.container {
    width: 1162px;
    max-width: 100%;
    margin: 0 auto;
    padding: 0 40px;
    
    &--big {
        width: 100%;
        max-width: 100%;
        padding: 0 25px;
    }

    &--middle {
        width: 1290px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .container {
        width: 100%;
        padding: 0 12.5vw;
        
        &--middle {
            width: 100%;
            padding: 0 12.5vw;
        }
    }
}

@media(max-width: 1140px) {
    .container {
        padding: 0 40px;

        &--big {
            padding: 0 24px;
        }
    }
}

@media(max-width: 960px) {
    .container {
        &--big {
            padding: 0 16px;
        }
    }
}

@media(max-width: 640px) {
    .container {
        padding: 0 32px;
    }
}

@media(max-width: 480px) {
    .container {
        padding: 0 24px;

        &--big {
            padding: 0 11px;
        }
    }
}