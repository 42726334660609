.catalog {
    display: flex;
    flex-wrap: wrap;
    margin: 57px -41px -41px;

    &__item {
        display: block;
        width: calc(~"33.3% - 82px");
        margin: 0 41px 41px;
        text-align: center;
    }

    &__img {
        margin-bottom: 17px;
    }

    &__name {
        font-weight: bold;
        font-size: 26px;
        color: @black;
        line-height: 130%;
        transition: color 0.3s ease;
        padding: 0 5px;
    }

    &--inner {
        margin-top: 57px;
        margin-bottom: -65px;

        .catalog {
            &__item {
                margin-bottom: 65px;
            }
        }
    }
}

@media(min-width: 960px) {
    .catalog {
        &__item {
            &:hover {
                .catalog {
                    &__name {
                        color: @orange;
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .catalog {
        display: flex;
        flex-wrap: wrap;
        margin: 40px -32px -32px;

        &__img {
            width: 100%;
            margin-bottom: 12px;

            img {
                max-width: 100%;
            }
        }
    
        &__item {
            width: calc(~"33.3% - 64px");
            margin: 0 32px 32px;
        }

        &__name {
            font-size: 19px;
            line-height: 130%;
        }
    }
}

@media(max-width: 960px) {
    .catalog {
        display: flex;
        flex-wrap: wrap;
        margin: 40px -30px -36px;
    
        &__item {
            width: calc(~"50% - 60px");
            margin: 0 30px 36px;
        }
    }
}

@media(max-width: 640px) {
    .catalog {
        flex-direction: column;
        align-items: center;
        margin: 32px 0 -36px;

        &__img {
            margin-bottom: 16px;
        }

        &__name {
            padding: 0;
        }
    
        &__item {
            width: 270px;
            margin: 0 0 36px;
        }
    }
}

@media(max-width: 480px) {
    .catalog {
        margin-bottom: -49px;
        justify-content: center;

        &__img {
            margin-bottom: 16px;
        }

        &__name {
            padding: 0;
        }

        &__img {
            width: 202px;
            margin-left: auto;
            margin-right: auto;
        }
    
        &__item {
            text-align: center;
            width: 270px;
            margin: 0 0 49px;
        }
    }
}