.slider-for {
    width: 100%;
    height: 680px;

    .slick-arrow {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
   
        padding: 0;
        border: 0;
        z-index: 5;
        top: 50%;
        transform: translateY(-50%);
        width: 80px;
        height: 80px;
        background-color: transparent;

        &.slick-prev {
            left: -80px;
        }

        &.slick-next {
            right: -80px;
        }

        svg {
            path {
                stroke: #2E2E2E;
                transition: stroke 0.3s ease;
            }
        }
    }

    &__item {
        display: flex;
        width: 100%;
        height: 680px;
        background-size: cover;
        background-position: center;
    }
}


@media (min-width: 1141px) and (max-width: 1600px) {
    .slider-for {
        width: 100%;
        height: 49vw;

        &__item {
            height: 49vw;
        }
    }
}

@media (max-width: 1140px) {
    .slider-for {
        width: 100%;
        height: 60vw;

        .slick-arrow {
            width: 20px;
            height: 20px;

            &.slick-prev {
                left: -22px;
            }
    
            &.slick-next {
                right: -22px;
            }
        }

        &__item {
            height: 60vw;
        }
    }
}