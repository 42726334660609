.info {
    display: flex;
    align-items: center;
    padding-top: 169px;
    padding-bottom: 100px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 33.3%;
        text-align: center;
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        font-family: "Georgia";

        span {
            position: relative;
        }
        
        svg {
            
            position: absolute;
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
    }
}

@media(max-width: 1200px) {
    .info {
        padding-top: 140px;
        padding-bottom: 75px;

        &__item {
            font-size: 19px;
            line-height: 1.2;

            svg {
                width: 223px;
                height: auto;
            }
        }
    }
}

@media(max-width: 768px) {
    .info {
        padding-top: 88px;
        padding-bottom: 0;

        &__wrap {
            flex-direction: column;
        }
        
        &__item {
            font-size: 19px;
            line-height: 1.2;
            height: 153px;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            svg {
                width: 223px;
                height: auto;
            }
        }
    }
}