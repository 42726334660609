.social {
    display: flex;
    margin-left: 31px;

    &__item {
        width: 34px;
        height: 40px;
        display: inline-flex;
        position: relative;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        font-size: 18px;
        line-height: 21px;
        color: #000;
        margin-right: 14px;
        text-decoration: none;

        &:last-child {
            margin-right: 0;
        }

        .svg-icon {
            use {
                fill: @grey-2;
            }
        }

        .svg-icon--back {
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;

            use {
                fill: transparent;
                stroke: #EDEDED;
            }
        }
    }

    &--light {
        margin-bottom: 55px;

        .social {
            &__item {
                width: 47px;
                height: 56px;

                .svg-icon--back {
                    width: 100%;
                    height: 100%;

                    use {
                        stroke: #6E6E6E;
                    }
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px){
    .social {
        margin-left: 25px;

        &__item {
            width: 2vw;
            height: 3vw;
            margin-right: 11px;

            .svg-icon {
                &:last-child {
                    max-width: 12px;
                    height: auto;
                }
            }
        }

        &--light {
            margin-bottom: 36px;
            
            .social {
                &__item {
                    width: 3vw;
                    height: 4vw;
                    margin-right: 11px;

                    &:last-child {
                        margin-right: 0;
                    }
        
                    .svg-icon {
                        &:last-child {
                            max-width: 12px;
                            height: auto;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .social {
        &__item {
            &:hover {
                .svg-icon {
                    use {
                        fill: @orange;
                    }

                    &--back {
                        use {
                            fill: transparent;
                            stroke: @orange;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .social {
        &--light {
            margin-left: 0;
            margin-bottom: 42px;

            .social {
                &__item {
                    width: 40px;
                    height: 48px;
                    margin: 0 7px;
                }
            }
        }
    }
}