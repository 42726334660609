.item {
    width: 92%;
    margin: 0 auto;

    &__wrap {
        display: flex;
        flex-direction: column;
    }

    &__play {
        order: 2;
        position: relative;
        margin-top: -343px;
        margin-left: 316px;

        &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            padding-left: 10px;
            width: 160px;
            height: 184px;
            top: 0;
            left: -116px;

            svg {
                &:nth-child(1) {
                    position: absolute;
                    left: 0;
                    top: 0;
                    path {
                        stroke: @orange;
                    }
                   
                }

                &:nth-child(2) {
                    z-index: 2;
                    position: relative;
                   path {
                    fill: @orange;
                   }
                }

               
            }
        }
    }

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 38px;
        order: 3;

        &:nth-child(2) {
            order: 1;
            margin-bottom: 242px;
        }

        &:nth-child(odd) {
            .item {
                &__body {
                    order: 2;
                    width: 560px;
                }

                &__icon {
                    order: 1;
                }
            }
        }
    }

    &__body {
        width: 480px;

        table {
            table-layout: fixed;
            border-collapse: collapse;
            margin-top: 30px;
            width: 500px;

            tr {
                td {
                    background: #F3F3F3;
                    padding: 8px 10px 8px 16px;
                    border: 0;
                    border-bottom: 2px solid #fff;

                    &:last-child {
                        width: 40%;
                    }

                    &:nth-child(1) {
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 157%;
                    }
                }
            }
        }
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
    }

    &__name {
        font-weight: 700;
        font-size: 36px;
        line-height: 121%;
        margin-bottom: 24px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .item {
        margin: 25px auto;

        &__play-icon {
            width: 10vw;
            height: 12vw;
            left: -17%;
            bottom: initial;
            top: 1vw;
            
            svg {
                &:first-child {
                    width: 100%;
                    height: auto;
                }

                &:last-child {
                    width: 2vw;
                    height: auto;
                }
            }
        }

        &__name {
            font-size: 2.2vw;
        }

        &__item {
            &:nth-child(odd) {
                .item {
                    &__body {
                        width: 44%;
                        flex: none;
                    } 
                }
            }
        }
        

        &__play {
            margin-top: -315px;
            margin-left: 235px;
        }

        &__play-img {
            width: 19vw;
        }

        &__icon {
            width: 34vw;
        }

        &__body {
            width: 44%;
            flex: none;

            table {
                width: 100%;

                tr {
                    td {
                            padding: 5px 6px 3px 7px;
                            
                        &:nth-child(1) {
                            font-size: 1vw;
                        }
                    }
                }
            }
        }

        &__text {
            font-size: 1.1vw;
        }
    }
}

@media(max-width: 1140px) {
    .item {
        margin: -28px auto 0;
        width: 100%;

        &__body {
            width: 53%;
            padding-right: 44px;
            flex: none;

            table {
                width: 100%;

                tr {
                    td {
                        &:nth-child(1) {
                            font-size: 14px;
                        }

                        &:nth-child(2) {

                        }
                    }
                }
            }
        }

        &__icon {
            padding-right: 45px;
        }

        &__play-icon {
            width: 100px;
            top: 8px;
            right: 333px;
            height: 115px;
            left: -77px;

            svg {
                &:first-child {
                    width: 100%;
                    height: auto;
                }

                &:last-child {
                    width: 26px;
                    height: auto;
                }
            }
        }

        &__play {
            margin-top: -292px;
            margin-left: 360px;

            &-img {
                width: 182px;
            }
        }


        &__item {

            &:nth-child(2) {
                margin-bottom: 219px;
                align-items: flex-start;

                .item {
                    &__body {
                        padding-top: 86px;
                    }
                }
            }

            &:nth-child(odd) {
                .item {
                    &__body {
                        width: 53%;
                        padding-right: 0;
                        padding-left: 35px;
                    }

                    &__icon {
                        padding-left: 39px;
                        padding-right: 6px;
                    }
                }
            }
        }

        &__name {
            font-size: 30px;
            line-height: 110%;
            margin-bottom: 32px;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }
    }
}

@media(max-width: 960px) {
    .item {
        margin-top: 0;

        &__body {
            flex: none;
            width: 59%;
            padding-right: 21px;

            table {
                width: 100%;

                tr {
                    td {
                        &:nth-child(1) {
                            font-size: 12px;
                        }

                        &:nth-child(2) {
                            font-size: 10px;
                        }
                    }
                }
            }
        }

        &__play {
            position: absolute;
            top: 23px;
            display: inline-flex;
            right: 159px;
            margin: 0;

            &-icon {
                position: relative;
                top: initial;
                right: initial;
                left: initial;
            }
        }

        &__wrap {
            position: relative;
        }

        &__play-img {
            display: none;
        }

        &__item {
            &:nth-child(2) {
                margin-bottom: 59px;

                .item {
                    &__body {
                        padding-top: 100px;
                    }

                    &__icon {
                        padding-top: 94px;
                    }
                }
            }

            &:nth-child(odd) {
                margin-bottom: 59px;
                
                .item {
                    &__body {
                        width: 63%;
                        padding-right: 0;
                    }

                    &__icon {
                        padding-top: 0;
                        width: 338px;
                        flex: none;
                        padding-right: 0;
                        margin-left: -139px;
                        padding-left: 0;

                        img {
                            width: 100%;
                            max-width: initial;
                        }
                    }
                }
            }
        }

        &__icon {
            width: 338px;
            flex: none;
            padding-right: 0;

            img {
                width: 100%;
                max-width: initial;
            }
        }
    }
}

@media(max-width: 640px) {
    .item {
        margin-top: 40px;

        &__play {
            top: 0;
            left: 204px;
            right: initial;
        }

        &__item {
            flex-direction: column;
            align-items: flex-start;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__icon {
            order: 1 !important;
            padding-top: 0 !important;
            width: 230px !important;
            margin: 0 !important;
        }

        &__body {
            width: 100% !important;
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 18px !important;
            order: 2 !important;
        }
    }
}

@media(max-width: 480px) {
    .item {
        &__play {
            left: 177px;
        }
    }   
}

@media(min-width: 960px) {
    .item {
        &__play {
            &-icon {
                &:hover {
                    svg {
                        &:nth-child(1) {
                            path {
                                fill: @orange;
                                stroke: @orange;
                            }
                        
                        }
        
                        &:nth-child(2) {
                            path {
                                fill: #fff;
                            }
                            
                        }
        
                       
                    }
                }
            }
        }
    }
}