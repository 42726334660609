.up {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 49px;
    right: 86px;
    width: 56px;
    padding-top: 2px;
    height: 54px;
    cursor: pointer;
    z-index: 15;
    opacity: 0;
    pointer-events: none;
    transition: box-shadow .3s, opacity .3s, background-color 0.3s ease;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    svg {
        &:first-child {
            position: absolute;
            top: 0;
            right: 0;
        }

        &:last-child {
            position: relative;
            z-index: 1;
        }
    }
}

@media (min-width: 960px) {
    .up:hover {
        opacity: .7;
    }
}

@media(max-width: 960px) {
    .up {
        right: 15px;
        bottom: 15px;
    }
}