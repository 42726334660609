.tag {
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 56px;

	&__item {
		display: inline-flex;
		padding: 6px 12px;
		margin-right: 8px;
		margin-bottom: 8px;
		font-size: 15px;
		line-height: 150%;
		border-radius: 20px;
		color: @grey-3;
		background-color: @grey-1;
		transition: color 0.3s , background-color 0.3s ease;

		&.is-active {
			background-color: @orange;
			color: #fff;
		}
	}
}

@media(min-width: 1141px) and (max-width: 1600px) {
	.tag {
		margin-bottom: 34px;

		&__item {
			font-size: 0.95vw;
    		padding: 5px 8px;
		}
	}
}

@media(max-width: 1140px) {
	.tag {
		margin-bottom: 26px;
		
		&__item {
			padding: 3px 8px;
			font-size: 12px;
			line-height: 150%;
			margin-right: 8px;
			margin-bottom: 8px;
		}
	}
}