.main {
    overflow: hidden;
    position: relative;

    &::before {
        content:" ";
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 426px;
        transform: rotate(180deg);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%);
    }

    &__main {
        position: relative;
        z-index: 1;
        float: left;
        width: calc(~"100% - 853px");
    }

    &__container {
        width: 1280px;
        padding-left: 200px;
    }

    &__aside {
        width: 853px;
        height: 100vh;
        float: left;
    }
}


@media(max-width: 2200px) {
    .main {
        &__main {
            width: calc(~"100% - 640px");
        }
    
        &__container {
            width: 100%;
            padding-right: 236px;
            padding-left: 200px;
        }
    
        &__aside {
            width: 640px;
        }
    }
}

@media(max-width: 1900px) {
    .main {
        &__main {
            width: calc(~"100% - 533px");
        }
    
        &__container {
            width: 100%;
            padding-right: 129px;
            padding-left: 94px;
        }
    
        &__aside {
            width: 533px;
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .main {
        &__aside {
            width: 33%;
        }

        &__main {
            width: 67%;
        }

        &__container {
            padding-left: 70px;
        }
    }
}


@media(max-width: 1140px) {
    .main {
        &__main {
            width: calc(~"100% - 341px");
        }
    
        &__container {
            width: 100%;
            padding-right: 91px;
            padding-left: 41px;
        }
    
        &__aside {
            width: 341px;
        }
    }
}

@media (max-width: 960px) {
    .main {
        overflow: visible;

        &__main {
           width: 100%;
           float: none;
        }
    
        &__container {
            width: 100%;
            padding-right: 41px;
            padding-left: 41px;
        }
    
        &__aside {
            display: none;
        }
    }
}

@media (max-width: 640px) {
    .main {
        &__container {
            padding-right: 32px;
            padding-left: 32px;
        }
    }
}

@media (max-width: 480px) {
    .main {
        &__container {
            padding-right: 24px;
            padding-left: 24px;
        }
    }
}