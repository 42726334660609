@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay-Regular.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: normal; 
} 

@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay-Medium.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 500; 
} 

@font-face {
    font-family: "SF Pro Display";
    src: url("../fonts/SFProDisplay-Bold.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 700; 
} 


@font-face {
    font-family: "Georgia";
    src: url("../fonts/Georgia-Bold.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 700; 
} 

@font-face {
    font-family: "Georgia";
    src: url("../fonts/Georgia.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: 400; 
} 

@font-face {
    font-family: "Georgia";
    src: url("../fonts/Georgia-Italic.ttf") format("truetype"); 
    font-style: italic; 
    font-weight: 400; 
} 

.font-grey {
    color: #2B2725;
}