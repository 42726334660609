.lang {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    font-size: 11px;
    line-height: 150%; 
    color: @grey-2;
    margin-left: 31px;
    text-transform: uppercase;
    position: relative;

    

    &__control {
        margin-left: 6px;

        .svg-icon {
            transition: transform 0.3s ease;

            use {
                fill: transparent;
                stroke:  #B8B8B8;
            }
        }
    }

    &__body {
        display: flex;
        align-items: center;
    }

    span {
        display: block;
        margin: 0 5px;
    }

    &__item {
        color: inherit;
        &.is-active {
            color: @orange;
        }
    }

    &__icon {
        flex: none;
        margin-right: 10px;
    }

    &__drop {
        width: 200px;
        top: 0;
        position: absolute;
        padding-top: 48px;
        left: -30px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        a {
            display: flex;
            align-items: center;
            padding: 16px 20px;
            font-weight: 600;
            font-size: 13px;
            line-height: 150%;
            text-transform: uppercase;
            color: #FFFFFF;
            background-color: #2E2E2E;
            border-bottom: 1px solid #fff;
            transition: background-color 0.3s ease;

            &.is-active {
                background-color: @orange;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px){
    .lang {
       

        &__drop {
            width: 13vw;
            padding-top: 3.1vw;

            a {
                padding: 12px 15px;
                font-size: 0.8vw; 
            }
        }
    }
}

@media(max-width: 1140px) {
    .lang {
        margin-left: 13px;

        &__drop {
            padding-top: 18px;

            a {
                padding: 13px 16px;
                font-size: 12px;   
            }
        }
    }
}

@media(min-width: 960px) {
    .lang {
        &:hover {
            .lang {
                &__drop {
                    opacity: 1;
                    pointer-events: auto;
                }

                &__control {
                    .svg-icon {
                        transform: rotate(180deg);

                        use {
                            stroke: @orange;
                        }
                    }
                }
            }
        }

        &__drop {
            a {
                &:hover {
                    background-color: @orange;
                }
            }
        }
    }
}