.menu-top {
    padding: 12px 0;

    background-color: #fff;

    .btn {
        display: none;
    }

    .logo__info {
        display: none;
    }
    
    .container {
        display: flex;
        align-items: center;
        position: relative;
    }

    &__logo {
        display: flex;
        align-items: center;

        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.3s ease;

        img {
            width: 37px;
            
        }
    }

    &::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: -6px;
        height: 6px;
        background-image: url(../images/common/line-menu.png);
        transition: opacity 0.3s ease;
    }

    &__nav {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;

        a {
            display: inline-flex;
            margin: 0 45px;
            font-size: 16px;
            line-height: 19px;
            color: #000000;
            text-decoration: none;
        }
    }
}

@media(max-width: 1200px) {
    .menu-top {
        &__nav {
            a {
                margin: 0 30px;
            }
        }
    }
}

@media(max-width: 992px) {
    .menu-top {
        padding: 0;
        height: 100%;

        &::after {
            display: none;
        }

        &__logo {
            display: none;
        }

        &__btn {
            width: 100%;

            .btn {
                display: inline-flex;
                margin-bottom: 15px;
            }

            .logo {
                &__info {
                    display: flex;
                }
            }
        }

        &__nav {
            flex-direction: column;
            margin: 0;
            align-items: flex-start;
            width: 100%;

            a {
                margin: 0;
                margin-bottom: 13px;
            }
        }

        .container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 100%;
            padding: 0;
        }
    }
}