.order {
    display: flex;
    align-items: center;
    margin-bottom: 44px;

    &__text {
        padding-left: 16px;
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: @orange;
        max-width: 256px;
    }

    &--mini {
        margin-bottom: 27px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .order {
        margin-bottom: 32px;

        &__text {
            font-size: 0.85vw;
            max-width: 16vw;
            padding-left: 11px;
        }

        .btn {
            font-size: 0.9vw;
            padding: 8px 15px;
        }
    }
}

@media (max-width: 1140px) {
    .order {
        margin-bottom: 34px;
        flex-direction: column;
        align-items: flex-start;

        &__text {
            margin-top: 10px;
            max-width: 225px;
            font-size: 13px;
            line-height: 160%;
            padding-left: 0;
        }
    }
}

@media (max-width: 960px) {
    .order {
        margin-bottom: 42px;
        
        .btn {
            padding: 13px 27px;
        }
    }
}


@media (max-width: 640px) {
    .order {
        flex-direction: row;
        align-items: center;
        margin-bottom: 36px;

        &__text {
            margin-top: 0;
            font-size: 11px;
            line-height: 160%;
            max-width: initial;
            padding-left: 16px;
        }

        .btn {
            width: 196px;
        }
    }
}

@media (max-width: 480px) {
    .order {
        margin-bottom: 32px;

        &__text {
            display: none;
        }

        .btn {
            width: 100%;
        }
    }
}