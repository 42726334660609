.doc {
    &__item {
        display: flex;
        align-items: center;

        img {
            width: 100%;
        }
    }
    &__slider {
        .slick-list {
            margin: 0 -17px;
        }

        .slick-arrow {
            position: absolute;
            padding: 0;
            border: 0;
            z-index: 5;
            top: -79px;
            right: 0;
            background-color: transparent;

            &.slick-prev {
                right: 95px;
            }

            svg {
                path {
                    stroke: #2E2E2E;
                    transition: stroke 0.3s ease;
                }
            }
        }

        .slick-slide {
            padding: 0 17px;
        }
    }
}

@media (max-width: 960px) {
    .doc {
        &__item {
            width: 304px;
        }

        &__slider {
            margin-right: -40px;
        }
    }
}


@media (min-width: 960px) {
    .doc {
        &__slider {
            .slick-arrow {
                &:hover {
                    path {
                        stroke: @orange;
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .doc {
        &__item {
            width: 274px;
        }

        &__slider {
            margin-right: -24px;
        }
    }
}