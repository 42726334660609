.menu-catalog {
   
    position: fixed;
    top: 48px;
    padding-top: 32px;
    width: calc(~"100vw + 1px");
    left: 0;
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.3s ease;

    &__wrap {
        display: flex;
    }

    &__img {
        width: 160px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 27px;
        position: relative;
        z-index: 1;
    }

    &__item {
        width: 33.3%;
        height: 498px;
        padding-top: 113px;
        color: #fff;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-position: center;
        background-size: cover;
        border-right: 1px solid #FFFFFF;

        &:last-child {
            border-right: 0;
        }

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: @orange;
            opacity: 0;
            transition: opacity 0.3s ease;
        }
    }

    &__more {
        position: relative;
        opacity: 0;
        z-index: 1;
        transition: opacity 0.3s ease;
    }

    &__name {
        font-weight: 500;
        font-size: 26px;
        line-height: 132%;
        z-index: 1;
        max-width: 312px;
        text-align: center;
        margin-bottom: 64px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .menu-catalog {
        top: 3.2vw;
        padding-top: 20px;

        &__item {
            height: 32vw;
            padding-top: 60px;
        }

        &__name {
            font-size: 2vw;
        }

        &__img {
            width: 10vw;
            height: 10vw;
        }
    }
}

@media(max-width: 1140px) {
    .menu-catalog {
        top: 35px;
        padding-top: 21px;

        &__item {
            height: 470px;
        }
    }
}

@media(min-width: 960px) {
    .menu-catalog {
        &__item {
            &:hover {
                &::before {
                    opacity: 1;
                }

                .menu-catalog {
                    &__more {
                        opacity: 1;
                    }
                }
            }
        }
    }
}