.play-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 184px;

    
    padding-bottom: 6px;
    padding-left: 8px;
    cursor: pointer;

    svg {
        &:nth-child(1) {
            position: absolute;
            left: 0;
            top: 0;

            path {
                stroke: @orange;
                fill: @orange;
                transition: fill 0.3s , stroke 0.3s ease;
            }

        }

        &:nth-child(2) {
            position: relative;
            z-index: 2;

            path {
                fill: #fff;
                transition: fill 0.3s , stroke 0.3s ease;
            }
        }
    }

    &--revert {
        svg {
            &:nth-child(1) {

                path {
                    stroke: @orange;
                    fill: transparent;
                }
    
            }
    
            &:nth-child(2) {
                path {
                    fill: @orange;
                    transition: fill 0.3s , stroke 0.3s ease;
                }
            }
        }
    }
}


@media(min-width: 960px) {
    .play-icon {
        &:hover {
            svg {
                &:nth-child(1) {
                    path {
                        fill: #fff;
                        stroke: @orange; 
                    }
                }
        
                &:nth-child(2) {
                    path {
                        fill: @orange; 
                    }
                }
            }
        }

        &--revert {
            &:hover {
                svg {
                    &:nth-child(1) {
        
                        path {
                            stroke: @orange;
                            fill: @orange;
                        }
            
                    }
            
                    &:nth-child(2) {
                        path {
                            fill: #fff;
                            transition: fill 0.3s , stroke 0.3s ease;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .play-icon {
        display: none;
    }
}