.form-big {
    display: flex;
    align-items: center;

    &__title {
        font-weight: bold;
        font-size: 18px;
        line-height: 157%;
        color: #fff;
        margin-bottom: 4px;
    }

    &__wran {
        margin-top: 12px;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        color: #F3F3F3;
        opacity: 0.6;
    }

    &__textinfo {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #fff;
        margin-bottom: 32px;
    }

    &__heading {
        font-weight: 700;
        font-size: 26px;
        line-height: 130%; 
        color: #FFFFFF;
        margin-bottom: 9px;
    }

    &__section {
        margin-bottom: 24px;
    }

    &__body {
        flex-grow: 1;
        padding-left: 160px;
    }

    &__row {
        display: flex;
        align-items: center;
        margin: 0 -15px;

        .form-big {
            &__field {
                width: calc(~"50% - 30px");
                margin: 0 15px;
            }
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        margin-top: 48px;
        
    }

    &__agree {
        font-size: 14px;
        line-height: 160%;
        color: #FFFFFF;
        padding-left: 24px;

        a {
            color: #FFFFFF;
            text-decoration: underline;
        }
    }

    &__textarea {
        display: block;
        height: 100px;
        background-color: #FFFFFF;
        border-radius: 8px;
        outline: 0;
        width: 100%;
        border: 0;
        padding: 15px 20px;
        font-family: Montserrat;

        &::-webkit-input-placeholder { color: #B8B8B8;     font-family: Montserrat;}
        &:-moz-placeholder           { color: #B8B8B8;     font-family: Montserrat;}
        &::-moz-placeholder        { color: #B8B8B8;     font-family: Montserrat;}
        &:-ms-input-placeholder     { color: #B8B8B8;     font-family: Montserrat;}
    }

    &__input {
        height: 56px;
        background-color: #FFFFFF;
        border-radius: 8px;
        outline: 0;
        width: 100%;
        border: 0;
        padding: 15px 20px;
        font-family: Montserrat;

        &::-webkit-input-placeholder { color: #B8B8B8;     font-family: Montserrat;}
        &:-moz-placeholder           { color: #B8B8B8;     font-family: Montserrat;}
        &::-moz-placeholder        { color: #B8B8B8;     font-family: Montserrat;}
        &:-ms-input-placeholder     { color: #B8B8B8;     font-family: Montserrat;}
    }

    &__name {
        width: 480px;
        flex: none;
        font-weight: bold;
        font-size: 48px;
        line-height: 110%;
        color: #fff;

        &-text {
            padding-top: 115px;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%; 
            color: #F3F3F3;
        }

        span {
            display: block;
            margin-top: 24px;
            font-size: 21px;
            line-height: 140%; 
        }
    }

    &__main {
        width: 480px;
        flex: none;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        
        p {
            font-weight: 700;
            font-size: 18px;
            line-height: 157%;
            color: #FFFFFF;
        }
    }

    &__text {
        font-size: 14px;
        line-height: 160%;
        color: @grey-1; 
    }

    &__bigtitle {
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        margin-bottom: 32px;
        color: #fff;
    }

    &--contact {
        justify-content: space-between;
        align-items: inherit;

        .form-big {
            &__body {
                padding-left: 0;
                width: 480px;
                flex: none;
            }

            &__agree {
                display: none;
            }

        }
    }

    &__mini {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;  
        color: #F3F3F3;
        opacity: 0.6;
        margin-left: 20px;
    }

    &--consult {
        padding-top: 55px;
        padding-bottom: 55px;
        position: relative;
        position: relative;

        .form-big {
            &__body {
                padding-left: 121px;
            }
            
            &__name {
                display: flex;
                flex-direction: column;
                align-self: stretch;
                justify-content: space-between; 
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .form-big {

        &__heading {
            font-size: 1.55vw;
            margin-bottom: 7px;
        }

        &__section {
            margin-bottom: 18px;
        }

        &__agree {
            font-size: 0.8vw;
        }

        &__btn {
            margin-top: 32px;

            .btn {
                width: 12vw;
                flex: none;
            }
        }

        &__title {
            font-size: 1.1vw;
        }

        &__name {
            width: 39%;
            font-size: 3vw;

            span {
                font-size: 1.3vw;
            }
        }

        &__body {
            padding-left: 122px;
        }

        &__textinfo {
            font-size: 0.9vw;
            margin-bottom: 21px;
        }

        &__input {
            padding: 11px 14px;
            height: 3.5vw;
            font-size: 1.1vw;
            border-radius: 6px;
        }
    }
}


@media (max-width: 1140px) {
    .form-big {
        &__name {
            width: 283px;
            font-size: 30px;
            line-height: 110%;

            span {
                font-size: 16px;
                line-height: 140%;  
                margin-top: 32px;
            }
        }

        &__textinfo {
            font-size: 11px;
            line-height: 160%;
            margin-bottom: 16px;
        }

        &__info {
            height: 48px;
            padding: 12px 16px;
        }

        &__heading {
            font-size: 19px;
            line-height: 130%;
            margin-bottom: 6px;
        }

        &__title {
            font-size: 13px;
            line-height: 160%;
            margin-bottom: 4px;

            &--big {
                font-size: 18px;
                line-height: 157%;
            }
        }

        &__section {
            margin-bottom: 18px;
        }

        &__btn {
            margin-top: 40px;
        }

        &__input {
            height: 48px;
            font-size: 15px;
            line-height: 150%;
            padding: 11px 16px;
        }

        &__agree {
            font-size: 11px;
            line-height: 160%;
        }

        &__body {
            padding-left: 67px;
        }

        &__bigtitle {
            font-size: 30px;
            line-height: 110%;
            margin-bottom: 16px;
        }

        &__textarea {
            height: 80px;
        }

        &--contact {
            .form-big {
                &__body {
                    width: 40%;
                    flex: none;
                }

                &__btn {
                    margin-top: 33px;

                    .btn {
                        font-size: 12px;
                        line-height: 17px;
                        padding: 9px 21px;
                    }
                }

                &__text {
                    font-size: 11px;
                    line-height: 160%;
                }

                &__section {
                    margin-bottom: 8px;
                }

                &__main {
                    width: 60%;
                    padding-right: 50px;

                    p {
                        font-size: 13px;
                        line-height: 160%;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .form-big--consult .form-big__body {
        padding-left: 0;
    }
    .form-big {
        display: block;

        &__name {
            font-size: 30px;
            line-height: 110%;
            width: auto;
            padding: 40px 40px 35px;
            margin: -40px -40px 0;
            background: #F55905;
            border-radius: 24px 24px 0px 0px;

            &-text {
                padding-top: 15px;
            }

            span {
                font-size: 13px;
                line-height: 160%;
                margin-top: 16px;
            }
        }

        &__body {
            padding-left: 0;
            padding-top: 24px;
        }

        &--contact {
            .form-big {
                &__main {
                    background: #F55905;
                    width: auto;
                    margin: -40px -40px 0;
                    padding: 40px;
                }

                &__agree {
                    display: block;
                }

                &__text {
                    display: none;
                }

                &__body {
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .form-big {

        &__wran {
            font-size: 9px;
        }
        
        &__row {
            display: block;

            .form-big {
                &__field {
                    width: calc(~"100% - 30px");
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &__section {
            margin-bottom: 16px;
        }

        &__agree {
            font-size: 11px;
            line-height: 160%;
            opacity: 0.5;
            margin-top: 8px;
            padding-left: 0;
        }

        &__btn {
            display: block;
            margin-top: 32px;
        }

        &__body {
            padding-top: 24px;
        }
    }
}

@media (max-width: 480px) {
    .form-big {
        &__name {
            background: #F55905;
            padding: 40px 24px 35px;
            border-radius: 24px 24px 0px 0px;
            margin: -40px -24px 0;
            width: auto;
        }

        &--contact {
            .form-big {
                &__main {
                    margin: -40px -32px 0;
                    padding: 32px;
                }
            }
        }
    }
}