.about {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px 23px;
    max-width: 600px;

    &__item {
        width: 50%;
        padding: 0 20px;
        margin-bottom: 23px;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 157%;
        margin-bottom: 8px;
    }

    &__text {
        font-size: 14px;
        line-height: 160%;
    }
}

@media(min-width: 1141px) and (max-width: 1601px) {
    .about {
        margin-left: -15px;
        margin-right: -15px;
        margin-bottom: 12px;
        max-width: 75%;

        &__name {
            font-size: 1.1vw;
        }

        &__item {
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 17px;
        }

        &__text {
            font-size: 0.85vw;
        }
    }
}


@media (max-width: 1140px) {
    .about {
        max-width: 98%;
        margin-left: -8px;
        margin-right: -8px;
        margin-bottom: 0;


        &__name {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 4px;
        }

        &__item {
            padding: 0 8px;
            margin-bottom: 17px;
        }

        &__text {
            font-size: 11px;
            line-height: 160%;
        }
    }
}

@media (max-width: 480px) {
    .about {
        margin-bottom: 32px;
        
        &__item {
            width: 100%;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}