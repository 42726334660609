.footer {
    background-color: @black;
    padding-top: 85px;
    padding-bottom: 85px;

    &__main {
        max-width: 281px;

        .logo {
            margin-bottom: 74px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;

        .footer {
            &__link {
                display: none;
            }
        }
    }

    &__link {
        display: flex;
        flex-direction: column;
        color: @grey-2;

        a {
            display: inline-flex;
            color: @grey-2;
            margin-bottom: 16px;
        }

        div {
            margin-bottom: 16px;
        }
    }

    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }
}

.site {
    display: inline-flex;
    align-items: center;
    color: @grey;
    font-weight: 500;
    font-size: 13px;
    line-height: 160%;

    svg {
        margin-top: 2px;
        display: block;
        margin-left: 5px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .footer {
        padding-top: 62px;
        padding-bottom: 61px;

        &__main {
            padding-right: 42px;
            width: 27%;

            .logo {
                width: 15vw;
                margin-bottom: 47px;
            }
        }

        &__link {
            font-size: 0.85vw;

            a {
                margin-bottom: 6px;
            }

            div {
                margin-bottom: 6px;
            }
        }
    }

    .site {
        font-size: 0.9vw;

        svg {
            width: 3vw;
            margin-top: -1px;
        }
    }
}

@media(max-width: 1140px) {
    .footer {
        padding-top: 49px;
        padding-bottom: 67px;

        &__row {
            flex-direction: column;
            align-items: center;
        }

        &__link {
            font-size: 11px;
            line-height: 160%;
            margin-bottom: 14px;
            font-weight: 500;

            a {
                margin-bottom: 6px;
                color: @grey-3;
            }

            div {
                color: @grey-3;
                margin-bottom: 6px;
            }
        }

        &__info {
            align-items: center;
            text-align: center;

            .footer {
                &__link {
                    display: flex;
                    align-items: center;
                }
            }
        }

        &__main {
            .logo {
                margin-bottom: 39px;

                img {
                    width: 240px;
                    height: auto;
                }
            }
        }

        &__menu {
            display: none;
        }

        &__link {
            display: none;
        }
    }

    .site {
        font-size: 11px;
        line-height: 160%;
        color: #FFFFFF;
    }
}