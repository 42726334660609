.director {
    text-align: center;
    font-size: 16px;
    line-height: 20px;

    &__wrap {
        position: relative;
        max-width: 500px;
        margin: 0 auto;
    }

    &__arrow {
        position: absolute;

        &--left {
            top: -19px;
            left: -131px;
        }

        &--right {
            top: -19px;
            right: -131px;
        }
    }

    &__title {
        font-weight: 600;
        font-size: 22px;
        line-height: 36px;
        margin-bottom: 3px;

        span {
            margin-right: 10px;
        }
    }
}

@media(max-width: 768px) { 
    .director {
        &__text {
            font-size: 16px;
            line-height: 20px;
        }

        &__title {
            font-size: 18px;
            line-height: 26px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            margin-bottom: 12px;

            span {
                margin-right: 0;
            }
        }
    }
}