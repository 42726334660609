.phone-block {
    text-align: right;
    color: #fff;
    margin-bottom: 16px;

    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%; 
    }

    .phone {
        color: #fff;
        font-weight: bold;
        font-size: 36px;
        line-height: 116%;
    }
}

.mail {
    display: inline-flex;
    font-size: 18px;
    line-height: 150%; 
    color: @orange;
    margin-bottom: 60px;
    border-bottom: 2px solid fade(@orange, 20);
}


@media(min-width: 1141px) and (max-width: 1600px) {
    .phone-block {
        margin-bottom: 12px;

        &__title {
            font-size: 1.1vw;
        }

        .phone {
            font-size: 2vw;
        }
    }

    .mail {
        font-size: 1.2vw;
        margin-bottom: 42px;
    }
}

@media(max-width: 1140px) {
    .phone-block {
        text-align: center;

        .phone {
            font-size: 24px;
            line-height: 121%;  
        }

        &__title {
            font-size: 13px;
            line-height: 160%;
        }
    }

    .mail {
        margin-bottom: 26px;
        font-size: 16px;
        line-height: 140%;
    }
}