.not-found {
    &__img {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__text {
        margin-top: 11px;
        max-width: 423px;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: #fff;
        text-align: center;
        margin: 0 auto 45px;
    }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media(max-width: 640px) {
    .not-found {
        &__text {
            margin-top: 24px;
            font-size: 14px;
            line-height: 150%;
            margin-bottom: 20px;
            max-width: 284px;
        }
    }
}