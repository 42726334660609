.speed {
    text-align: center;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__img {
        margin-bottom: -17px;
    }

    &__name {
        font-weight: 700;
        font-size: 48px;
        line-height: 110%;
        margin-bottom: 25px; 

        span {
            display: block;
            font-size: 21px;
            line-height: 140%;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
        max-width: 560px;
        margin-bottom: 33px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .speed {
        &__img {
            margin-bottom: -14px;
            width: 23.5vw;
        }

        &__text {
            font-size: 1.05vw;
            max-width: 34vw;
            margin-bottom: 28px;
        }

        &__name {
            font-size: 2.9vw;
            margin-bottom: 20px;

            span {
                font-size: 1.3vw;
            }
        }
    }
}

@media(max-width: 1140px) {
    .speed {
        &__name {
            font-size: 36px;
            line-height: 110%;

            span {
                font-size: 15px;
                line-height: 140%;
            }
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
            max-width: 468px;
            margin-bottom: 19px; 
        }

        &__img {
            margin-bottom: -14px;
            width: 284px;
        }
    }
}

@media(max-width: 960px) {
    .speed {
        &__text {
            max-width: 100%;
            margin-bottom: 12px;
        }
    }
}

@media(max-width: 640px) {
    .speed {
    }
}

