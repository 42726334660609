
h1, .h1 {
    font-weight: bold;
    font-size: 48px;
    line-height: 110%;
    margin: 0;
}

h2 {
    margin: 0;
    font-weight: bold;
    font-size: 36px;
    line-height: 121%;

    a {
        color: inherit
    }
}

@media(min-width: 1141px) {
    h2 {
        a {
            &:hover {
                color: @orange;
            }
        }
    }

}

@media(min-width: 1141px) and (max-width: 1600px){
    h1 , .h1 {
        font-size: 3vw;
    }

    h2 {
        font-size: 2.25vw;
    }

}

@media(max-width: 1140px) {
    h1, .h1 {
        font-size: 30px;
        line-height: 110%;
    }

    h2 {
        font-size: 24px;
        line-height: 121%;
    }
}

@media(max-width: 960px) {
    h2 {
        font-size: 24px;
        line-height: 121%;
    }


}
