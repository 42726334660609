.wrapper {
    padding-top: 80px;

    &--404 {
        background: linear-gradient(180deg, #2E2E2E 0%, #272626 100%);

        .wrapper__content {
            padding-top: 55px;
        }

        .section__top {
            margin-bottom: 55px;
        }
    }

    &--index {
        .header {
            &::before {
                display: none;
            }
        }

        .wrapper {
            &__content {
                padding-top: 0;
            } 
        }
    }

    &--banner {
        .wrapper {
            &__content {
                padding-top: 0;
            } 
        }
    }

    &--inner {
        .wrapper {
            &__content {
                padding-top: 100px;
            } 
        }
    }

    &__content {
        padding-top: 100px;
    }
}

@media(min-width: 1141px) and (max-width: 1600px){
    .wrapper {
        padding-top: 5vw;

        &--inner {
            .wrapper {
                &__content {
                    padding-top: 69px;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .wrapper {
        padding-top: 56px;

        &--inner {
            .wrapper {
                &__content {
                    padding-top: 59px;
                }
            }
        }
        
        &__content {
            padding-top: 60px;
        }
    }
}

@media(max-width: 960px) {
    .wrapper {
        &--inner {
            .wrapper {
                &__content {
                    padding-top: 40px;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .wrapper {
        &--404 {
            .wrapper__content {
                padding-top: 23px;
            }
    
            .section__top {
                margin-bottom: 86px;
            }
        }
    }
    
}

@media(max-width: 480px) {
    .wrapper {
        padding-top: 48px;
    }
}