.price {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    max-width: 94%;
    position: relative;

    &__name {
        color: #DBA37E;
    }

    &__warn {
        position: relative;
        text-align: center;
        font-size: 16px;
        line-height: 26px;
        color: #656565;
    }

    &__info {
        position: relative;
        text-align: center;
        margin-top: 6px;
        font-weight: bold;
        font-size: 22px;
        line-height: 26px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 15px 45px;
        text-align: center;
        flex-wrap: wrap;
        font-weight: bold;
        font-size: 22px;
        line-height: 34px;
        width: calc(~"33.3% - 80px");
        background: #FFFFFF;
        border: 1px solid #DBA37E;
        margin: 0 40px 30px;
        border-radius: 5px;
        height: 253px;
    }

    &__text {
       
    }

    &__line {
        margin: 18px 0;
        width: 65px;
        height: 1px;
        background-color: #D7B56D;
    }
}

@media(max-width: 1200px) {
    .price {
        max-width: initial;

        &__item {
            height: 227px;
            font-size: 20px;
            line-height: 27px;
        }

        &__warn {
            font-size: 14px;
            line-height: 23px;
        }

        &__info {
            font-size: 20px;
            line-height: 24px;
        }

        &__item {
            width: calc(~"33.3% - 40px");
            margin: 0 20px 30px;
            padding: 15px 25px;
        }
    }
}


@media(max-width: 992px) {
    .price {
        max-width: initial;
        margin: 0 -10px;

        &__item {
            height: 227px;
            font-size: 22px;
            line-height: 34px;
        }

        &__warn {
            font-size: 16px;
            line-height: 18px;
        }

        &__info {
            font-size: 20px;
            line-height: 24px;
        }

        &__item {
            width: calc(~"33.3% - 20px");
            margin: 0 10px 30px;
            padding: 15px 25px;
        }
    }
}

@media(max-width: 656px) {
    .price {
        max-width: initial;
        margin: 0 -10px 30px;

        &__item {
            height: 253px;
        }

        &__warn {
            font-size: 16px;
            line-height: 18px;
        }

        &__info {
            font-size: 20px;
            line-height: 24px;
        }

        &__item {
            width: calc(~"100% - 20px");
            margin: 0 10px 15px;
            padding: 15px 25px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}