.form-ok {
    background: rgba(46, 46, 46, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 15;
    text-align: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }

    &__body {
        svg {
            margin-bottom: 24px;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 26px;
        line-height: 130%;
        margin-bottom: 5px;
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
    }

    &__close {
        cursor: pointer;
        right: 40px;
        top: 40px;
        position: absolute;

        .svg-icon {
            width: 16px;
            height: auto;
        }
    }

    &__wrap {
        position: relative;
        width: 560px;
        background-color: #FFFFFF;
        border-radius: 12px;
        padding: 53px 25px;
    }
}

@media(max-width: 960px) {
    .form-ok {
        &__title {
            font-size: 22px;
            line-height: 130%;
            margin-bottom: 2px;
        }

        &__wrap {
            width: 460px;
            padding: 42px 25px;
        }

        &__close {
            top: 25px;
            right: 25px;
        }

        &__text {
            font-size: 16px;
        }

        &__body {
            svg {
                width: 100px;
                height: auto;
                margin-bottom: 10px; 
            }
        }
    }
}