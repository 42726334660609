.sert {
    display: flex;
    margin: 0 -8px;

    &__item {
        display: flex;
        margin: 0 8px;
        border-radius: 5px;
        overflow: hidden;

        img {
            max-width: 100%;
        }
    }
}

@media(max-width: 600px) {
    .sert {
        flex-wrap: wrap;

        &__item {
            width: calc(~"50% - 16px");
            margin-bottom: 16px;

            img {
                width: 100%;
            }

            &:nth-child(1) {
                width: calc(~"100% - 16px");
            }
        }
    }
}