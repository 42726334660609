.package {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 72px -40px 0;

    &__body {
        width: 480px;
        flex: none;
    }

    &__title {
        font-weight: bold;
        font-size: 56px;
        line-height: 110%;
        color: #FFFFFF;
        margin-bottom: 32px;
    }

    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 48px;
        justify-content: flex-end;
        text-align: center;
        width: calc(~"50% - 80px");
        margin: 0 40px;
        background-position: center;
        background-size: cover;
        height: 394px;
        position: relative;
        border-radius: 31px;
        overflow: hidden;

        &-body {
            position: relative;
        }

        &::before {
            content:" ";
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
        }

        &-img {
            margin-right: 39px;
        }

        &-type {
            font-size: 18px;
            line-height: 157%;
            color: #FFFFFF;
            margin-bottom: 24px;
        }

        &-name {
            color: #FFFFFF;
            font-weight: bold;
            font-size: 26px;
            line-height: 130%;
            margin-bottom: 3px;
        }

        &:nth-child(even) {
            .package {
                &__item {
                    &-img {
                        order: 1;
                        margin-right: 39px;
                        margin-left: 0;
                    }

                    &-body {
                        order: 2;
                    }
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .package {
        margin-top: 40px;
        margin-left: -20px;
        margin-right:-20px;

        &__item {
            width: calc(~"50% - 40px");
            margin: 0 20px;
            height: 26vw;
            padding: 32px;

            &-name {
                font-size: 2vw;
            }

            &-type {
                font-size: 1.2vw;
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 1140px) {
    .package {
        margin-top: 40px;
        margin-left: -15px;
        margin-right:-15px;

        &__item {
            width: calc(~"50% - 30px");
            margin: 0 15px;
            height: 300px;
            padding: 40px;

            .btn {
                font-size: 12px;
                line-height: 15px;
                padding: 11px 20px;
            }

            &-name {
                font-size: 24px;
                line-height: 121%;
            }

            &-type {
                font-size: 14px;
                line-height: 160%;
                margin-bottom: 10px;
            }
        }
    }
}

@media (max-width: 960px) {
    .package {
        display: block;
        margin-left: -40px;
        margin-right: -40px;

        &__item {
            width: auto;
            border-radius: 0;
            margin: 0;
            
            &::before {
                background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
            }
        }
    }
}

@media (max-width: 640px) {
    .package {
        display: block;
        margin-left: -32px;
        margin-right: -32px;

        &__item {
            height: 360px;
        }
    }
}

@media (max-width: 480px) {
    .package {
        display: block;
        margin-left: -24px;
        margin-right: -24px;

        &__item {
            height: 270px;
        }
    }
}