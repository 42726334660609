.production {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    z-index: 1;

    .more {
        margin-left: 50px;
    }

    &__list {
        padding: 0;
        margin: 51px 0  48px 0;

        &-name {
            font-weight: 700;
            font-size: 21px;
            line-height: 140%;
            margin-bottom: 9px;
        }

        li {
            font-weight: 500;
            font-size: 18px;
            line-height: 157%;
            display: flex;
            align-items: flex-start;
            margin-bottom: 24px;

            svg {
                margin-right: 20px;
                flex: none;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__back {
        display: flex;
        align-items: center;
        justify-content: center;
        pointer-events: none;
        position: absolute;
        left: 0;
        right: 0;
        top: 23px;
        margin: 0 auto;
    }

    &__img {
        position: relative;

        img {
            &:last-child {
                position: absolute;
                left: -201px;
                bottom: -288px;
            }
        }
    }

    &__body {
        width: 440px;
        padding-top: 128px;

        h2 {
            margin-bottom: 32px;
            max-width: 80%;
        }   

        p {
            font-weight: 500;
            font-size: 18px;
            line-height: 157%;
            margin-bottom: 32px;
        }
    }

    &--rein {
        position: relative;

        .play-icon {
            position: absolute;
            top: 237px;
            left: 533px;
            z-index: 2;
        }
    }

    &--compound {
        .play-icon {
            position: absolute;
            bottom: initial;
            transform: translateX(0);
            top: 93px;
            right: 342px;
            z-index: 2;
        }

        .btn {
            margin-left: 50px;
        }

        .production {
            &__img {
                margin-top: 176px;

                img {
                    &:last-child {
                        position: absolute;
                        left: -85px;
                        bottom: -330px;
                    }
                }
            }

            &__body {
                padding-top: 0;
                width: 560px;

                h2 {
                    max-width: initial;
                }
            }
        }
    }

    &--inner {
        align-items: flex-start;
        position: relative;

        .production {
            &__body {
                padding-top: 43px;
                width: 50%;

                p {
                    margin-bottom: 7px;
                    max-width: 95%;
                }
            }

            &__img {
                padding-right: 117px;
                padding-top: 64px;

                .play-icon {
                    position: absolute;
                    top: 15px;
                    left: -29px;
                    bottom: initial;
                    transform: translateX(0);
                }

                img {


                    &:last-child {
                        position: absolute;
                        right: 11px;
                        left: initial;
                        bottom: initial;
                        top: 530px;
                    }
                }
            }
        }
    }

    &--plant {
        .play-icon {
            z-index: 2;
            position: absolute;
            top: 160px;
            left: 522px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .production {
        .more {
            margin-left: 38px;
            font-size: 0.75vw;
        }

        &--inner {
            .production {
                &__img {
                    padding-right: 0;
                }
            }
        }

        &__list {
            margin: 36px 0 23px 0;

            &-body {
                font-size: 1.1vw;
                line-height: 1.5;
            }

            &-name {
                   font-size: 1.3vw;
            }

            li {
                margin-bottom: 18px;

                svg {
                    width: 2vw;
                    height: auto;
                    margin-right: 14px;
                }
            }
        }

        &__body {
            h2 {
                margin-bottom: 25px;
            }

            p {    
                line-height: 154%;
                font-size: 1.1vw;
            }
        }

        &--plant {
            .production {
                &__img {
                    position: relative;
                    padding-top: 38px;

                    img {
                        &:first-child {
                            width: 35vw;
                        }

                        &:last-child {
                            width: 20vw;
                            left: -143px;
                            bottom: -202px;
                        }
                    }
                }

                &__body {
                    width: 39%;
                }
            }
            
            .play-icon {
                width: 10vw;
                height: 12vw;
                top: 198px;
                left: 369px;

                svg {
                    &:first-child {
                        width: 100%;
                        height: auto;
                    }

                    &:last-child {
                        width: 2vw;
                    }
                   
                }
            }
        }

        &--rein {
            padding-bottom: 60px;

            .play-icon {
                width: 10vw;
                height: 12vw;
                top: 167px;
                left: 369px;

                svg {
                    &:first-child {
                        width: 100%;
                        height: auto;
                    }

                    &:last-child {
                        width: 2vw;
                    }
                   
                }
            }

            .production {
               

                &__body {
                    width: 42%;
                    padding-top: 90px;
                }

                &__img {
                    width: 35vw;

                    img {
                        &:last-child {
                            position: absolute;
                            left: -146px;
                            bottom: initial;
                            width: 20vw;
                            top: 35vw;
                        }
                    }
                }
            }
        }

        &--compound {
            .btn {
                margin-left: calc(~"2vw + 14px");
            }
            .play-icon {
                width: 10vw;
                height: 12vw;
                top: 26px;
                right: 293px;

                svg {
                    &:first-child {
                        width: 100%;
                        height: auto;
                    }

                    &:last-child {
                        width: 2vw;
                    }
                   
                }
            }
            .production {
                &__body {
                    width: 46%;
                }

                &__back {

                }

                &__img {
                    margin-top: 131px;
                    padding-right: 10px;

                    img {
                        &:first-child {
                            width: 34vw;
                            width: 34.5vw;
                        }

                        &:last-child {
                            width: 19vw;
                            left: -56px;
                            bottom: -225px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .production {

        .more {
            margin-left: 0;
        }
        

        &__img {
            img {
                &:first-child {
                    width: 353px;

                }

                &:last-child {
                    width: 180px;
                }
            }
        }

        h2 {
            font-size: 24px;
            line-height: 121%;
            margin-bottom: 24px;
           
        }

        &__body {
            p {
                font-size: 13px;
                line-height: 160%;
                margin-bottom: 24px;
            }
        }

        &__list {
            margin-top: 24px;
            margin-bottom: 24px;

            li {
                font-size: 13px;
                line-height: 160%;

                svg {
                    width: 20px;
                    height: auto;
                    margin-right: 12px;
                }
            }

            &-name {
                font-size: 16px;
                line-height: 140%;
                margin-bottom: 6px;
            }

            
        }

        &__back {
            img {
                width: 463px;
            }
        }

        &--compound {
            .btn {
                margin-left: 32px;
            }
            .play-icon {
                width: 100px;
                top: 31px;
                right: 333px;

                svg {
                    &:first-child {
                        width: 100%;
                    }

                    &:last-child {
                        width: 26px;
                        height: auto;
                    }
                }
            }
            .production {
               

                &__body {
                    width: 43%;
                    flex: none;

                    h2 {
                        max-width: 90%;
                    }
                }

                &__img {
                    margin-top: 102px;

                    img {
                        &:last-child {
                            left: -109px;
                            bottom: -221px;
                        }
                    }
                }
            }
        }

        &--plant {
            padding-bottom: 116px;
            
            .play-icon {
                width: 144px;
                height: 188px;
                top: -38px;
                right: 297px;
                left: inherit;

                svg {
                    &:first-child {
                        width: 100%;
                    }

                    &:last-child {
                        width: 26px;
                        height: auto;
                    }
                }
            }

            h2 {
                font-size: 30px;
                line-height: 110%;
                margin-bottom: 31px;
            }

            .production {
                &__body {
                    width: 51%;
                    padding-top: 75px;

                    .btn {
                        font-size: 12px;
                        line-height: 15px;
                        padding: 9px 19px;
                    }

                    p {
                        max-width: 85%;
                        margin-bottom: 34px;
                    }
                }

                &__img {
                    img {
                        &:last-child {
                            width: 148px;
                            bottom: inherit;
                            top: 373px;
                            left: inherit;
                            right: 245px;
                        }
                    }
                }
            }
        }

        &--rein {
            padding-top: 124px;
            padding-bottom: 206px;

            &~.production {
                &__back {
                    top: 62px;
                }
            }

            .play-icon {
                width: 144px;
                height: 188px;
                top: 82px;
                right: 297px;
                left: inherit;

                svg {
                    &:first-child {
                        width: 100%;
                    }

                    &:last-child {
                        width: 26px;
                        height: auto;
                    }
                }
            }

            h2 {
                font-size: 30px;
                line-height: 110%;
                margin-bottom: 31px;
            }

            .production {
                &__img {
                    img {
                        &:last-child {
                            left: -130px;
                            bottom: -174px;
                            width: 199px;
                        }
                    }
                }
                &__body {
                    width: 52%;
                    padding-top: 73px;

                    .btn {
                        font-size: 12px;
                        line-height: 15px;
                        padding: 9px 19px;
                    }

                    p {
                        max-width: 85%;
                        margin-bottom: 34px;
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .production {
        



        &__img {
            display: none;
        }

        &__list {
            display: flex;
            flex-wrap: wrap;
            margin-left: -12px;
            margin-right: -12px;
            margin-top: 37px;
            margin-bottom: -6px;

            li {
                width: 50%;
                padding: 0 12px;
            }
        }

        &--compound  {
            .btn {
                margin-top: 30px;
                margin-left: 0;
                margin-bottom: 20px;
            }

            .play-icon {
                width: 80px;
                display: flex;
                top: -59px;
                right: 0;

                svg {
                    &:first-child {
                        path {
                            stroke: #FF6600;
                            fill: #FF6600;
                        }
                    }

                    &:last-child {
                        width: 21px;
                        
                        path {
                            fill: #fff;
                        }
                    }
                }
            }

            .production {
                &__body {
                    width: 100%;

                    h2 {
                        max-width: 78%;
                        margin-bottom: 37px;
                    }
                    
                }
            }
        }

        &--plant {
            .play-icon {
                top: 36px;
                right: 126px;
                display: flex;
                width: 100px;
                height: 115px;

                svg {
                    &:nth-child(1) {
                        height: auto;
                    }
                }

                
            }

            .production {
                &__body {
                    width: 59%;
                }

                &__img {
                    display: block;
                    width: 378px;
                    margin-right: -151px;
                    flex: none;
                    margin-top: 110px;

                    img {
                        max-width: initial;
                        width: 100%;

                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }

        &--rein {
            padding-top: 0;
            padding-bottom: 32px;

            .play-icon {
                top: 8px;
                right: 163px;
                display: flex;
                width: 100px;
                height: 115px;

                svg {
                    &:nth-child(1) {
                        height: auto;
                    }
                }

                
            }

            .production {
                &__body {
                    width: 56%;
                    padding-top: 32px;
                }

                &__img {
                    display: block;
                    width: 232px;
                    margin-right: 0;
                    flex: none;
                    margin-top: 36px;

                    img {
                        max-width: initial;
                        width: 100%;

                        &:last-child {
                            left: 29px;
                            bottom: inherit;
                            width: 148px;
                            top: 278px;
                       
                        }
                    }
                }
            }
        }

        &--inner {
            .production {
                &__img {
                    display: none;
                }

                &__body {
                    padding-top: 0;
                    width: 100%;
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .production {
        &--compound {
            .production {
                &__body {
                    h2 {
                        max-width: 78%;
                        margin-bottom: 14px;
                    }
                }
            }
        }

        &--plant {
            padding-bottom: 0;
            flex-direction: column;
            align-items: flex-start;

            .play-icon {
                top: -4px;
                right: inherit;
                display: flex;
                width: 100px;
                height: 115px;
                left: 180px;
            }

            &~.production {
                &__back {
                    display: none;
                }
            }

            h2 {
                max-width: 100%;
            }

            .production {

                

                &__img {
                    margin-top: 0;
                    width: 230px;
                    order: 1;
                }

                &__body {
                    width: 100%;
                    padding-top: 18px;
                    order: 2;

                    p {
                        max-width: 100%;
                    }
                }
            }
        }

        &--rein {
            padding-bottom: 0;
            flex-direction: column;
            align-items: flex-start;

            .play-icon {
                top: 23px;
                right: inherit;
                display: flex;
                width: 100px;
                height: 115px;
                left: 209px;
            }

            &~.production {
                &__back {
                    display: none;
                }
            }

            h2 {
                max-width: 100%;
            }

            .production {

                

                &__img {
                    margin-top: 0;
                    width: 230px;
                    order: 1;

                    img {
                        &:last-child {
                            left: 270px;
                            bottom: inherit;
                            width: 148px;
                            top: 154px;
                            opacity: 0.3;
                        }
                    }
                }

                &__body {
                    width: 100%;
                    padding-top: 18px;
                    order: 2;

                    p {
                        max-width: 100%;
                    }
                }
            }
        }

        &__list {
            margin-bottom: 24px;
            
            li {
                width: 100%;
            }
        }
    }
}

@media(max-width: 480px) {
    .production {
        &--rein {
            .production {
                &__img {
                    img {
                        &:last-child {
                            left: 160px;
                            top: 218px;
                        }
                    }
                }
            }
        }
    }
}