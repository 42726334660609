.tablew {
    table-layout: fixed;
    border-collapse: collapse;
    width: 100%;
    border-radius: 3px;
    overflow: hidden;

    tbody {
        tr {
            td {
                text-align: center;
                vertical-align: top;
                border: 1px solid #EAEAEA;
                padding-top: 12px;
                padding-bottom: 12px;
                color: #535353;
                font-size: 18px;
                line-height: 18px;
                height: 60px;

                &:last-child {
                    width: 98px;
                }

                &:nth-child(2) {
                    width: 480px;
                    text-align: left;
                    padding: 12px 10px;
                    vertical-align: middle;
                }

                &:first-child {
                    width: 30px;
                    vertical-align: middle;
                    padding: 12px  6px;
                }
            }
        }
    }

    &__total {
        font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                color: #535353; 
                background: #F2F2F2;
                padding-top: 12px;
                padding-bottom: 12px;
                border: 1px solid #EAEAEA;

                &:nth-child(2) {
                    padding: 12px 10px;
                    width: 480px;
                    text-align: left;
                }
                

                &:first-child {
                    width: 30px;
                    padding: 12px  6px;
                }
    }

    thead {
        tr {
            th {
                font-weight: bold;
                font-size: 16px;
                line-height: 18px;
                color: #535353; 
                background: #F2F2F2;
                padding-top: 12px;
                padding-bottom: 12px;
                border: 1px solid #EAEAEA;

                &:last-child {
                    width: 98px;
                }

                &:nth-child(2) {
                    padding: 12px 10px;
                    width: 480px;
                    text-align: left;
                }

                &:first-child {
                    width: 30px;
                    padding: 12px  6px;
                }
            }
        }
    }
}