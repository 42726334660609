.excellence {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-bottom: -24px;

    &__item {
        width: 240px;
        color: #fff;
        margin-bottom: 24px;
        margin-right: 30px;
    }

    &__number {
        margin-bottom: 8px;
    }

    &__number {
        font-weight: 800;
        font-size: 48px;
        line-height: 100%;
        font-feature-settings: 'pnum' on, 'lnum' on;
    }

    &__name {
        font-weight: bold;
        font-size: 20px;
        line-height: 140%;
        margin-bottom: 4px;
    }

    &__text {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
    }

    &--big {
        margin-bottom: -42px;

        .excellence {
            &__item {
                width: 346px;
                margin-bottom: 42px;
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .excellence {
        &--big {
            margin: 41px -20px 0;

            .excellence {
                &__item {
                    width: 33.3%;
                    padding: 0 20px;
                    margin-right: 0;
                }
            }
        }

        &__name {
            font-size: 1.2vw;
        }

        &__text {
            font-size: 0.9vw;
        }

        &__number {
            margin-bottom: 11px;

            img {
                height: 27px;
            }
        }
    }
}

@media (max-width: 1140px) {
    .excellence {
        &__name {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 4px;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }

        &__name {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 4px;
        }

        &__number {
            margin-bottom: 8px;

            img {
                margin: 0 auto;
            }
        }

        &__item {
            width: 33.3%;
            text-align: center;
            margin-right: 0;
            padding: 0 10px;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }

        &--big {
            .excellence {
                &__item {
                    width: 33.3%;
                    padding: 0 15px;
                    margin-bottom: 30px;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .excellence {
        &--big {
            .excellence {
                &__item {
                    width: 50%;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .excellence {
        &--big {
            .excellence {
                &__item {
                    width: 100%;
                }
            }
        }
    }
}