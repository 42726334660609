.comp {
    display: flex;

    &__scroll {
        display: none;
    }

    &__row {
        height: 100px;
        display: flex;
        align-items: center;    
    }

    &__name {
        font-weight: bold;
        font-size: 26px;
        line-height: 130%;
    }

    &__item {
        display: flex;
        align-items: center;
        height: 223px;
        padding: 0 40px;

        &-wrap {
            display: flex;
            align-items: center;

            img {
                margin-right: 26px;
            }
        }
    }

    &__main {
        width: 400px;
        padding-right: 35px;
        flex: none;

        .comp {
            &__row {
                font-weight: 500;
                font-size: 20px;
                line-height: 140%; 
                color: #000000;
            }
        }
    }

    &__col {
        flex: none;
        width: 400px;

        .comp {
            &__row {
                padding: 0 40px;
                font-weight: 500;
                font-size: 20px;
                line-height: 140%;

                &:nth-child(even) {
                    background-color:  rgba(0, 0, 0, 0.03);
                }
            }
        }

        &--orange {
            background-color: @orange;
            border-radius: 32px 0px 0px 32px;
            color: #fff;
            
            .comp {
                &__row {
                    &:nth-child(even) {
                        background-color:  rgba(0, 0, 0, 0.03);

                    }
                }
            }
        }

        &--grey {
            background-color: @grey-1;
            border-radius: 0px 32px 32px 0px;
        }
    }

    &__head {
        height: 233px;
        font-weight: bold;
        font-size: 36px;
        line-height: 121%;
        color: #000000;
    }

    &--reinforcement {
        .comp {
            &__head {
                padding-top: 26px;
            }

            &__img {
                margin-top: -58px;
                margin-right: -43px;
            }
        }
    }

    &--plant {
        .comp {
            &__item-wrap {
                flex-direction: column;
                align-items: center;
                justify-content: center;
               
                img {
                    margin-right: 0;
                    margin-left: 35px;
                }
            }

            &__img {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 17px;
            }

            &__item {
                align-items: center;
                justify-content: center;
            }
            
            &__name {
                font-size: 21px;
                line-height: 140%;
            }
        }
    }

    &--three {
        .comp {
            &__col {
                width: 281px;

                &:nth-child(3) {
                    border-radius: 0;
                }

                &:nth-child(4) {
                    border-radius: 0px 32px 32px 0px;
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .comp {
        &__head {
            font-size: 2.3vw;
            height: 12vw;
            padding-top: 0;
        }

      

        &__main {
            width: 30%;
            padding-right: 21px;
            flex: none;

            .comp {
                &__row {
                    height: 5vw;
                    font-size: 1.3vw;
                }
            }
        }

        &__item {
            height: 12vw;
        }

        &__col {
            width: calc(~"(100% - 33%) / 2");
        }

        &__name {
            font-size: 1.6vw;
        }

        &__col {
            .comp {
                &__row {
                    height: 5vw;
                    font-size: 1.3vw;
                }
            }
        }

        &--plant {
            .comp {
                &__name {
                    font-size: 1.3vw;
                }

                &__img {
                    margin-bottom: 10px;
                    
                    img {
                        max-height: 8vw;
                        
                    }
                }
            }
        }

        &--compound {
            .comp {
                &__main {
                    width: 33%;
                }

                &__img {
                    width: 5vw;
                    margin-right: 15px;
                }

                &__item {
                    height: 158px;
                    padding: 0 23px;
                }

                &__col {
                    .comp {
                        &__row {
                            padding: 0 23px;
                        }
                    }
                }

                &__head {
                    padding-top: 19px;
                    height: 158px;
                }
            }
        }

        &--reinforcement {
            margin-top: 40px;
            margin-bottom: 40px;
            
            .comp {
                &__head {
                    padding-top: 0;
                }

                &__item {
                    &-wrap {
                        img {
                            max-height: 89px;
                        }
                    }
                }

                &__img {
                    margin-top: 0;
                    margin-right: -23px;

                    
                }
            }
        }

        &--three {
            .comp {
                &__col {
                    width: calc(~"(100% - 30%) / 3");
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .comp {
        &__head {
            display: flex;
            align-items: center;
            font-size: 24px;
            line-height: 121%;
            height: 159px;
        }

        &__item {
            height: 159px;
        }

        &__row {
            height: 56px;
        }

        &__col {
            width: calc(~"(100% - 339px) / 2");
        }

        &__col {
            .comp {
                &__row {
                    font-size: 16px;
                    line-height: 140%;
                    justify-content: center;
                }
            }
        }

        &__main {
            width: 339px;
            padding-right: 53px;

            .comp {
                &__row {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }

        &__name {
            font-size: 19px;
            line-height: 130%;
        }

        &--compound {
            .comp {
                &__img {
                    order: 1;
                    margin-top: 11px;
                }
                &__item {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    &-wrap {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                    }
                    
                    img {
                        width: 64px;
                        margin-right: 0;
                 
                    }
                }
            }
        }

        &__wrap {
            &--plant {
                padding-top: 17px;
                position: relative;
                overflow-x: auto;
                margin-right: -40px;

                .comp {
                    margin-right: 35px;
                    
                    &__scroll {
                        display: block;
                        position: absolute;
                        left: 171px;
                        top: 0;
                    }
    
                }
            }
        }

        &--plant {
            .comp {
                
                &__main {
                    width: 245px;
                    padding-right: 53px;
                }

               
                &__col {
                    width: 269px;

                    &:nth-child(3) {
                        border-right: 1px solid #d6d6d6;
                    }
                }

                &__name {
                    font-size: 19px;
                    line-height: 130%;
                }

                &__item {
                    &-wrap {
                        img {
                            max-height: 80px;
                            margin-left: 19px;
                        }
                    }
                }
            }
        }

        &--reinforcement {
            .comp {
                
                &__main {
                    width: 340px;
                    padding-right: 53px;
                }

               
                &__col {
                    width: 269px;
                }

                &__name {
                    font-size: 19px;
                    line-height: 130%;
                    order: 1;
                }

                &__img {
                    order: 2;
                    margin-top: 0;
                    margin-right: -10px;
                }

                &__item {
                    align-items: center;
                    justify-content: center;
                    text-align: center;

                    &-wrap {
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        text-align: center;
                        img {
                            max-height: 80px;
                            margin-left: 19px;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .comp {
        width: 683px;
        margin-right: 40px;

        &__col {
            width: calc(~"(100% - 273px) / 2");
        }

        &__scroll {
            display: block;
            position: absolute;
            left: 171px;
            top: 0;
        }

        &__main {
            width: 273px;
        }

        &__wrap {
            padding-top: 17px;
            position: relative;
            overflow-x: auto;
            margin-right: -40px;
        }

        &--pro {
            .comp__item {
                justify-content: center;
            }
            .comp__img {
                margin-right: 10px;
                flex: none;
                width: 32px;

                img {
                    margin-right: 0;
                }
            }
            .comp {
                

                &__col {
                    width: 301px;
                }

                &__main {
                    width: 239px;
                    padding-right: 29px;
                }

                &__head {
                    padding-top: 0;
                }
            }
        }

        &--reinforcement {


            .comp {
    

                &__col {
                    width: 222px;
                }

                &__main {
                    width: 239px;
                    padding-right: 29px;
                }

                &__head {
                    padding-top: 0;
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .comp {
        &__main {
            width: 249px;
            padding-right: 0;
        }
    }
}