.inner-text {
    max-width: 964px;
    
    p {
        font-weight: 600;
        font-size: 30px;
        line-height: 134%;  
        color: #FFFFFF;
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .inner-text {
        p {
            font-size: 2.2vw;
        }
    }
}

@media(max-width: 1141px) {
    .inner-text {
        max-width: 674px;
        p {
            font-size: 20px;
            line-height: 134%;
        }
    }
}

@media(max-width: 960px) {
    .inner-text {
        p {
            font-size: 20px;
line-height: 134%;
        }
    }
}