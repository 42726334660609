.menu-bottom {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    a {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
        color: #FFFFFF;
        margin-bottom: 13px;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

@media (min-width: 960px) {
    .menu-bottom {
        a {
            &:hover {
                color: @grey-2;
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .menu-bottom {
        a {
            margin-bottom: 9px;
            font-size: 1.1vw;
        }
    }
}