.radio {
    display: inline-flex;
    align-items: center;
    position: relative;

    input {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        z-index: 2;

        &:checked {
            &~.radio {
                &__control {
                    background-color: @orange;
                }
            }
        }
    }

    &__control {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        margin-right: 8px;
        border: 5px solid transparent;
        transition: background-color 0.3s ease;
    }

    &__line {
        flex-wrap: wrap;
        display: flex;
        margin-bottom: -12px;
        margin-top: 17px;

        .radio {
            margin-right: 24px;
            margin-bottom: 12px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__item {
        display: inline-flex;
        align-items: center;
    }

    &__name {
        font-size: 17px;
        line-height: 150%;
    }

    &--white {
        .radio {
            &__name {
                color: #FFFFFF;
            }

            &__control {
                border-color: #fff;
                background-color: #fff0e6;
            }
        }
    }
}

@media (max-width: 1140px) {
    .radio {
        &__name {
            font-size: 14px;
            line-height: 150%;
        }
    }
}