.object {
    display: flex;
    align-items: flex-end;
    margin: 0 -34px 50px;

    &__item {
        width: calc(~"20% - 68px");
        margin: 0 34px;
    }

    &__name {
        font-weight: bold;
        font-size: 18px;
        line-height: 140%;
        margin-right: -46px;
        margin-bottom: 8px;
    }

    &__img {
        margin-bottom: 20px;
    }

    &__text {
        font-size: 14px;
        line-height: 160%;
        margin-right: -27px;
    }
}

@media (min-width: 960px) {
    .object {
        &__item {
            transform: translateY(100px);
            transition: transform 0.8s ease;

            &.is-active {
                transform: translateY(0);
            }

            &:nth-child(1) {

            }

            &:nth-child(2) {
                transition-delay: 0.3s;
            }

            &:nth-child(3) {
                transition-delay: 0.6s;
            }

            &:nth-child(4) {
                transition-delay: 0.9s;
            }
        }
    }
}

@media (max-width: 1920px) {
    .object {
        &__item {
            width: calc(~"25% - 68px");
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .object {
        &__name {
            font-size: 1.1vw;
            margin-right: -50px;
        }

        &__img {
            width: 9.5vw;
        }

        &__text {
            font-size: 0.85vw;
        }
    }
}

@media (max-width: 1140px) {
    .object {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin: 0 -10px 56px;
    
        &__item {
            width: calc(~"50% - 20px");
            margin: 0 10px 16px;
        }
    
        &__name {
            font-size: 13px;
            line-height: 160%;
            margin-right: 0;
        }
    
        &__img {
            width: 64px;
            height: auto;

            img {
                width: 100%;
            }
        }
    
        &__text {
            font-size: 11px;
            line-height: 160%;
            margin-right: 0;
        }
    }
}

@media (max-width: 960px) {
    .object {
        &__name {
            margin-right: 0;
        }

        &__text {
            margin-right: 0;
        }
    }
}

@media (max-width: 480px) {
    .object {
        margin-bottom: 32px;
        
        &__item {
            width: calc(~"100% - 16px");
            display: flex;
            align-items: center;
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        &__name {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 4px;
        }

        &__text {
            font-size: 11px;
            line-height: 160%;
        }

        &__img {
            order: 2;
            width: 96px;
            flex: none;
            margin-left: 16px;
        }

        &__body {
            order: 1;
        }
    }
}