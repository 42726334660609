.tizer {
    &__title {
        font-weight: 700;
        font-size: 21px;
        line-height: 140%;
        margin-bottom: 24px;
    }

    &__item {
        display: block;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        color: #2E2E2E;
    }

    &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        filter: grayscale(100%);
        transition: transform 0.3s ease;
        
        &-wrap {
            width: 100%;
            height: 180px;
            overflow: hidden;
            margin-bottom: 10px;

        }
    }
}

@media (min-width: 960px) {
    .tizer__item {
        &:hover {
            .tizer {
                &__img {
                    transform: scale(1.3);
                }

                &__name {
                    color: @orange;
                }
            }
        }
    }
}
