.menu {
    &>ul {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0;

        &>li {
            list-style: none;
            margin-right: 24px;
            position: relative;

            &:last-child {
                margin-right: 0;
            }

            &>a {
                display: inline-flex;
                align-items: center;
                text-transform: uppercase;
                font-weight: 600;
                font-size: 13px;
                line-height: 150%;
                color: #2E2E2E;
            }
        }
    }

    &__control {
        margin-left: 5px;

        .svg-icon {
            display: block;

            use {
                fill: #2E2E2E;
            }
        }
    }
}

.btn-catalog {
    display: none;
}

@media (max-width: 1140px) {
    .menu {
        &>ul {
            &>li {
                margin-right: 20px;

                &>a {
                    font-size: 11px;
                    line-height: 150%;
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px){
    .menu {
        &__control {
            .svg-icon {
                width: 2px;
                height: auto;
            }
        }

        &>ul {
            &>li {
                margin-right: 1.4vw;

                &>a {
                    font-size: 0.8vw;
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .menu {
        &>ul {
            &>li {

                &:hover {
                    .menu-catalog {
                        opacity: 1;
                        pointer-events: auto;
                    }

                    .dropmenu {
                        opacity: 1;
                        pointer-events: auto;
                    }

                    &>a {
                        color: @orange;

                        .menu {
                            &__control {
                                .svg-icon {
                                    use {
                                        fill: @orange;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 960px) {
    .menu {
        display: none;
    }

    .btn-catalog {
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        font-size: 11px;
        line-height: 150%;
        text-transform: uppercase;
        transition: color 0.3s ease;

        &.is-active {
            color: @orange; 
        }

        svg {
            margin-left: 5px;

            use {
                fill: @orange;
            }
        }
    }
    
}