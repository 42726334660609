.advantage {
    display: flex;
    align-items: flex-start;
    padding-left: 83px;

    &__body {
        width: 400px;
        padding-top: 24px;
        flex: none;
    }

    &__main {
        padding-left: 62px;
    }

    h2 {
        margin: 0 0 16px;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 120px;
        height: 137px;
        flex: none;

        img {
            &:first-child {
                position: absolute;
                left: 0;
                top: -4px;
                width: auto;
                max-width: initial;
                margin-left: -29px;
            }

            &:last-child {
                position: relative;
                z-index: 2;
            }
        }
    }

    &__about {
        font-weight: 700;
        font-size: 18px;
        line-height: 157%;
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
        margin-left: 60px;
        max-width: 480px;
    }

    &__item {
        display: flex;
        align-items: center;
        margin-bottom: 64px;
        position: relative;

        &:first-child {
            &::before {
                height: 80px;
                top: -86px;
            }
        }

        &::before {
            content: " ";
            position: absolute;
            left: 59px;
            top: -56px;
            height: 48px;
            width: 1px;
            background-color: @orange;
        }

        &:last-child {
            margin-bottom: 0;

            &::after {
                content: " ";
                position: absolute;
                left: 59px;
                bottom: -173px;
                height: 160px;
                width: 1px;
                background-color: @orange;
            }
        }
    }
}

@media (max-width: 1140px) {
    .advantage {
        padding-left: 0;
        justify-content: space-between;

        &__body {
            width: 277px;
        }

        &__main {
            width: calc(~"50% + 42px");
            padding-left: 0;
        }

        &__icon {
            width: 85px;
            height: 97px;

            img {
                &:first-child {
                    width: 127px;
                    margin-left: -21px;
                }

                &:last-child {
                    max-width: 60%;
                    
                }
            }
        }

        &__item {
            &::before {
                left: 41px;
            }

            &:last-child {
                &::after {
                    left: 41px;
                }
            }

            &:nth-child(2) {
                .advantage {
                    &__icon {
                        padding-left: 5px;
                    }
                }
              
            }
        }

        &__about {
            font-size: 13px;
            line-height: 160%;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
            margin-left: 20px;
        }

        h2 {
            font-size: 30px;
            line-height: 110%;
            margin-bottom: 16px;
        }
    }
}

@media(max-width: 960px) {
    .advantage {
        display: block;
        margin-bottom: 10px;

        &__body {
            width: 100%;
            margin-bottom: 33px;
            padding-top: 0;
        }

        &__main {
            width: auto;
        }

        &__text {
            margin-left: 20px;
            max-width: initial;
        }

        &__item {
            margin-bottom: 32px;

            &:last-child {
                margin-bottom: 0;
            }

            &::before {
                display: none;
            }

            &::after {
                display: none;
            }
        }

        h2 {
            font-size: 24px;
            line-height: 121%;
        }
    }
}

@media (max-width: 640px) {
    .advantage {
        &__item {
            flex-direction: column;
        }

        &__body {
            margin-bottom: 38px;
        }

        &__icon {
            margin-bottom: 12px;
        }

        &__text {
            text-align: center;
            margin-left: 0;
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .advantage {
        padding-left: 56px;

        &__main {
            padding-left: 12.7%;
        }


        &__item {
            &::before {
                left: 3.7vw;
            }

            &::after {
                display: none;
            }

           
        }

        &__body {
            width: 29%;
            padding-top: 13px;
            flex: none;

            h2 {
                margin: 0 0 12px;
            }
        }

        &__icon {
            width: 7.5vw;
            height: 8vw;
            padding-top: 4px;

            img {
                &:first-child {
                    width: 11vw;
                    top: -3px;
                    margin-left: -1.8vw;
                }

                &:last-child {
                    max-width: 60%;
                }
            }
        }

        &__text {
            font-size: 1.1vw;
        }

        &__about {
            font-size: 1.15vw;
        }
    }
}