
.header {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 15;

    &__main {
        display: flex;
        align-items: center; 
    }

    &__info {
        display: inline-flex;
        align-items: center;
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #fff;
        position: relative;
        height: 80px;

        &::before {
            content:" ";
            height: 48px;
            right: 0;
            left: 0;
            pointer-events: none;
            transform: rotate(180deg);
            position: absolute;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.03) 0%, rgba(0, 0, 0, 0) 100%);
            top: 100%;
        }
        
    }
    
    .phone {
        font-weight: bold;
        font-size: 18px;
        line-height: 22px;
        margin-left: 34px;
        color: @orange;
        border-bottom: 2px solid transparent;
        transition: border-color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .header {


        .phone {
            margin-left: 41px;

            &:hover {
                border-color: @orange;
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px){
    .header {
        .phone {
            font-size: 1.2vw;
            margin-left: 2.2vw;
        }

        &__top {
            padding: 0 18px;
            height: 5vw;
        }

        .logo {
            width: 13.5vw;

            img {
                max-width: 100%;
            }
        }
    }
}

@media(max-width: 1920px) {
    .header {
        &__top {
            padding-right: 30px;
        }
    }
}

@media(max-width: 1140px) {
    .header {
        &__top {
            height: 56px;
            padding-right: 32px;
        }

        .phone {
            font-size: 16px;
            line-height: 20px;
            margin-top: 6px;
            margin-left: 29px;
        }

        &__info {

            .social {
                display: none;
            }
        }
    }
}

@media(max-width: 960px) {
    .header {
        &__top {
            padding-right: 0;
        }
   
        &__main {
            .phone {
                display: none;
            }
        }
    }
}

@media(max-width: 480px) {
    .header {
        &__top {
            height: 48px;
        }
    }
}