
.project-small {
    &__item {
        display: block;
        width: 360px;
        cursor: pointer;

        &--all {

            position: absolute;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            z-index: 2;
            bottom: 0;
            top: 0;
            background-color: #fff;
            padding: 0 40px;
            left: -40px;
            width: 420px;
        
        }
    }

    

    &__name {
        font-weight: bold;
        font-size: 21px;
        line-height: 140%;
        color: #2E2E2E;
        margin-bottom: 16px;
        transition: color 0.3s ease;
    }

    &__date {
        font-size: 14px;
        line-height: 160%;
        color: @grey-3;
    }

    &__img {
        height: 100%;
        width: 100%;
        background-position: center;
        background-size: cover;
        filter: grayscale(100%);
        transition: transform 0.3s ease;

        &-wrap {
            height: 360px;
            width: 100%;
            margin-bottom: 16px;
            overflow: hidden;
        }
    }

    &__all {
        a {
            font-weight: 600;
            font-size: 12px;
            line-height: 147%;
            color: @orange;
            letter-spacing: 0.13em;
            text-transform: uppercase;
            padding-bottom: 3px;
            border-bottom: 1px solid @orange;
        }
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
        margin-bottom: 32px;
    }

    &__slider {
        &-wrap {
            position: relative;
        }
        .slick-list {
            overflow: visible;
            margin: 0 -40px;
        }
        .slick-slide {
            padding: 0 40px;
            opacity: 0.8;
            transition: opacity 0.3s ease;

            &.slick-current {
                &~.slick-slide {
                    opacity: 1;
                }
            }
        }

        .slick-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            padding: 0;
            border: 0;
            z-index: 5;
            bottom: 0;
            left: 0;
            width: 80px;
            height: 80px;
            background-color: transparent;
    
            &.slick-prev {
                left: 0;
            }

            &.slick-next {
                left: 80px;
            }
    
            svg {
                path {
                    stroke: #2E2E2E;
                    transition: stroke 0.3s ease;
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .project-small {

        &__item {
            &--all {
                left: -14px;
                padding: 0 14px;
                width: calc(~"22.5vw + 28px");
            }

           
        }

        &__text {
            font-size: 1.2vw;
            line-height: 149%;
            margin-bottom: 16px;
        }

        &__slider {
            .slick-list {
                overflow: visible;
                margin: 0 -14px;
            }
            .slick-slide {
                padding: 0 14px;
            }

            .slick-arrow {
                width: 60px;
                height: 60px;
            }

            .project-small {
                &__item {
                    width: 22.5vw;
                }

                &__name {
                    font-size: 1.4vw;
                }

                &__all {
                    a {
                        font-size: 0.7vw;
                    }
                }

                

                &__date {
                    font-size: 0.85vw;
                }

                &__img {
                   

                    &-wrap {
                        height: 23vw;
                        margin-bottom: 7px;
                    }

                    &-img {
                        
                    }
                }
                
            }
        }
    }
}

@media(min-width: 960px) {
    .project-small__slider {
        .slick-arrow {
            &:hover {
                svg path {
                    stroke: @orange;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .project-small {
        &__item {
            width: 416px;

            &--all {
                width: auto;
                padding: 0;
                background-color: transparent;
                bottom: initial;
                right: 0;
                left: initial;
                top: -42px;
            }
        }

        &__all {
            font-size: 12px;
            line-height: 147%;

            span {
                display: none;
            }
        }

        &__text {
            font-weight: 500;
            font-size: 13px;
            line-height: 160%;
            margin-bottom: 88px;
        }

        &__name {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 6px;
        }

        &__date {
            font-size: 11px;
            line-height: 160%;
        }

        &__img {
            &-wrap {
                height: 272px;
                margin-bottom: 12px;
            }
        }

        &__text {
            display: none;
        }

        &__slider {
            .slick-list {
                overflow: visible;
                margin: 0 -12px;
            }
            .slick-arrow {
                display: none !important;
            }
            .slick-slide {
                padding: 0 12px;
                opacity: 1;
            }

            .project-small {
                &__item {
                    width: 256px;
                }
            }
        }
    }
}

@media (max-width: 9600px) {
    .project-small {
        &__item {
            &-all {
                top: -51px;
            }
        }
    }
}


@media(min-width: 960px) {
    .project-small {
        &__item {
            &:hover {
                .project-small {
                    &__img {
                        transform: scale(1.2);
                    }

                    &__name {
                        color: @orange;
                    }
                }
            }
        }
    }
}