.mobile-menu {
    display: none;
}

@media(max-width: 960px) {
    .mobile-menu {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        height: 100%;
        z-index: 15;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &__control {
            position: absolute;
            top: 0;
            right: 0;
            width: 56px;
            height: 56px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: transform 0.3s ease;

            .svg-icon {
                use {
                    stroke: #fff;
                    fill: transparent;
                }
            }
        }

        &__close {
            cursor: pointer;
            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }

        &__info {
            padding: 0 28px;
        }

        .phone {
            display: inline-flex;
            padding-bottom: 3px;
            color: #fff;
            font-weight: bold;
            font-size: 24px;
            line-height: 121%;
            border-bottom: 3px solid fade(#fff , 30);
        }

        .social {
            margin-left: 0;
            margin-top: 34px;

            &__item {
                width: 44px;
                height: 52px;

                .svg-icon {
                    use {
                        fill: #fff;
                    }
    
                    &--back {
                        width: 100%;
                        height: 100%;

                        use {
                            fill: transparent;
                            stroke: rgba(255, 255, 255, 0.38);
                        }
                    }
                }
            }
        }

        &.is-open {
            opacity: 1;
            pointer-events: auto;
        }

        &__menu {
            width: 100%;
            margin-bottom: 40px;
            flex: none;

            .menu {
                display: flex;
                flex-direction: column;
                width: 100%;

                .dropmenu {
                    position: relative;
                    top: initial;
                    left: initial;
                    opacity: 1;
                    pointer-events: auto;
                    top: initial;
                    width: 100%;
                    padding-top: 8px;
                    padding-bottom: 19px;
                    display: none;

                    &>ul {
                        display: block;


                        &>li {
                            margin-bottom: 27px;

                            
                            &:last-child {
                                margin-bottom: 0;
                            }

                            &>a {
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 150%;
                                background-color: transparent;
                                border: 0;
                                text-transform: initial;
                                padding: 0 28px;
                            }
                        }
                    }
                }

                &>ul {
                    width: 100%;
                    flex-direction: column;

                    &>li {
                        width: 100%;
                        border-top: 1px solid #F55905;
                        margin-right: 0;
                        transition: background-color 0.3s ease;

                        

                        &.is-active {
                            background-color: #F55905;

                            .mobile-menu__control {
                                transform: rotate(180deg);
                            }
                        }


                        &>a {
                            width: 100%;
                            padding: 16px 28px;
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 150%;
                            color: #fff;
                        }
                    }
                }
            }
        }

        &__close {
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
            z-index: 2;
            right: 0;
            background-color: @black;
            width: 56px;
            height: 56px;
        }

        &__body {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            flex-direction: column;
            min-height: 100%;
            padding: 96px 0 47px;
        }

        &__container {
            width: 360px;
            height: 100%;
            position: relative;

            &::before {
                content:" ";
                position: absolute;
                width: 158px;
                top: 0;
                left: -158px;
                bottom: 0;
                background: linear-gradient(270deg, rgba(46, 46, 46, 0.23) 0%, rgba(46, 46, 46, 0) 94.23%);
            }
        }

        &__wrap {
            width: 100%;
            height: 100%;
            background-color: @orange;
            overflow-y: auto;
        }
    }
}

@media(max-width: 480px) {
    .mobile-menu {
        &__container {
            width: 300px;

            &::before {
                width: 74px;
                left: -74px;
            }
        }

        &__close {
            width: 48px;
            height: 48px;
        }
    }
}