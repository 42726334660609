.main-banner {
    position: relative;

    .play-icon {
        position: absolute;
        transition: transform 0.3s linear;
    }

    &__logo {
        margin-bottom: 65px;

        img {
            &:nth-child(2) {
                display: none;
            }
        }
    }

    &__img {
        position: absolute;
        left: 62%;
        top: 109px;

        img {
            width: auto;
            max-width: initial;
            transition: transform 0.3s linear;

            &:last-child {
                top: -187px;
                position: absolute;
                left: -321px;
            }
        }
    }

    &__text {
        font-weight: 600;
        font-size: 18px;
        line-height: 160%;
        max-width: 560px;
        margin-bottom: 29px;
    }
}

@media (max-width: 1920px) {
    .main-banner {
        .play-icon {
            left: 62%;
            bottom: -152px;
        }

        &__img {
           
            left: 68%;
            top: 53px;

            img {
                &:last-child {
                     top: -189px;
                    left: -315px;
                }
            }
        }

        
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .main-banner {
        .play-icon {
            width: 10vw;
            height: 12vw;
            left: 66%;
            bottom: initial;
            top: 24vw;
            
            svg {
                &:first-child {
                    width: 100%;
                    height: auto;
                }

                &:last-child {
                    width: 2vw;
                    height: auto;
                }
            }
        }

        &__logo {
            width: 33vw;
            margin-bottom: 44px;
        }

        &__img {
            left: 69%;
            top: 41px;

            img {
                &:first-child {
                    width: 35vw;
                }

                &:last-child {
                    width: 32vw;
                    top: -10vw;
                    left: -18vw;
                }
            }
        }

        &__text {
            font-size: 1.1vw;
            line-height: 1.7;
            max-width: 69%;
            margin-bottom: 16px;
        }
    }
}

@media(max-width: 1140px) {
    .main-banner {
        .play-icon {
            left: 69%;
            top: 196px;
            width: 100px;
            height: 118px;

            svg {
                &:first-child {
                    width: 100%;
                    height: auto;
                }

                &:last-child {
                    width: 26px;
                }
            }
        }

        &__logo {
            margin-bottom: 27px;

            img {
                width: 310px;
                height: auto;
            }
        }

        &__img {
            left: 65%;
            top: 47px;

            img {
                &:first-child {
                    width: 271px;
                }

                &:nth-child(2) {
                    width: 227px;
                    top: -72px;
                    left: -107px;
                }
            }
        }
    
        &__text {
            font-size: 13px;
            line-height: 160%;
            max-width: 312px;
        }
    }
}

@media (max-width: 960px) {
    .main-banner {
        background-image: url(../images/common/main-banner-back.jpg);
        padding: 83px 41px 56px;
        background-position: top center;
        background-size: cover;

        &__img {
            display: none;
        }

        .more {
            position: relative;
            z-index: 2;
        }

        &__logo {
            margin-bottom: 22px;

            img {
                width: 268px;

                &:nth-child(2) {
                    z-index: 2;
                    display: block;
                    position: relative;
                }
    
                &:nth-child(1) {
                    display: none;
                }
            }
           
        }

        &__text {
            position: relative;
            z-index: 1;
            max-width: 431px;
            color: #fff;
            margin-bottom: 21px;
        }

        &::before {
            position: absolute;
            content:" ";
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            height: 100%;
            background: linear-gradient(360deg, #2E2E2E 0%, rgba(46, 46, 46, 0) 100%);
        }
    }
}


@media (max-width: 640px) {
    .main-banner {
        padding: 78px 32px 68px;

        &__logo {
            img {
                width: 268px;
            }
        }
    }
}

@media (max-width: 480px) {
    .main-banner {
        padding: 43px 24px 61px;

        &__logo {
            img {
                width: 279px;
            }
        }
    }
}