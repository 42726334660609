.select {
    width: 100%;

    .select2-container {
        width: 100% !important;
        height: 56px;

        &.select2-container--open {
           


            &.select2-container--above {
                .select2-selection {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    box-shadow: 0px 4px 20px rgba(165, 60, 3, 0.3);
                }
            }

            &.select2-container--below {
                .select2-selection {
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;
                    box-shadow: 0px 4px 20px rgba(165, 60, 3, 0.3);
                }
            }

            

            .select2-selection__arrow {
                transform: rotate(180deg);
            }
        }

        .select2-selection {
            height: 56px;
            background: #FFFFFF;
            border-radius: 8px;
            transition: border-radius 0.3s, box-shadow 0.3s ease;
            border: 0;

            
        }

        .select2-selection__arrow {
            width: 56px;
            height: 56px;
            position: absolute;
            right: 0;
            top: 0;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../images/common/select.png);
            transition: transform 0.3s ease;

            b {
                display: none;
            }
        }

        .select2-selection__rendered {
            font-weight: 400;
            font-size: 17px;
            line-height: 150%;
            height: 100%;
            width: 100%;
            padding: 15px 20px;

           
        }
    }
    
    .jq-selectbox__trigger {
        width: 56px;
        height: 56px;
        position: absolute;
        right: 0;
        top: 0;
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(../images/common/select.png);
    }

    .jq-selectbox {
        width: 100%;
        transition: box-shadow 0.3s ease;

        &.opened {
            box-shadow: 0px 4px 20px rgba(165, 60, 3, 0.3);
        }

        &.dropdown {
            .jq-selectbox__select {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }

            .jq-selectbox__dropdown {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }
        }

        &.dropup {
            .jq-selectbox__select {
                border-top-left-radius: 0;
                border-top-right-radius: 0;
            }

            .jq-selectbox__dropdown {
                padding-top: 17px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }

    .jq-selectbox__select-text {
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
    }

    .jq-selectbox__dropdown {
        padding: 5px 20px 28px;
        width: 100%;
        background-color: #FFFFFF;
        border-radius: 8px;
        transition: border-radius 0.3s ease;

        ul {
            margin: 0;
            padding: 0;
            max-height: 216px !important;

            li {
                font-weight: 400;
                font-size: 17px;
                line-height: 150%;
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .jq-selectbox__select {
    
        background: #FFFFFF;
        border-radius: 8px;
        transition: border-radius 0.3s ease;
    }

    &--white {
        .jq-selectbox__select {
            height: 56px;
            width: 100%;
            padding: 15px 20px;
        }
    }

}

.select2-search {
    padding: 0;
    margin: 0 20px 15px;

    .select2-search__field {
        font-weight: 400;
        font-size: 17px;
        line-height: 150%;
        height: 40px;
        padding: 0 10px;
        border: 1px solid @orange !important;
    }
}

.select2-dropdown {
    padding: 5px 0 15px;
    border: 0;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
    transition: border-radius 0.3s ease;

    ul {
        margin: 0;
        padding: 0;
        max-height: 216px !important;

        li {
            font-weight: 400;
            font-size: 17px;
            line-height: 150%;
            margin-bottom: 16px;
            padding: 2px 20px;
            background-color: transparent !important;
            transition: color 0.3s ease;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.select2-container--default .select2-results__option--highlighted.select2-results__option--selectable {
    background-color: transparent;
    color: @orange;
}

.select2-results__option.select2-results__message {
    font-size: 0;
    height: 19px;
    position: relative;
}

.select2-results__option.select2-results__message::before {
    content: "Ничего не найдено";
    position: absolute;
    top: 9px;
    font-size: 15px;
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .select {
        .select2-container {
            height: 3.5vw;
    
            .select2-selection {
                height: 3.5vw;
                
            }
    
            .select2-selection__arrow {
                height: 3.5vw;
                width: 3.5vw;
            }
    
            .select2-selection__rendered {
                padding: 11px 14px;
                height: 3.5vw;
                font-size: 1.1vw;
            }
        }

        &--white {
            .jq-selectbox__select {
                padding: 11px 14px;
                height: 3.5vw;
            }
        }

        .jq-selectbox__dropdown {
            padding-left: 14px;
            padding-right: 14px;
            padding-bottom: 17px;

            ul {
                li {
                    font-size: 1.1vw;
                }
            }
        }

        .jq-selectbox__trigger { 
            height: 3.5vw;
            width: 3.5vw;
        }

        .jq-selectbox__select-text {
            font-size: 1.1vw;
        }



    }

    .select2-dropdown {
        padding-bottom: 17px;
    
        ul {
            margin: 0;
            padding: 0;
            max-height: 216px !important;
    
            li {
                font-size: 1.1vw;
                padding: 2px 17px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .select2-dropdown {
        padding-bottom: 17px;
    
        ul {
            margin: 0;
            padding: 0;
            max-height: 216px !important;
    
            li {
                font-size: 15px;
                line-height: 150%;
                padding: 2px 16px;
    
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .select {
        .select2-container {
            height: 48px;
    
            .select2-selection {
                height: 48px;
                
            }
    
            .select2-selection__arrow {
                height: 48px;
                width: 48px;
            }
    
            .select2-selection__rendered {
                padding: 14px 16px;
                height: 48px;
                font-size: 15px;
                line-height: 150%;
            }
        }

        &--white {
            .jq-selectbox__select {
                padding: 14px 16px;
                height: 48px;
            }
        }

        .jq-selectbox__trigger { 
            height: 48px;
            width: 48px;
        }

        .jq-selectbox__dropdown {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 17px;

            ul {
                li {
                    font-size: 15px;
                }
            }
        }

        .jq-selectbox__select-text {
            font-size: 15px;
            line-height: 150%;
        }

        
    }
}