.hamburger {
    display: none;
}

@media(max-width: 960px) {
    .hamburger {
        cursor: pointer;
        width: 56px;
        height: 56px;
        margin-left: 32px;
        flex: none;
        display: flex;
        flex-direction: column;
        background-color: @orange;
        align-items: center;
        justify-content: center;
        z-index: 10;

        span {
            display: block;
            pointer-events: none;
            width: 24px;
            height: 2px;
            background-color: #fff;
            margin: 4px 0 ;
        }
    }
}


@media(max-width: 640px) {
    .hamburger {
        margin-left: 24px;
    }
}

@media(max-width: 480px) {
    .hamburger {
        height: 48px;
        width: 48px;
    }
}