.main-info {
    background-color: #3F2B22;
    font-size: 22px;
    line-height: 120%;
    color: #FFFFFF;
  
    text-align: center;

    &__text {
        text-align: center;
        max-width: 823px;
        margin: 0 auto;
        font-size: 26px;
        line-height: 34px;
        letter-spacing: 0.03em;

        svg {
            display: block;
            margin: 0 auto 22px;
        }
    }

    &__info {
        margin-top: 20px;

        span {
            position: relative;


            &::before {
                content: "";
                position: absolute;
                background-image: url(../images/common/line-text.svg);
                background-repeat: no-repeat;
                width: 181px;
                height: 18px;
                bottom: -25px;
                left: -7px;
            }
        }
    }

    &__wrap {
        padding: 99px 0;
        position: relative;
    }

    &__right {
        position: absolute;
        bottom: 0;
        left: -11px;
    }

    &__left {
        position: absolute;
        top: 0;
        right: -76px;
    }

    &--text {
        text-align: center;
        
        .main-info {
            &__wrap {
                padding: 47px 0 77px;
            }
        }
    }
}

@media(max-width: 768px) { 
    .main-info {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        &__text {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;

            svg {
                width: 12px;
                height: auto;
                margin-bottom: 16px;
            }
        }

        &__info {
            span {
                display: block;

                &::before {
                    width: 117px;
                    height: 10px;
                    background-size: cover;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    bottom: -20px;
                }
            }
        }

        &__wrap {
            padding: 57px 20px  77px !important;
            overflow: hidden; 
            margin: 0 -20px;
        }

        &__left {
            width: 90px;
            right: -10px;
        }

        &__right {
            width: 43px;
            left: 10px;
        }
    }
}

