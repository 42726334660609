.project {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -32px -59px;

    &__item {
        display: block;
        width: calc(~"33.3% - 64px");
        margin: 0 32px 59px;
    }

    &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        filter: grayscale(100%);
        transition: transform 0.3s ease;
       

        &-wrap {
            height: 360px;
            margin-bottom: 16px;
            overflow: hidden;
        }
    }

    &__date {
        color: #6E6E6E;
    }

    &__name {
        font-weight: bold;
        font-size: 21px;
        line-height: 140%; 
        color: @black;
        margin-bottom: 16px;
        transition: color 0.3s ease;
    }
}

@media(min-width: 960px) {
    .project {
        &__item {
            &:hover {
                .project {
                    &__img {
                        transform: scale(1.1);
                    }

                    &__name {
                        color: @orange;
                    }
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .project {
        margin: 0 -23px -41px;

        &__item {
            width: calc(~"33.3% - 46px");
            margin: 0 23px 41px;
        }

        &__img {
            &-wrap {
                margin-bottom: 9px;
                height: 22.5vw;
            }
           
        }

        &__date {
            font-size: 0.9vw;
        }

        &__name {
            font-size: 1.35vw;
            margin-bottom: 10px;
        }
    }
}

@media(max-width: 1140px) {
    .project {
        margin: 0 -18px -63px;

        &__item {
            display: block;
            width: calc(~"33.3% - 36px");
            margin: 0 18px 63px;
        }

        &__img {
            &-wrap {
                height: 198px;
            }
        }

        &__date {
            font-size: 14px;
            line-height: 160%;
        }

        &__name {
            font-size: 16px;
            line-height: 140%;  
            margin-bottom: 6px;
        }
    }
}

@media(max-width: 960px) {
    .project {
        margin: 0 -12px -36px;

        &__item {
            display: block;
            width: calc(~"50% - 24px");
            margin: 0 12px 36px;
        }
    }
}

@media(max-width: 640px) {
    .project {
        margin: 0 -12px -36px;

        &__item {
            display: block;
            width: calc(~"100% - 24px");
            margin: 0 12px 36px;
        }

        &__img {
            &-wrap {
                height: 316px;
                margin-bottom: 12px;
            }
        }
    }
}

@media(max-width: 480px) {
    .project {
        margin: 0 -12px -24px;

        &__item {
            display: block;
            width: calc(~"100% - 24px");
            margin: 0 12px 24px;
        }

        &__img {
            &-wrap {
                height: 221px;
                margin-bottom: 12px;
            }
        }
    }
}



