.case {
    display: flex;
    flex-wrap: wrap;
    margin: 59px -32px -59px;

    &__item {
        display: block;
        width: calc(~"33.3% - 64px");
        margin: 0 32px 74px;
    }

    &__img {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        transition: transform 0.3s ease;

        &-wrap {
            overflow: hidden;
            height: 260px;
            margin-bottom: 16px;
        }
    }

    &__date {
        color: initial;
    }

    &__name {
        font-weight: bold;
        font-size: 21px;
        line-height: 140%; 
        color: @black;
        margin-bottom: 16px;
    }
}

@media(min-width: 960px) {
    .case {
        &__item {
            &:hover {
                .case {
                    &__img {
                        transform: scale(1.3);
                    }
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .case {
        margin-top: 44px;
        margin-left: -21px;
        margin-right: -21px;
        margin-bottom: -50px;

        &__date {
            font-size: 0.9vw;
        }

        &__name {
            font-size: 1.35vw;
            margin-bottom: 10px;
        }

        &__img {
            &-wrap {
                height: 16vw;
                margin-bottom: 9px;
            }
        }

        &__item {
            width: calc(~"33.3% - 42px");
            margin: 0 21px 50px;
        }
    }
}

@media(max-width: 1140px) {
    .case {    
        margin: 0 -18px -43px;

        &__item {
            width: calc(~"33.3% - 36px");
            margin: 0 18px 43px;
        }

        &__img {
            &-wrap {
                height: 195px;
            }
        }

        &__date {
            font-size: 11px;
            line-height: 160%; 
        }

        &__name {
            font-size: 16px;
            line-height: 140%;
            margin-bottom: 6px;
        }
    }
}

@media(max-width: 960px) {
    .case {    
        margin: 0 -12px -36px;

        &__item {
            width: calc(~"50% - 24px");
            margin: 0 12px 36px;
        }
    }
}

@media(max-width: 640px) {
    .case {    
        margin: 0 -12px -32px;

        &__item {
            width: calc(~"100% - 24px");
            margin: 0 12px 32px;
        }

        &__img {
            &-wrap {
                height: 312px;
            }
        }
    }
}


@media(max-width: 640px) {
    .case {    
        margin: 0 -12px -48px;

        &__item {
            margin: 0 12px 48px;
        }

        &__img {
            &-wrap {
                height: 216px;
            }
        }
    }
}