.video {
    &__slider {
        position: relative;

        .slick-list {
            overflow: visible;
            margin: 0 -20px;
        }

        .slick-arrow {
            position: absolute;
            padding: 0;
            border: 0;
            z-index: 5;
            top: -79px;
            right: 0;
            background-color: transparent;

            &.slick-prev {
                right: 95px;
            }

            svg {
                path {
                    stroke: #2E2E2E;
                    transition: stroke 0.3s ease;
                }
            }
        }

        .video {
            &__item {
                width: 100%;
            }

            &__icon {
                .svg-play {
                    width: 23px;
                }

                .svg-icon:first-child {
                    position: absolute;
                    width: 70px;
                    height: auto;
        
                    use {
                        fill: rgba(46, 46, 46, 0.6);
                    }
                }
            }
        }

        .slick-slide {
            opacity: 0.2;
            padding: 0 20px;
            transition: opacity 0.3s ease;

            &.slick-active {
                opacity: 1;
            }
        }
    }

    &__date {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: @grey-3;
        margin-top: 12px;
    }

    &__name {
        font-weight: 500;
        font-size: 18px;
        color: #000000;
        line-height: 157%;
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .svg-play {
            position: absolute;

            use {
                fill: #fff;
            }
        }
    }

    &__image {
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        height: 253px;
        background-position: center;
        background-size: cover;
        background-color: black;
        margin-bottom: 12px;
    }

    &__plit {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -20px -32px;

        &>div {
            width: calc(~"33.3% - 40px");
            margin: 0 20px 32px;
        }

        .video {
            &__item {
                width: 100%;

                &--foto {
                    .video__icon .svg-play use {
                        fill: transparent;
                    } 
                }
            }

            &__icon {
                .svg-icon:first-child {
                    width: 70px;
                    height: auto;

                    use {
                        fill: rgba(46, 46, 46, 0.6);
                    }
                }

                .svg-icon:last-child {
                    width: 23px;
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .video {
        &__date {
            font-size: 0.8vw;
            margin-top: 5px;
        }

        &__slider {
            .video {
                &__image {
                    height: 15.6vw;
                    margin-bottom: 10px;
                }

                &__icon {
                    width: 4vw;

                    .svg-icon {

                        &:last-child {
                            width: 1.5vw;
                        }
                        
                    }
                }

                &__name {
                    font-size: 1.1vw;
                }

                &>div {
                    width: calc(~"33.3% - 30px");
                    margin: 0 15px 26px;
                }

                &__item {
                    width: 100%;
                }
            }
        }

        &__plit {
            margin-left: -15px;
            margin-right: -15px;
            margin-bottom: -26px;

            .video {
                &__image {
                    height: 15.6vw;
                    margin-bottom: 10px;
                }

                &__icon {
                    width: 4vw;

                    .svg-icon {

                        &:last-child {
                            width: 1.5vw;
                        }
                        
                    }
                }

                &__name {
                    font-size: 1.1vw;
                }

                &>div {
                    width: calc(~"33.3% - 30px");
                    margin: 0 15px 26px;
                }

                &__item {
                    width: 100%;
                }
            }
        }
    }
}

@media (min-width: 960px) {
    .video {
        &__plit {
            .video {
                &__item {
                    &:hover {
                        .video {
                            &__icon {
                                .svg-icon:last-child {
                                    use {
                                        fill: #B8B8B8;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .video {
        &__plit {
            display: block;
            margin: 0 0;

            .slick-slide {
                padding: 0 20px;
            }

            &>div {
                width: auto;
                margin: 0 0;

                &.slick-list {
                    margin-left: -20px;
                    overflow: visible;
                }
            }

            .video {
                &__item {
                   width: 300px;
                   margin: 0;
                }

                &__icon {
                    width: 49px;
                }

                &__image {
                    border-radius: 8px;
                    height: 205px;
                    margin-bottom: 7px;
                }
    
                &__icon {
                    .svg-icon:first-child {
                        width: 70px;
                        height: auto;
    
                        use {
                            fill: rgba(46, 46, 46, 0.6);
                        }
                    }
    
                    .svg-icon:last-child {
                        width: 14px;
                    }
                }
            }
        }

        &__date {
            font-size: 12px;
            margin-top: 5px;
        }

        &__slider {
            .video {
                &__item {
                    width: 300px;
                }

                &__icon {
                    .svg-icon {
                        &:first-child {
                            width: 47px;
                        }

                        &:last-child {
                            width: 14px;
                        }
                    }
                }

                &__image {
                    height: 206px;
                    margin-bottom: 9px;
                }

                &__name {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
            .slick-list {
                overflow: visible;
            }

            .slick-slide {
                padding: 0 20px;
            }
        }
    }
}



@media (max-width: 960px) {
    .video {
        &__slider {
            .slick-arrow {
                display: none;
            }
        }

        &__plit {
            .video {
                &__name {
                    font-size: 16px;
                    line-height: 140%;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .video {
        &__plit {
            .slick-slide {
                padding: 0 7px;
            }

            

            & > div.slick-list {
                margin-left: -7px;
                margin-right: -7px;
            }
        }
    }
}