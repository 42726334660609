.popup-big {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    left: 0;
    top: 0;
    padding-top: 80px;
    bottom: 0;
    right: 0;
    z-index: 5;
    background: fade(#2E2E2E , 50);
   opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    .form-ok {
        border-radius: 0 24px 24px 0;
        background: #FFFFFF;

        &__close {
            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }

        &__title {
            color: #fff;
        }

        &__text {
            color: #fff;
        }

        &__wrap {
            background: linear-gradient(180deg, #FF7214 0%, #FB5A04 100%);
            border-radius: 0 24px 24px 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 50px 55px 50px;
        }
    }

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }


    &__type {
        max-width: 90%;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #000000;
        display: flex;
        align-items: center;
        margin-bottom: 32px;

        img {
            margin-right: 16px;
            flex: none;
        }
    }
    
    &__info {
        width: 439px;
        background: @grey-1;
        padding: 20px 64px;
        border-radius:  0 24px 24px 0  ;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &-title {
            font-weight: 700;
            font-size: 21px;
            line-height: 140%;
            margin-bottom: 16px;
        }

        &-order {
            width: 100%;
            margin-bottom: 56px;

            .btn {
                width: 100%;
            }
        }

        &-btn {
            width: 100%;

            .btn {
                width: 100%;
            } 
        }

        &-info {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%; 
            margin-bottom: 24px;

            span {
                display: inline-flex;
                line-height: 1;
                color: @orange;
                border-bottom: 1px solid @orange;
            }
        }

        &-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            margin-bottom: 24px;

            span {
                display: inline-flex;
                line-height: 1;
                color: @orange;
                border-bottom: 1px solid @orange;
            }
        }
    }

    &__tech {
        color: #000000;
        margin-bottom: 32px;

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 5px;
            padding-bottom: 5px;
            margin-bottom: 5px;
            border-bottom: 1px solid @grey-1;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                font-size: 15px;
                line-height: 135%;
                font-weight: 500;
                width: 45%;
                padding-right: 10px;

                &:last-child {
                    width: 55%;
                    font-weight: 600;
                    text-align: right;
                    padding-left: 10px;
                }
            }
        }
    }
    
    &__name {
        font-weight: 700;
        font-size: 36px;
        line-height: 121%;
        color: #000000;
        margin-bottom: 34px;
    }

    &__text {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;

        span {
            font-weight: 700;
            font-size: 18px;
            line-height: 157%;
            display: block;
            margin-bottom: 4px;
        }
    }

    &__form {
        width: 439px;
        z-index: 6;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border-radius: 0 24px 24px 0;
        background-color: @orange;
        padding: 50px 55px 50px;
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }

        .form-big {
            &__section {
                margin-bottom: 23px;
            }

            &__agree {
                margin-top: 6px;
                padding-left: 0;
                color: rgba(255, 255, 255, 0.6);

                a {
                    text-decoration: none;
                    color: rgba(255, 255, 255, 0.6);
                }
            }
            
            &__btn {
                margin-top: 24px;
                display: block;

                .btn {
                    width: 100%;
                }
            }

            &__textinfo {
                font-weight: 400;
                font-size: 14px;
                line-height: 149%;
                color: #fff;
                margin-bottom: 28px;
            }
            
           &__heading {
            margin-bottom: 6px;
           }
    
            &__agree {
                font-size: 14px;
                line-height: 160%; 
            }
            
            &__btn {
                .btn {
                    font-size: 13px;
                    line-height: 16px;
                    padding: 15px 27px;
                }
            }
        }

        &-close {
            cursor: pointer;
            top: 41px;
            right: 41px;
            position: absolute;
            z-index: 5;
    
            .svg-icon {
                width: 16px;
                height: auto;
    
                use {
                    fill:  #fff;
                }
            }
        }
    }

    &__body {
        display: flex;
        width: 761px;
        background-color: #fff;
        padding: 86px 48px 0 70px;
        border-radius: 24px 0 0 24px ;

        &-img {
            margin-left: 74px;
            padding-left: 45px;
        }

        .popup-big__info {
            display: none;
        }
        
        &-tab {
            padding-left: 50px;
        }

        &-tabbody {
            display: none;
            align-items: flex-end;
                justify-content: center;

            img {
                display: block;
            }

            &.is-active {
                display: flex;
                
            }
        }

        &-tablink {
            font-weight: 700;
            font-size: 18px;
            line-height: 157%;
            color: @grey-3;
            cursor: pointer;
            padding-bottom: 3px;
            border-bottom: 3px solid @grey-3;
            margin-right: 30px;
            transition: color 0.3s, border-color 0.3s ease;

            &:last-child {
                margin-right: 0;
            }

            &.is-active {
                color: @black;
                border-color: @orange;
            }
        }

        &-tabcontrol {
            display: flex;
            align-items: center;
            margin-bottom: 24px;
        }

        &-main {
            width: 340px;
            flex: none;
        }
    }

    &__wrap {
        display: flex;
        align-items: center;
        position: relative;
        width: 1200px;
        height: 697px;
        height: auto;
        max-height: 100%;
        position: relative;
        align-items: stretch;
    }

    &__close {
        cursor: pointer;
        top: 41px;
        right: 41px;
        position: absolute;
        z-index: 5;

        .svg-icon {
            width: 16px;
            height: auto;

            use {
                fill:  #2E2E2E;
            }
        }
    }

    &--compound {
        .popup-big__body-tabbody {
            height: 551px;
        }
    }

    &--comp {
        .popup-big__body-img {
            display: flex;
            align-items: flex-end;
            justify-content: center;
        }
    }
   
}

@media(min-width: 1600px) {
    .popup-big {
        &--plant {
            .popup-big {
                &__body {
                    &-img {
                        min-height: 697px;
                    }
                }
            }
        }
    }
}

.popup-big--plant .popup-big__body-img--big {
    justify-content: center;
    align-items: flex-end;
    display: flex; 
}

@media(min-width: 640px) {
    .popup-big {
        &--plant {
            .popup-big {
                &__body {
                    padding-top: 0;
                    padding-right: 0;
                    overflow: hidden;
    
                    &-img {
                        flex-grow: 1;
                        padding-top: 36px;
                        padding-right: 25px;

                        &--big {
                            padding-right: 0;
                            position: relative;
                        }
                    }
    
                    &-main {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        padding-bottom: 36px;
                    }
                }
            }
        }
    }
}

@media (min-width: 641px) and (max-width: 1140px) {
    .popup-big {
        &--plant {
            .popup-big {
                &__body {
                    &-img {
                        min-height: 650px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .popup-big {
        &--plant {
            .popup-big {
                &__body {
                    &-img {
                        min-height: 45vw;
                        display: flex;
                        justify-content: center;
                        align-items: flex-end;
                    }
                }
            }
        }

        &__wrap {
            width: 75vw;
            height: 89%;
        }

        &__name {
            font-size: 2.35vw;
            margin-bottom: 20px;
        }

        &__text {
            font-size: 0.9vw;

            span {
                font-size: 1.15vw;
            }
        }

        &__tech {
            margin-bottom: 20px;

            div {
                margin-bottom: 10px;

                span {
                    font-size: 0.95vw;
                }
            }
        }

        &__close {
            top: 29px;
            right: 29px;

            .svg-icon {
                width: 13px;
                height: auto;
            }
        }

        &__body {
            width: 63%;
            padding: 69px 50px 0;
            flex: none;

            &-tablink {
                font-size: 1.1vw;
                margin-right: 20px;
                padding-bottom: 1px;
                border-bottom-width: 2px;
            }

            &-main {
                width: 53%;
                flex: none;
            }
        }

        &__info {
            width: 37%;
            padding-left: 43px;
            padding-right: 43px;
            padding-top: 20px;
            padding-bottom: 20px;

            &-info {
                font-size: 0.95vw;
                line-height: 152%;
                margin-bottom: 15px;
            }

            &-text {
                font-size: 0.95vw;
                line-height: 152%;
                margin-bottom: 18px;
            }

            &-title {
                font-size: 1.1vw;
                max-width: 80%;
                margin-bottom: 13px;
            }
        }

        &__form {
            width: 37%;
            padding: 55px 26px 35px 48px;

            &-close {
                top: 29px;
                right: 29px;
    
                .svg-icon {
                    width: 13px;
                    height: auto;
                }
            }

            .form-big {
                &__section {
                    margin-bottom: 19px;
                }

                &__agree {
                    font-size: 0.9vw;
                }

                &__btn {
                    margin-top: 14px;
                }
            }
        }

        &--compound {
            .popup-big {
                &__body-tabbody {
                    height: 33vw;

                    img {
                        max-height: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .popup-big {
        padding: 40px;

        &__wrap {
            width: 100%;
        }

        &__name {
            font-size: 19px;
            line-height: 130%;
            margin-bottom: 24px;
        }

        &__text {
            font-size: 11px;
            line-height: 160%;

            span {
                font-size: 13px;
                line-height: 160%; 
            }
        }

        &__tech {
            margin-bottom: 30px;

            div {

                span {
                    font-size: 13px;
                    line-height: 135%;
                }
            }
        }

        &__body {
            width: 63.5%;
            padding-top: 58px;
            padding-left: 51px;
            padding-right: 54px;

            &-tablink {
                font-size: 13px;
                line-height: 160%;
                margin-right: 20px;
            }

            &-main {
                width: 55%;
                flex: none;
            }
        }

        &__close {
            top: 30px;
            right: 30px;

            .svg-icon {
                width: 11px;
                height: auto;
            }
        }

        &__info {
            padding-left: 44px;
            padding-right: 44px;
            width: 36.5%;

            .btn {
                font-size: 12px;
                line-height: 15px;  
                padding: 11px 20px;
            }

            &-order {
                margin-bottom: 44px;
            }

            &-info {
                font-size: 11px;
                line-height: 160%; 
                margin-bottom: 17px;
            }

            &-text {
                font-size: 11px;
                line-height: 160%; 
                margin-bottom: 17px;
            }

            &-title {
                font-size: 14px;
                line-height: 150%;
                margin-bottom: 11px;
            }
        }

        &__form {
            width: 36.5%;
            padding: 45px 33px 32px;

            &-close {
                top: 30px;
                right: 30px;
    
                .svg-icon {
                    width: 11px;
                    height: auto;
                }
            }

            .form-big {
                &__section {
                    margin-bottom: 16px;
                }

                &__btn {
                    margin-top: 16px;

                    .btn {
                        font-size: 12px;
                        line-height: 15px;  
                        padding: 11px 20px;
                    }
        
                }
            }
        }

        &--compound .popup-big__body-tabbody {
            height: 403px;

            img {
                max-height: 100%;
            }
        }
    }
}

@media (max-width: 960px) {
    .popup-big {
        

        &__info {
            display: none;
        }

        &__form {
            display: flex;
            left: 0;
            width: 100%;
            padding: 40px;
            align-items: center;
            border-radius: 16px;

            .form-ok {
                border-radius: 16px;

                &__wrap {
                    border-radius: 16px;
                }
            }

            &__close {
                top: 24px;
                right: 24px;
    
                .svg-icon {
                    width: 12px;
                }
            }

            .form-big {
                &__btn {
                    display: flex;
                    align-items: center;

                    .btn {
                        order: 2;
                        width: 235px;
                    }
                }

                &__agree {
                    padding-top: 0;
                    order: 1;
                    margin-right: 10px;
                }
            }
        }

        &__close {
            top: 24px;
            right: 24px;

            .svg-icon {
                width: 12px;
            }
        }

        &__text {
            display: none;
        }

        &__tech {
            margin-bottom: 24px;
        }

        &__body {
            width: 100%;
            padding-top: 49px;
            padding-left: 40px;
            padding-right: 40px;
            border-radius: 16px;

            &-main {
                width: 52%;
                padding-bottom: 40px;
            }
            
            .popup-big {
                &__info {
                    margin-top: 25px;
                    background-color: transparent;
                    padding: 0;
                    width: 100%;
                    display: block;

                    &-text {
                        margin-bottom: 20px;
                    }

                    &-order {
                        margin-bottom: 10px;
                    }

                    &-title {
                        font-size: 16px;
                        line-height: 140%;
                    }

                    &-info {
                        display: none;
                    }
                }
            }
        }

        &--compound .popup-big__body-tabbody {
            height: 518px;
        }
    }
}

@media (max-width: 640px) {
    .popup-big {
        padding: 60px 32px;

        &__info {
            &-title {
                max-width: 100%;
            }
        }

        &__close {
            right: 32px;
        }

        &__form {
            &-close {
                right: 32px;
            }
            .form-big {
                &__section {
                    margin-bottom: 20px;
                }

                &__agree {
                    margin-right: 0;
                }

                &__btn {
                    margin-top: 25px;
                    display: block;

                    .btn {
                        width: 100%;
                    }
                }
            }
        }

        &__name {
            margin-bottom: 25px;
            max-width: 73%;
        }

        &__body {
            padding: 50px 32px 0;

            &-main {
                padding-bottom: 32px;
            }

            &-main {
                width: 100%;
            }

            &-tab {
                display: none;
            }
        }
        
    }
}

@media (max-width: 480px) {
    .popup-big {
        padding: 40px 24px;

        &__close {
            top: 16px;
            right: 17px;
        }

        &__body {
            padding-top: 42px;
            padding-left: 24px;
            padding-right: 24px;
            border-radius: 12px;

            &-main {
                padding-bottom: 24px;
            }
        }

        &__name {
            max-width: 100%;
        }

        &__form {
            padding-top: 42px;
            padding-left: 24px;
            padding-right: 24px;
            padding-bottom: 24px;
            border-radius: 12px;

            &-close {
                top: 16px;
                right: 17px;
            }
        }

    }
}