* {
    box-sizing: border-box;
}
.js-popup-form-open {
    cursor: pointer;
}
body {
    font-family: Montserrat;
    color: @black;
    font-size: 14px;
    line-height: 160%;
    scroll-behavior: smooth;
    outline: 0 !important;

    &.hidden {
        overflow: hidden;
    }
}

a {
    text-decoration: none;
    transition: opacity 0.3s, color 0.3s ease;
}

input, button, a  {
    outline: 0;
}

p {
    font-size: 18px;
    line-height: 23px;
    margin: 0 0 15px;

    &:last-child {
        margin-bottom: 0;
    }
}

.svg-icon {
    use {
        transition: fill 0.3s, stroke 0.3s ease;
    }
}

svg {
    path {
        transition: fill 0.3s, stroke 0.3s ease;
    }
}

img {
    display: block;
    max-width: 100%;
}

@media(max-width: 768px) {
    p {
        font-size: 16px;
        line-height: 20px;
        margin: 0 0 13px;
    }
}


@media(max-width: 560px) {
    p {
        font-size: 15px;
        line-height: 19px;
    }
}

.slick-arrow {
    cursor: pointer;
}

.team-img {
    margin: 0 auto;
    max-width: 100%;
    border-radius: 10px;
    display: block;
}


.link {
    display: inline-flex;
    font-size: 16px;
    line-height: 19px;
    color: #008ACC;
    border-bottom: 1px solid #008ACC;
    transition:  color 0.3s, border-color 0.3s ease;

    &:hover {
        color: #2CB4F5;
        border-color: #2CB4F5;
    }
}


h1 {
    font-weight: 500;
    font-size: 50px;
    line-height: 60px;
    margin: 0;
}


h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
}

h3 {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 30px;
}

@media(max-width: 992px) {
    h1 {
        font-size: 37px;
        line-height: 1.4;  
    }

    h2 {
        font-size: 25px;
        line-height: 1.2;
    }
}

@media(max-width: 560px) {
    h1 {
        font-size: 26px;
        line-height: 1.2;
    }

    h2 {
        font-size: 23px;
        line-height: 28px;
    }
}


@media(max-width: 360px) {
    h1 {
        font-size: 23px;
    }
}

.text-block {
    font-weight: 600;
    font-size: 24px;
    line-height: 140%;
    font-family: Montserrat;
    text-align: center;
    margin-bottom: 50px;
    margin-top: -20px;

    br {
        display: none;
    }

    &--mt0 {
        margin-top: 0;
    }
    
    div {
        margin-top: 12px;
        font-weight: normal;
        font-size: 16px;
        line-height: 140%;
        font-family: Noto Sans;
    }
}

@media(max-width: 992px) {
    .text-block {
        font-size: 20px;
        line-height: 140%;

        br {
            display: none;
        }

        div {
            font-size: 14px;
            line-height: 140%;
        }
    }
}

@media(max-width: 560px) {
    .text-block {
        br {
            display: block;
        }
    }
}


.big-link-img {
    display: flex;
    margin-top: 40px;
    border-radius: 6px;
    overflow: hidden;

    img {
        max-width: 100%;
        height: auto;
        display: block;
    }
}

@media(max-width: 560px) {
    .big-link-img {
        position: relative;

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #333333;
            opacity: 0.5;
            background-position: center;
            background-repeat: no-repeat;
            background-image: url(../images/icon/more.png);
        }
    }
}

.wrapper {
    overflow: hidden;
    position: relative;
}

.slider-common {
    position: relative;
    margin-bottom: 20px;

    &:hover {
        .slick-arrow {
            opacity: 1;
        }
    }

    .slick-arrow {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        position: absolute;
        opacity: 0;
        z-index: 2;
        padding: 0;
        top: 50%;
        border: 0;
        cursor: pointer;
        outline: 0 !important;
        transform: translateY(-50%);
        background-color: #FEFEFE;
        box-shadow: 0px 6px 20px rgba(31, 31, 31, 0.1);
        transition: opacity 0.3s ease;

        &.slick-disabled {
            opacity: 0 !important;
        }

        &:hover {
            opacity: 0.8;
        }

        &.slick-next {
            right: -12px;
        }

        &.slick-prev {
            left: -12px;

            svg {
                transform: rotate(180deg);
            }
        }
    }

    .slick-dots {
        position: absolute;
        bottom: -20px;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        left: 0;
        right: 0;

        li {
            list-style: none;
            margin: 0 4px;

            &.slick-active {
                button {
                    background-color: #3E7CE1;
                }
            }

            button {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                font-size: 0;
                border: 0;
                padding: 0;
                outline: 0 !important;
                background-color: #C4C4C4;
            }
        }
    }
}

@media(max-width: 992px) {
    .slider-common {
        .slick-arrow {
            opacity: 1;
            width: 40px;
            height: 40px;

            &.slick-prev {
                left: -5px;
            }

            &.slick-next {
                right: -5px;
            }
        }



        .slick-list {
            overflow: visible;
        }
    }
}

.big-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 157%;
    margin-bottom: 32px;
    margin-top: 32px;
    padding-left: 16px;
    position: relative;
    border-left: 6px solid @orange;

    &--orange {
        color: @orange;
    }
}

@media (max-width: 960px) {
    .big-text {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 14px;
        padding-left: 10px;
        border-left: 4px solid @orange;
    }
}

.more {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    line-height: 147%;
    color: @orange;
    border-bottom: 1px solid @orange;
    transition: border-color 0.3s ease;
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .more {
        font-size: 0.8vw;
    }
}

@media (min-width: 960px) {
    .more {
        &:hover {
            border-color: transparent;
        }
    }
}

@media (max-width: 1140px) {
    .more {
        font-weight: 600;
        font-size: 10px;
        line-height: 147%;
        padding-bottom: 3px;
    }
}


.back {
    display: inline-flex;
    align-items: center;
    font-weight: 600;
    font-size: 12px;
    line-height: 147%;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #FFFFFF;

    .svg-icon {
        margin-right: 12px;

        use {
            fill: #fff;
        }
    }
}

@media(max-width: 640px) {
    .back {
        font-size: 10px;
        line-height: 147%;
    }
}

@media(min-width: 960px) {
    .back {
        &:hover {
            color: @orange;

            .svg-icon {
                use {
                    fill: @orange;
                }
            }
        }
    }
}