.popup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 105;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &.is-active {
        opacity: 1;
        pointer-events: auto;
    }


    &__close {
        cursor: pointer;
        top: 41px;
        right: 41px;
        position: absolute;
        z-index: 8;

        .svg-icon {
            width: 16px;
            height: auto;

            use {
                fill:  #fff;
            }
        }
    }

    

    &__wrap {
        width: 536px;
        position: relative;
        max-width: 100%;
        border-radius: 16px;
        overflow: hidden;

        

        .popup-big {
            &__form {
                width: 100%;
                position: relative;
                top: initial;
                right: inherit;
                left: initial;
                bottom: initial;
                padding-left: 40px;
                padding-right: 40px;
                opacity: 1;
                pointer-events: inherit;
                border-radius: 0;

                .form-big {
                    &__agree {
                        font-size: 12px;
                        line-height: 160%;
                    }

                    &__btn {
                        display: flex;
                        align-items: center;

                        .btn {
                            width: 223px;
                            margin-left: 10px;
                        }
                    }
                }
            }

            
        }
    }
}



@media(max-width: 768px) {
    .popup {
        padding-top: 60px;
        align-items: flex-start;

        &__title {
            font-size: 18px;
            line-height: 24px;
        }

        
        &__wrap {
            border-radius: 10px;

            .popup-big__form .form-big__agree {
                font-size: 10px;
                line-height: 160%;
            }
    
            .popup-big__form .form-big__btn .btn {
                width: 165px;
            }
    
        }

        &__close {
            .svg-icon {
                width: 16px;
            }
        }
    }
}

@media(max-width: 440px) {
    .popup {
        &__wrap {
            .popup-big__form .form-big__btn {
                display: block;
                align-items: center;

                .btn {
                    margin-top: 10px;
                    margin-left: 0;
                    width: 100%;
                }
            }
        }
    }
}