.system {
    display: flex;
    justify-content: space-between;

    &__body {
        width: 600px;
    }

    &__img {
        margin-bottom: -83px;
        margin-left: -161px;

        img {
            max-width: initial;
        }
    }

    &__name {
        font-weight: bold;
        font-size: 36px;
        line-height: 121%;
        margin-bottom: 24px;
    }

    &__text {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
    }

    &__item {
        margin-bottom: 75px;

        &:last-child {
            margin-bottom: 0;
        }

        &-name {
            font-weight: 600;
            font-size: 36px;
            line-height: 121%;
            letter-spacing: -0.01em;
            padding-left: 50px;
        }

        &-img {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 13px;

            .polygon {
                position: relative;
                bottom: initial;
                left: initial;
            }

            img {
                position: relative;
                z-index: 1;
                position: absolute;
                bottom: 39px;
                left: 52px;
                margin: 0;
            }
        }

        &-type {
            margin-top: 1px;
            font-weight: 500;
            font-size: 18px;
            line-height: 157%; 
            padding-left: 50px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .system {
        justify-content: flex-start;

        &__img {
            width: 40vw;
            margin-bottom: -53px;
            margin-left: -125px;

            img {
                max-width: 100%;
            }
        }

        &__item {
            margin-bottom: 51px;
            &:last-child {
                margin-bottom: 0;
            }
            &-name {
                font-size: 2.25vw;
            }

            &-type {  
                font-size: 1.1vw;
            }

            &:nth-child(1) {
                .polygon {
                    width: 15vw;
                }

                
            }

            &:nth-child(2) {
                .polygon {
                    width: 12vw;
                }

                img {
                    &:last-child {
                        bottom: 19px;
                        left: 39px;
                    }
                }
            }

            &:nth-child(3) {
                .polygon {
                    width: 10vw;
                }

                img {
                    &:last-child {
                        bottom: 19px;
                        left: 39px;
                    }
                }
            }
        }

        &__text {
            font-size: 1.1vw;
        }

        &__main {
            margin-left: 13vw;
        }

        &__body {
            width: 46%;
        }

        &__name {
            font-size: 2.25vw;
            margin-bottom: 16px;
        }
    }
}

@media (max-width: 1140px) {
    .system {
        justify-content: flex-start;

        &__body {
            width: 428px;
        }

        &__item {
            margin-bottom: 42px;

            &:last-child {
                margin-bottom: 0;
            }

            &-name {
                font-size: 32px;
                line-height: 121%;
                padding-left: 0;
            }

            &-img {
                img {
                    left: 40px;
                    bottom: 34px;
                }
            }

            &-type {
                font-size: 13px;
                line-height: 160%;
                padding-left: 0;
            }

            &:nth-child(1) {
                .polygon {
                    width: 174px;
                }

                img {
                    &:last-child {
                        width: 248px;
                        max-width: initial;
                    }
                }
            }

            &:nth-child(2) {
                .polygon {
                    width: 154px;
                }

                img {
                    &:last-child {
                        width: 239px;
                        max-width: initial;
                    }
                }
            }

            &:nth-child(3) {
                .polygon {
                     width: 145px;   
                }

                img {
                    &:last-child {
                        width: 236px;
                        max-width: initial;
                        left: 19px;
                    }
                }
            }
        }

        &__name {
            font-size: 24px;
            line-height: 121%;
            
        }

        &__text {
            font-weight: 500;
            font-size: 13px;
            line-height: 160%; 
           
        }

        &__main {
            padding-left: 73px;
        }

        &__img {
            margin-bottom: -37px;
            margin-left: -22px;
            width: 446px;

            img {
                max-width: 100%;
            }
        }
    }
}

@media (max-width: 960px) {
    .system {
        display: block;

        &__main {
            padding-left: 126px;
            padding-top: 67px;
        }

        &__img {
            position: absolute;
            left: -27px;
            margin: 0;
            width: 311px;
            top: -9px;
        }

        &__body {
            position: relative;
            width: 100%;
            padding-left: 281px;
        }

        &__item {
            margin-bottom: 42px;

            &:last-child {
                margin-bottom: 0;
            }

            &-name {
                font-size: 32px;
                line-height: 121%;
                padding-left: 0;
            }

            &-img {
                img {
                    left: 46px;
                }
            }

            &-type {
                font-size: 13px;
                line-height: 160%;
                padding-left: 0;
            }

            &:nth-child(1) {
                .polygon {
                    width: 222px;
                }

                img {
                    &:last-child {
                        width: 320px;
                    }
                }
            }

            &:nth-child(2) {
                .polygon {
                    width: 204px;
                }

                img {
                    &:last-child {
                        width: 321px;
                        bottom: 44px;
                    }
                }
            }

            &:nth-child(3) {
                .polygon {
                     width: 160px;   
                }

                img {
                    &:last-child {
                        width: 266px;
                        left: 14px;
                        bottom: 39px;
                    }
                }
            }
        }
    }
}

@media (max-width: 480px) {
    .system {
        &__body {
            padding-left: 0;
        }

        &__item {
            margin-bottom: 42px;

            &-img {
                margin-bottom: 25px;


                img {
                    left: 42px;
                    bottom: 25px;
                }
            }

            &:nth-child(1) {
                .polygon {
                    width: 206px;
                }

                img {
                    &:last-child {
                        width: 296px;
                    }
                }
            }

            &:nth-child(2) {
                .polygon {
                    width: 174px;
                }

                img {
                    &:last-child {
                        width: 283px;
                        bottom: 35px;
                        left: 32px;
                    }
                }
            }

            &:nth-child(3) {
                .polygon {
                     width: 160px;   
                }

                img {
                    &:last-child {
                        width: 266px;
                        left: 14px;
                        bottom: 39px;
                    }
                }
            }
        }

        &__img {
           position: relative;
            top: inherit;
            width: 407px;
            left: initial;
            margin-bottom: -32px;
        }

        &__main {
            padding-top: 39px;
            padding-left: 0;
        }
    }
}

@media (max-width: 640px) {
    .system {

        &__item {
            margin-bottom: 42px;

            &-img {
                margin-bottom: 25px;


                img {
                    left: 29px;
                    bottom: 37px;
                }
            }

            &:nth-child(1) {
                .polygon {
                    width: 189px;
                }

                img {
                    &:last-child {
                        width: 241px;
                    }
                }
            }

            &:nth-child(2) {
                .polygon {
                    width: 174px;
                }

                img {
                    &:last-child {
                        width: 249px;
                        bottom: 35px;
                        left: 23px;
                    }
                }
            }

            &:nth-child(3) {
                .polygon {
                     width: 156px;   
                }

                img {
                    &:last-child {
                        width: 266px;
                        left: 14px;
                        bottom: 39px;
                    }
                }
            }
        }

        &__img {
            width: 355px;
            left: initial;
            margin-bottom: -10px;
            margin-left: -32px;
            margin-top: -87px;
            position: relative;
        }

        &__body {
            padding-left: 0;
        }

        &__main {
            padding-top: 39px;
            padding-left: 0;
        }
    }
}