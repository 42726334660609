.work {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
    position: relative;


    &__item {
        display: flex;
        align-items: flex-end;
        width: calc(~"33.3% - 30px");
        background-position: center;
        background-size: cover;
        margin: 0 15px 15px;
        border-radius: 3px;
        overflow: hidden;
        height: 264px;
    }

    &__bottom {
        display: flex;
        align-items: center;
        height: 98px; 
    }

    &__name {
        font-size: 18px;
        line-height: 22px;
        color: #fff;
        padding: 15px 50px 17px 27px;
        background: linear-gradient(90deg, rgba(63, 43, 34, 0.9) 27.08%, rgba(63, 43, 34, 0) 100%);
    }
}

@media(max-width: 992px) {
    .work {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;
    
    
        &__item {
            width: calc(~"33.3% - 20px");
            margin: 0 10px 10px;
            height: 21vw;
        }

        &__name {
            font-size: 16px;
            line-height: 17px;
            padding: 15px 17px 17px 13px;
        }
    }
}

@media(max-width: 560px) {
    .work {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
        position: relative;
    
    
        &__item {
            width: calc(~"100% - 20px");
            margin: 0 10px 10px;
            height: 69vw;
        }

        &__name {
            font-size: 16px;
            line-height: 18px;
            padding: 13px 22px 13px 22px;
        }
    }
}