.form {
    label {
        position: absolute;
        top: 19px;
        right: 0;
        left: 0;
        font-size: 16px;
        line-height: 150%;
        color: #b1b8bd;
        padding: 0 20px;
        display: flex;
        align-items: center;
        opacity: .8;
        transition: font-size .3s, bottom .3s, top .3s, color .3s, opacity .3s ease;
    }
    &__agree {
        font-size: 12px;
        color: fade(#1a2134 , 80);
        line-height: 1.3;
    }

    &__btn {
        margin-top: 25px;
    }

    &__field {
        position: relative;
        margin-bottom: 25px;

        &.is-active label {
            font-size: 12px;
            line-height: 150%;
            opacity: 1;
            top: 10px;
        }
    }

    &__btn {
        .btn {
            width: 100%;
        }
    }

    input {
        height: 60px;
        width: 100%;
        padding: 0 15px;
        outline: 0;
        padding: 26px 20px 10px 20px;
        font-size: 16px;
        line-height: 1.33;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
    }

    &__phone {
        padding-top: 10px !important;
    }

    textarea {
        height: 102px;
        width: 100%;
        padding: 0 15px;
        outline: 0;
        padding: 26px 20px 10px 20px;
        font-size: 16px;
        line-height: 1.33;
        border: 1px solid #c9c9c9;
        border-radius: 5px;
    }

    .police {
        position: relative;
        z-index: 10;
        margin: 10px 0 0;
        font-size: 14px;
        /*display: flex;*/
        /*align-items: center;*/
    }
    .police label {
        position: static;
        display: block;
        color: #000;
        font-size: 14px;
        padding: 0;
    }
    .police label a {
        color: #000;
    }
    .police input {
        height: 20px;
        width: 20px;
        margin-right: 10px;
        position: relative;
        top: 5px;
    }
}
