.form-call {
    position: relative;
    z-index: 1;
    max-width: 804px;
    margin: 0 auto;

    &__input {
        background-color: #FFFFFF;
        border-radius: 28px;
        padding: 0 27px;
        font-weight: 500;
        font-size: 24px;
        line-height: 150%;
        height: 56px;
        color: @black;
        border: 0;
        width: 398px;
    }

    form {
        display: flex;
        justify-content: center;
    }

    &__form {
        position: relative;
        display: inline-flex;
        margin: 0 auto;

        .btn {
            position: absolute;
            top: 0;
            right: 0;
        }
    }

    &__title {
        text-align: center;
        font-weight: bold;
        font-size: 36px;
        line-height: 121%;
        color: #fff;
        margin-bottom: 16px;
    }

    &__text {
        font-weight: 500;
        font-size: 20px;
        line-height: 150%;
        text-align: center;
        color: #fff;
        margin-bottom: 48px;

        a {
            color: #fff;
            text-decoration: none;
            white-space: nowrap;
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .form-call {
        max-width: 55%;

        &__title {
            font-size: 2.3vw;
            margin-bottom: 14px;
        }

        &__input {
            height: 3.5vw;
            font-size: 1.6vw;
            width: 24vw;
            padding-left: 16px;

            
        }

        &__form {
            .btn {
                height: 3.5vw;
            }
        }

        &__text {
            font-size: 1.1vw;
            margin-bottom: 38px;
        }
    }
}

@media (max-width: 1140px) {
    .form-call {
        max-width: 68%;

        &__form {
            .btn {
                height: 56px;
                width: 155px;
            }
        }
    }
}

@media (max-width: 960px) {
    .form-call {
        max-width: 72%;

        &__input {
            width: 317px;
            font-size: 16px;
            line-height: 150%;
            height: 48px;
        }

        &__text {
            font-size: 16px;
            line-height: 150%;
            margin-bottom: 24px;
        }

        &__form {
            .btn {
                width: 139px;
                height: 48px;
            }
        }

        &__title {
            font-size: 24px;
            line-height: 121%;
            margin-bottom: 20px;
        }
    }
}

@media (max-width: 640px) {
    .form-call {
        max-width: 77%;
    }
}

@media (max-width: 480px) {
    .form-call {
        max-width: 100%;

        &__form {
            width: 100%;
        }

        &__input {
            width: 100%;
            padding: 0 14px;
        }
    }
}