.help {
    width: 749px;
    max-width: 100%;
    text-align: center;
    margin: 0 auto;
    position: relative;

    &__icon {
        position: absolute;
        left: -82px;
        top: 6px;
        transform: scale(1,-1) rotate(179.75deg);
    }

    h2 {
        margin: 0 auto;
        font-weight: normal;
        max-width: 90%;
    }

    &__btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &-bottom {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 40px;

            .phone {
                font-size: 22px;
                line-height: 26px;
                margin-right: 30px;
            }

            .link {
                margin-right: 30px;
            }
        }

        &-wrap {
            position: relative;
            display: inline-flex;

            .btn {
                letter-spacing: -0.01em;
            }

            img {
                position: absolute;
                right: -107px;
                top: -25px;
            }
        }
    }

    &__text {
        margin: 58px 0 87px;
        padding: 36px 0 39px;
        border-top: 1px solid #E7C89A;
        border-bottom: 1px solid #E7C89A;
        font-size: 22px;
        line-height: 120%;

        span {
            position: relative;
         
            span {
                position: relative;
                z-index: 2;
            }

            &::before {
                content: "";
                width: 467px;
                height: 40px;
                background-image: url(../images/common/grunge-ink-brush-strokes-freehand-black-brushes-vector-19444817.png);
                position: absolute;
                left: -17px;
                top: -5px;
                z-index: 1;
                opacity: 0.4;
            }
        }
    }
}

@media(max-width: 992px) {
    .help {
       
        &__text {

            padding: 22px 0 27px;
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 43px;
        }

        &__icon {
            width: 41px;
            height: 42px;
            left: -15px;
            top: 50%;
            transform: translateY(-50%) scale(1, -1) rotate(179.75deg);

            img {
                width: 100%;
            }
        }
    }
}
