.contact {
    display: flex;

    &__body {
        flex-grow: 1;
        padding-right: 80px;
    }

    &__title {
        font-weight: 500;
        font-size: 18px;
        line-height: 157%;
    }

    &__item {
        margin-bottom: 36px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__value {
        a {
            font-weight: bold;
            font-size: 21px;
            line-height: 140%;
            display: inline-flex;
            color: @orange;
            border-bottom: 3px solid fade(@orange, 30);
        }
    }

    &__phone {
        margin-bottom: 52px;

        &-title {
            font-weight: 500;
            font-size: 18px;
            line-height: 157%;
            margin-bottom: 8px;
        }

        &-phone {
            display: inline-flex;
            font-weight: bold;
            font-size: 48px;
            line-height: 110%;
            color: @black;
            margin-bottom: 16px;
        }
    }

    &__main {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        padding-top: 44px;
        width: 560px;
        flex: none;

        .social {
            margin-left: 0;

            &__item {
                width: 44px;
                height: 52px;

                .svg-icon {
                    use {
                        fill: #2E2E2E;
                    }
                }

                .svg-icon--back {
                    width: 100%;
                    height: auto;

                    use {
                        fill: transparent;
                        stroke: #B8B8B8;
                    }
                }
            }
        }
    }

    &__text {
        font-weight: bold;
        font-size: 36px;

        line-height: 132%;

        a {
            color: #2E2E2E;
            display: inline-flex;
            border-bottom: 4px solid @orange;
            transition: color 0.3s ease;
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .contact {
        &__main {
            width: 58%;
            padding-top: 0;
        }

        &__title {
            font-size: 1.1vw;
        }

        &__value {
            a {
                font-size: 1.5vw;
            }
        }

        &__text {
            font-size: 2.9vw;
        }

        &__phone {
            margin-bottom: 25px;

            &-phone {
                font-size: 2.5vw;
            }
        }
    }
}

@media (min-width: 1140px) {
    .contact {
        &__text {
            a {
                &:hover {
                    color: @orange;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .contact {
        display: flex;
        margin-top: -52px;
    
        &__body {
            flex-grow: 1;
            padding-right: 80px;
        }
    
        &__title {
            font-size: 13px;
        }
    
        &__item {
            margin-bottom: 24px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__value {
            a {
                font-size: 16px;
            }
        }
    
        &__phone {
            margin-bottom: 36px;
    
            &-title {
                font-size: 13px;
                margin-bottom: 4px;
            }
    
            &-phone {
                font-size: 30px;
            }
        }
    
        &__main {
            width: 428px;
        }
    
        &__text {
            font-size: 30px;
    
            span {
                border-bottom: 3px solid @orange;
            }
        }
    }
    
}

@media (max-width: 960px) {
    .contact {
        margin-bottom: 27px;
        margin-top: -52px;
        display: flex;
        flex-direction: column;
    
        &__block {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px -24px;
        }

        &__item {
            width: 50%;
            padding: 0 12px;
            margin-bottom: -24px;
        }
        
        &__body {
            order: 1;
            flex-grow: 1;
            padding-right: 0;

        }
    
        &__title {
            font-size: 13px;
        }
    
        &__item {
            margin-bottom: 24px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__value {
            a {
                font-size: 16px;
            }
        }
    
        &__phone {
            margin-bottom: 48px;
    
            &-phone {
                font-size: 30px;
            }
        }
    
        &__main {
            width: 100%;
            margin-bottom: 48px;

            .social {
                display: none;
            }
        }
    }
    
}

@media (max-width: 640px) {
    .contact {

        &__block {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -12px -24px;
        }

        &__item {
            width: 100%;
            padding: 0 12px;
            margin-bottom: -24px;
        }
    
        &__title {
            margin-bottom: 2px;
        }
    
        &__item {
            margin-bottom: 24px;
    
            &:last-child {
                margin-bottom: 0;
            }
        }
    
        &__value {
            a {
                font-size: 16px;
            }
        }
    
        &__phone {
            margin-bottom: 50px;
        }

        &__text {
            font-size: 21px;
            line-height: 140%;
        }
    
        &__main {
            margin-bottom: 40px;
        }
    }
    
}