.section {
    padding-bottom: 70px;
    padding-top: 70px;

    &__btn {
        display: none;
    }

    &--product {
        .production__back {
            top: 0;
            right: -100px;
            justify-content: flex-end;
        }
    }

    &__text {
        font-weight: bold;
        font-size: 18px;
        line-height: 157%;
        margin-bottom: 56px;
        max-width: 571px;
    }

    &--form {
        position: relative;
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: 10% top;
        background-image: url(../images/back/form-back.png);

        .container {
            transition: opacty 0.3s ease;

            &.is-hidden {
                opacity: 0;
                pointer-events: none;
            }
        }
    }

    &--white {
        background-color: black;
        
        .section {
            &__top {
                h2 {
                    color: #fff;
                }
            }
        }
    }

    &--100 {
        padding-top: 100px;
        padding-bottom: 100px;
    }

    &--middle {
        padding-top: 123px;
        padding-bottom: 123px;
    }

    &--default {
        padding-top: 136px;
        padding-bottom: 136px;
    }

    &--big {
        padding-top: 160px;
        padding-bottom: 160px;
    }

    &--ad {
       background-color: #000;
        background-position: center;
        background-size: cover;
    }

    &--package {
        .section {
            &__top {
                h2 {
                    text-align: center;
                    max-width: 50%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &--orange {
        background-color: @orange;
    }

    &--grey {
        background-color: @grey-1;
    }

    &--phone {
        background-position: center;
        background-size: cover;
        padding-top: 145px;
        padding-bottom: 137px;
        position: relative;
        background-image: url(../images/back/phone-back.jpg);

        &::before {
            content:" ";
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background: @black;
            opacity: 0.5;   
        }
    }

    &--plant-phone {
        background-image: url(../images/back/phone-back-plant.jpg);

        &::before {
            display: none;
        }
    }

    &--contact {
        padding-top: 120px;
        padding-bottom: 120px;
        background-image: url(../images/common/logo-contact.svg);
        background-repeat: no-repeat;
        background-size: auto 100%;
    }

    &--pt0 {
        padding-top: 0 !important;
    }

    &--pb0 {
        padding-bottom: 0 !important;
    }

    &__top {
        margin-bottom: 50px;

        h2 {
            margin-bottom: 0;
        }
    }

    &--production {
        padding-top: 125px;
        padding-bottom: 235px;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
    }

    &--production-plant {
        padding-top: 125px;
        padding-bottom: 335px;
    }

    &--production-compound {
        padding-top: 144px;
        padding-bottom: 249px;
        position: relative;

        .production__back {
            top: 257px;
            right: 0;
            justify-content: flex-end;
        }
    }

    

    &--advantage {
        padding-top: 100px;
        padding-bottom: 98px;
    }

   
    &--speed {
        background-image: url(../images/back/speed.jpg);
        background-position: center;
        background-size: cover;
        padding-bottom: 107px;
        padding-top: 86px;
    }

    &--mission {
        padding-top: 90px;
        padding-bottom: 105px;
    }

    &--main {
        padding-top: 100px;
        padding-bottom: 252px;
        position: relative;

        .section {
            &__img {
                position: absolute;

                img {
                    &:nth-child(2) {
                        position: absolute;
                        top: 422px;
                        left: -201px;
                    }
                }
            }

            &__top {
                max-width: 571px;
                margin-bottom: 20px;
            }
        }
    }

    &--main-banner {
        padding-top: 248px;
        padding-bottom: 230px;
    }

    &--main--flora {
        margin-bottom: 0;
        padding-bottom: 126px;
        
        .order{
            &__text {
                max-width: 340px;
            }
        }

        .section {
            &__text {
                margin-bottom: 48px;
            }
        }
    }

    &--main--grid {
        padding-bottom: 114px;
    }
}

@media (min-width: 1921px) {
    .section {



        &--main-banner {
            .play-icon {
                left: 56%;
                bottom: -152px;
            }
        }
        
        
        &--main {
            .section {
                &__img {
                    right: -125px;
                    top: 81px;

                    img {
                        &:nth-child(2) {
                           
                        }
                    }
                }
            }
        }

        &--main--flora {
            .section {
                &__img {
                    top: 121px;
                }
            }
        }

        &--main--grid {
            .section {
                &__img {
                    top: -11px;
                }
            }
        }
    }
}

@media (max-width: 1920px) and (min-width: 1601px) {
    .section {
        &--main-banner {
            padding-top: 194px;
        }

        &--grid1 {
            margin-bottom: 186px;
        }

        &--main {
            padding-bottom: 227px;

            .section {
                &__img {
                    left: 720px;
                    top: 63px;

                    img {
                        max-width: initial;

                        &:nth-child(2) {
                            top: 422px;
                            left: -144px;
                            width: 269px;
                        }
                    }

                }
            }
        }

        &--main--flora {
            .section {
               &__img {
                    top: 121px;
               }
            }
        }

        &--main--grid {
            padding-bottom: 95px;

            .section {
                &__img {
                    left: 723px;
                    top: -13px;
                    
                    img {
                        &:last-child {
                            position: relative;
                            top: initial;
                            right: initial;
                        }
                    }
                }
            }
        }

        &--main--grid1 {
            .section__img {
                transform: translateY(190px);
            }
        }

        &--main--grid {
            .section__img {
                transform: translateY(336px);
            }
        }

        &--main--flora {
            .section__img {
                transform: translateY(500px);
            }
        }

        &--main--inner {
            .section__img {
               

                img {
                    transition: transform 0.3s linear;
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .section {
        &--main {

        }

        &--speed {
            padding-top: 61px;
            padding-bottom: 78px;
        }

        &--main-banner {
            padding-top: 138px;
            padding-bottom: 140px;
        }
        

        &--package {
            .section {
                &__top {
                    h2 {
                        font-size: 3vw;
                        max-width: 100%;
                    }
                }
            }
        }

        &--production-plant {
            padding-top: 125px;
            padding-bottom: 261px;
        }

        &--big {
            padding-top: 50px;
            padding-bottom: 76px;
        }

        &--ad {
            padding-top: 85px;
            padding-bottom: 85px;
        }

        &--middle {
            padding-top: 75px;
            padding-bottom: 69px;
        }

        &--phone {
            padding-top: 85px;
            padding-bottom: 79px;
        }

        &__top {
            margin-bottom: 31px;
        }

        &--form {
            padding-top: 49px;
            padding-bottom: 49px;
        }

        &--mission {
            padding-top: 65px;
            padding-bottom: 78px;
        }

        &--production-compound {
            .production__back {
                width: 36vw;
                margin: 0;
                left: initial;
                top: 226px;
                right: -80px;
            }
        }

        &--main--inner {
            padding-bottom: 126px;

            .section__top  {
                max-width: 70%;
            }

            .section {
                &__text {
                    font-size: 1.1vw;
                    max-width: 72%;
                    margin-top: -17px;
                }

                &__img {
                    left: 92%;
                    top: 17vw;

                    img {
                        max-width: initial;

                        &:first-child {
                            width: 26vw;
                        }

                        &:nth-child(2) {
                            width: 17vw;
                            top: 26vw;
                            left: -8vw;
                        }
                    }
                }
            }
        }

        
        &--main--grid1 {
            .section__img {
                transform: translateY(-1vw);
            }
        }

        &--main--grid {
            .section__img {
                transform: translateY(10vw);
            }
        }

        &--main--flora {
            .section__img {
                transform: translateY(23vw);
            }
        }

        &--main--inner {
            .section__img {
               

                img {
                    transition: transform 0.3s linear;
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .section {
        padding-top: 55px;
        padding-bottom: 55px;

        &--sphere {
            padding-top: 137px;
        }

      

        &--package {
            .section {
                &__top {
                    h2 {
                        font-size: 36px;
                        line-height: 110%;
                        max-width: 100%;
                    }
                }
            }
        }

        &--speed {
            padding-top: 57px;
            padding-bottom: 63px;
        }

        &--grey {
            padding-top: 87px;
            padding-bottom: 106px;
        }

        &--main {
            h2 {
                font-size: 30px;
                line-height: 110%;
            }
        }

        &--mission {
            padding-top: 54px;
            padding-bottom: 87px;
        }

        &--big {
            padding-top: 70px;
            padding-bottom: 80px;
        }

        &--contact {
            padding-top: 85px;
        }

        &--ad {
            padding-bottom: 87px;
            padding-top: 69px;

            .section {
                &__top {
                    margin-bottom: 34px;

                    h2 {
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 110%;
                    }
                }
            }

            h2 {
                text-align: center;
            }
        }

        &--phone {
            padding-top: 108px;
            padding-bottom: 100px;
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
        }

        &__top {
            margin-bottom: 26px;
        }

        &--production-compound {
            padding-top: 100px;
            padding-bottom: 50px;
            .production__back {
                top: 4px;
                right: 136px;
            }
        }

        &--main-banner {
            padding-top: 137px;
            padding-bottom: 69px;
        }

        &--main {
            padding-top: 80px;
            padding-bottom: 252px;
        }

        &--main--inner {
            padding-top: 80px;
            padding-bottom: 0;

            .section__top  {
                margin-bottom: 33px;
                max-width: 80%;
            }

            .section {
                &__text {
                    font-size: 13px;
                    line-height: 160%;
                }

                &__img {
                    left: 92%;
                    top: 145px;

                    img {
                        max-width: initial;

                        &:first-child {
                            width: 222px;
                        }

                        &:nth-child(2) {
                            width: 116px;
                            top: 437px;
                            left: 7%;
                        }
                    }
                }
            }
        }

        &--main--grid {
            padding-bottom: 95px;

            .section {
                &__img {
                    right: -212px;
                    top: -13px;
                    
                    img {
                        &:last-child {
                            position: relative;
                            top: initial;
                            right: initial;
                        }
                    }
                }
            }
        }

        &--main--grid1 {
            .section__img {
                transform: translateY(0px);
            }
        }

        &--main--grid {
            .section__img {
                transform: translateY(245px);
            }
        }

        &--main--flora {
            padding-bottom: 80px;
            .section__img {
                transform: translateY(300px);
            }
        }

        &--main--inner {
            .section__img {
               

                img {
                    transition: transform 0.3s linear;
                }
            }
        }
    }
}

@media (min-width: 1921px) {
    .section {
        &--main--grid1 {
            .section__img {
                transform: translateY(190px);
            }
        }

        &--main--grid {
            .section__img {
                transform: translateY(445px);
            }
        }

        &--main--flora {
            .section__img {
                transform: translateY(500px);
            }
        }

        &--main--inner {
            .section__img {
               

                img {
                    transition: transform 0.3s linear;
                }
            }
        }
    }
}


@media (max-width: 960px) {
    .section {
        &--orange {
            padding-top: 40px;
            padding-bottom: 40px;
            border-radius: 24px 24px 0px 0px;
            overflow: hidden;
            background-image: none;
        }
        
        

        &--package {
            .section {
                &__top {
                    h2 {
                        font-size: 24px;
                        line-height: 121%;
                        max-width: 100%;
                    }
                }
            }
        }

        &--main-banner {
            padding-top: 0 !important;
            padding-bottom: 0 !important;
            margin-left: -41px;
            margin-right: -41px;
        }

        &--big {
            padding-top: 30px;
            padding-bottom: 70px;
        }

        &--main--grid {
            padding-bottom: 43px;
        }

        &--main--inner {
            .section__img {
                transform: translateY(0) !important;

                img {
                    transform: translateY(0) !important;

                    &:first-child {
                        width: 211px;
                        transform: translateY(0) !important;
                    }

                    &:nth-child(2) {
                        display: none;
                        transform: translateY(0) !important;
                    }
                }
            }
        }

        &--main {
            padding-top: 59px;

            .section {
                &__top {
                    max-width: 324px;
                    margin-bottom: 18px;
                }

                &__img {
                    width: auto;
                    top: 30px;
                    left: initial;
                    right: 0;

                    
                    img {
                        width: 100%;
                        
                    }
                }
            }
        }

        &--production-compound {
            padding-top: 73px;
        }

        &--mission {
            border-radius: 0;
            padding-top: 42px;
            padding-bottom: 52px;
        }

        &--sphere {
            padding-top: 60px;
        }

        &__top {
            margin-bottom: 32px;
        }

        &--phone {
            padding-top: 90px;
            padding-bottom: 90px;
        }

        &--production-compound {
            .production__back {
                top: 8px;
                right: 146px;
            }
        }

        &--grey {
            padding-top: 60px;
            padding-bottom: 106px;
        }

        &__btn {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 30px;
        }
    }
}

@media (max-width: 640px) {
    .section {
        &--orange {

        }

        &--main-banner {
            padding-top: 0;
            padding-bottom: 0;
            margin-left: -32px;
            margin-right: -32px;
        }

        &--grey {
            padding-bottom: 51px;
        }

        &--main {
            padding-top: 34px;
            padding-bottom: 30px;

            .section {
                &__img {
                    margin-bottom: 18px;
                    width: 196px;
                    position: relative;
                    top: initial;
                }

                &__top {
                    max-width: 100%;
                    margin-bottom: 30px;
                }

            }
        }

        &--speed {
            padding-top: 87px;
            padding-bottom: 63px;
        }

        &--mission {
            padding-top: 40px;
            padding-bottom: 40px;
            border-radius: 0px;
        }
    }
}

@media (max-width: 480px) {
    .section {
        &--main-banner {
            margin-left: -24px;
            margin-right: -24px;
        }
    }
}

