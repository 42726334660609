.slider-nav {
    width: 100%;
    margin-top: 16px;
    overflow: hidden;     margin-bottom: 40px;


    .slick-slide {
        display: flex;
        justify-content: flex-start;
        padding: 0 8px;
        opacity: 0.6;
        transition: opacity 0.3s ease;

        &.slick-current {
            opacity: 1; 
        }
    }



    .slick-track {
        margin: 0;
    }
    
    .slick-list {
        margin: 0 -8px;
    }

    &__item {
        display: flex;
        width: 100%;
        border-radius: 6px;
        height: 168px;
        background-position: center;
        background-size: cover;
    }
}


@media (min-width: 1141px) and (max-width: 1600px) {
    .slider-nav {
        width: 100%;
        height: 10vw;

        &__item {
            height: 10vw;
        }
    }
}



@media(max-width: 768px) {
    .slider-nav {
        display: none;

        &__item {
            width: 88px;
        }
    }
}



@media(max-width: 560px) {
    .slider-nav { 
        display: none;
    }
}