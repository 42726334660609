.page {
    display: flex;

    .video {
        &__item {
            margin-bottom: 20px;
        }
        &__icon {
            .svg-icon:first-child {
            
                use {
                    fill: rgba(46, 46, 46, 0.6);
                }
            }
        }
        &__image {
            height: 551px;
        }
    }
    img {
        width: 100%;
        margin-bottom: 20px;
    }

    &__aside {
        padding-left: 61px;
    }
    &__body {
        flex: none;
        width: 72.5%;

        p {
            margin-bottom: 20px;
        }

        h2 {
            margin-top: 60px;
            margin-bottom: 32px;
        }

        h3 {
            margin-top: 60px;
            margin-bottom: 32px;
        }
    }

    ul {
        padding: 0;
        margin: 32px 0 32px;

        li {
            font-weight: 500;
            font-size: 18px;
            line-height: 157%;
            position: relative;
            list-style: none;
            padding-left: 28px;
            margin-bottom: 20px;
            position: relative;

            &::before {
                content: " ";
                position: absolute;
                left: 0;
                top: 6px;
                width: 14px;
                height: 15px;
                background-image: url(../images/common/list-item.svg);
            }
        }
    }

    ol {
        padding: 0;
        margin: 32px 0 32px;
        counter-reset: myCounter;

        li {
            font-weight: 500;
            font-size: 18px;
            line-height: 157%;
            position: relative;
            list-style: none;
            padding-left: 28px;
            margin-bottom: 20px;
            position: relative;

            &::before {
                counter-increment: myCounter;
                content: counter(myCounter);
                position: absolute;
                left: 0;
                top: 0;
                font-weight: 700;
                font-size: 18px;
                line-height: 157%;
                width: 14px;
                height: 15px;
                color: @orange;
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .page {
        .video__image {
            height: 30vw;
        }

        &__body {
            width: 100%;
        }

        &__aside {
            padding-left: 50px;
        }

        p {
            font-size: 1.1vw;
            line-height: 1.5;
        }

        ul {
            li {
                font-size: 1.1vw;
                line-height: 1.5;
                
                &::before {
                    top: 1px;
                }
            }
        }
        ol {
            li {
                font-size: 1.1vw;
                line-height: 1.5;

                &::before {
                    font-size: 1.1vw;
                    top: -1px;
                }
            }
        }

        .big-text {
            font-size: 1.3vw;
        }
    }
}

@media(max-width: 1140px) {
    .page {
        .video__image {
            height: 60vw;
        }

        &__body {
            width: 100%;
        }

        &__aise {
            display: none;
        }
    }
}