.dropmenu {
    width: 200px;
    position: absolute;
    padding-top: 29px;
    left: -30px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &__control {
        .svg-icon {
            use {
                stroke: @grey-3;
                fill: transparent;
            }
        }
    }
    
    ul {
        margin: 0;
        padding: 0;

        li {
            list-style: none;

            &:last-child {
                a {
                    border-bottom: 0;
                }
            }

            a {
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-weight: 600;
                font-size: 13px;
                line-height: 150%;
                padding: 16px 20px;
                font-weight: 600;
                font-size: 13px;
                line-height: 150%;
                text-transform: uppercase;
                color: #FFFFFF;
                background-color: @black;
                border-bottom: 1px solid #fff;
                transition: background-color 0.3s ease;
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px){
    .dropmenu {
        width: 13vw;
        padding-top: 1.5vw;

        ul {
            li {
                a {
                    padding: 12px 15px;
                    font-size: 0.8vw; 
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .dropmenu {
        width: 188px;
        padding-top: 18px;
        left: -16px;

        ul {
            li {
                a {
                    padding: 13px 16px;
                    font-size: 12px;   
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .dropmenu {
        ul {
            li {
                a {
                    &:hover {
                        background-color: @orange;

                        .dropmenu {
                            &__control {
                                .svg-icon {
                                    use {
                                        stroke: #fff;
                                        fill: transparent;
                                    }
                                }
                            }
                        }
                    }
                   
                }
            }
        }
    }
}