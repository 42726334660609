.text-info {

    &__back {
        position: absolute;
        right: 0;
        top: 0;
    }

    &__back-bottom {
        position: absolute;
        right: 0;
        bottom: 50px;
    }

    &__schema {
        left: 785px;
        top: 25px;
        position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 650px;

        .sphere {
            &__item {
                padding: 0 31px 53px;
                width: 221px;
                height: 247px;
                background-position: center;
                background-size: cover;

                margin-left: 0;
                margin-right: 40px;

                &::before {
                    background-position: center;
                    background-size: cover;  
                }

                &:nth-child(1) {
                    margin-left: 0;
                    margin-right: 40px;
                }

                &:nth-child(3) {
                    margin-left: 129px;
                }

                &:nth-child(4) {
                    margin-right: 0;
                }

                &:nth-child(5) {
                    margin-right: 0;
                    margin-left: 260px;
                }

                &:nth-child(2) {
                    margin-left: 0;
                    margin-right: 0;
                }
            }
        }
    }

    &__img {
        left: 785px;
        top: 25px;
        position: absolute;

        img {
            max-width: initial;
            
            &:last-child {
                position: absolute;
                left: 201px;
                bottom: -241px;
            }
        }
    }

    &__section {
        position: relative;
    }

    &__name {
        font-weight: 700;
        font-size: 36px;
        line-height: 136%;
        color: #2E2E2E;
        margin-bottom: 32px;
    }

    &__body {
        width: 725px;

        p {
            font-size: 18px;
            line-height: 157%;
            margin: 0 0 8px;
        }
    }

    &__item {
        position: relative;
        margin-bottom: 117px;

        &:last-child {
            margin-bottom: 0;
            padding-bottom: 105px;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .text-info {
        &__body {
            width: 47vw;
            padding-right: 25px;
            p {
                font-size: 1.4vw;
            }
        }

        &__schema {
            left: 62%;
            width: 620px;

            .sphere {
                &__item {
                    padding: 0 19px 48px;
                    width: 170px;
                    height: 192px;
                    margin-right: 20px;

                    &:nth-child(1) {
                        margin-left: 0;
                        margin-right: 20px;
                    }
    
                    &:nth-child(3) {
                        margin-left: 94px;
                    }
    
                    &:nth-child(4) {
                        margin-right: 0;
                    }
    
                    &:nth-child(5) {
                        margin-right: 0;
                        margin-left: 193px;
                    }
    
                    &:nth-child(2) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }

        &__name {
            font-size: 2.6vw;
        }

        &__img {
            left: 62%;
        }
    }
}

@media(max-width: 1141px) {
    .text-info {
        &__body {
            width: 425px;
            padding-right: 25px;

            p {
                font-size: 13px;
                line-height: 160%;
            }
        }

        &__schema {
            left: 430px;
            width: 620px;

            .sphere {
                &__item {
                    position: relative !important;
                    top: initial !important;
                    left: initial !important;
                    right: initial !important;
                    bottom: initial !important;
                    padding: 0 19px 48px;
                    width: 170px;
                    height: 192px;
                    margin-right: 20px;

                    &:nth-child(1) {
                        margin-left: 0;
                        margin-right: 20px;
                    }
    
                    &:nth-child(3) {
                        margin-left: 94px;
                    }
    
                    &:nth-child(4) {
                        margin-right: 0;
                    }
    
                    &:nth-child(5) {
                        margin-right: 0;
                        margin-left: 193px;
                    }
    
                    &:nth-child(2) {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }

        &__img {
            img {
                &:first-child {
                    width: 370px;
                }
            }
        }

        &__name {
            font-size: 30px;
            line-height: 110%;
        }

        &__img {
            left: 430px;
        }
    }
}

@media(max-width: 960px) {
    .text-info {

        &__back {
            display: none;
        }

        &__back-bottom {
            display: none;
        }

        &__name {
            font-size: 24px;
            line-height: 121%;
        }

        &__schema {
            left: 388px;
            width: 430px;
            display: none;

            .sphere {
                &__item {
                    width: 121px !important;
                    height: 140px;
                }

                &__img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        &__body {
            width: 100%;
            padding-right: 0;
        }

        &__item {
            &:last-child {
                padding-bottom: 0;
            }
            
            &--img {
                display: flex;
                flex-direction: column;

                .text-info {
                    &__body {
                        order: 2;
                        width: 100%;
                        padding-right: 0;
                    }

                    &__img {
                        width: 230px;
                        order: 1;
                        position: relative;
                        top: initial;
                        left: initial;
                        margin-bottom: 10px;

                        img {
                            width: 100% !important;

                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}