.big-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: relative;

    &__arrow {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        width: 49px;
        height: 56px;
        position: absolute;
        bottom: 59px;
        right: -24px;
        z-index: 5;
        overflow: hidden;

        svg {
            &:first-child {
                position: absolute;
                top: 0;
                left: 0;
            }

            &:last-child {
                position: relative;
                margin-top: -5px;
            }
        }
    }

    &__img {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        background-position: center;
        background-size: cover;
        opacity: 0;
        transition: opacity 0.5s ease;

        &.is-active {
            opacity: 1;
        }
    }

    &__name {
        max-width: 277px;
        font-weight: 500;
        font-size: 18px;
        line-height: 140%;
        color: #fff;

        span {
            display: block;
            max-width: 75%;
        }
    }

    &__block {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: relative;
        padding-left: 22px;
    }

    &__link {
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        z-index: 1;
        margin-bottom: 40px;

        &:last-child {
            margin-bottom: 0;
        }

        &.is-active {
            .big-menu {
                &__icon {
                    .svg-icon {
                        &--polygon {
                            use {
                                stroke-width: 2;
                            }
                        }
                    }
                }
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 86px;
        height: 98px;
        margin-right: 33px;

        .svg-icon--polygon {
            position: absolute;
            left: 0;
            top: 0;

            use {
                stroke: #FFFFFF;
                transition: stroke-width 0.3s ease;
                fill: transparent;
            }
        }
    }
}

@media(min-width: 960px) {
    .big-menu {
        &__link {
            &:hover {
                .big-menu {
                    &__icon {
                        .svg-icon {
                            &--polygon {
                                use {
                                    stroke-width: 2;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .big-menu {
        &__name {
            width: 17vw;
            line-height: 1.3;

            span {
                font-size: 1.1vw;
            }
        }

        &__link {
            margin-bottom: 28px;
        }

        &__icon {
            width: 5.5vw;
            height: 6.4vw;
            margin-right: 24px;

            img {
                max-width: 80%;
            }

            svg {
                width: 100%;
                height: auto;
            }
        }
    }
}

@media(max-width: 1140px) {
    .big-menu {
    
        &__name {
            width: 169px;
            font-size: 15px;
            line-height: 140%;
    
            span {
                display: block;
                max-width: 75%;
            }
        }
    
    
        &__icon {
            width: 68px;
            height: 78px;
            margin-right: 17px;
    
            .svg-icon--polygon {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}