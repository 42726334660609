.mobile-catalog {
    display: none;
}

@media(max-width: 960px) {
    .mobile-catalog {
        position: fixed;
        left: 0;
        right: 0;
        top: 56px;
        z-index: 12;
        display: flex;
        background: rgba(46, 46, 46, 0.94);
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.3s ease;

        &__close {
            display: none;
        }

        &.is-active {
            opacity: 1;
            pointer-events: auto;
        }

        &__icon {
            position: relative;
            margin: 0 auto;
            width: 80px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 91px;

            svg {
                position: absolute;
                left: 0;
                top: 0;
            }

            img {
                position: relative;
                z-index: 2;
            }
        }

        &__body {
            padding: 63px 40px 69px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &__item {
            width: 172px;
        }

        &__name {
            margin-top: 7px;
            text-align: center;
            color: #fff;
            font-weight: 600;
            font-size: 15px;
            line-height: 140%;
        }
    }
}

@media(max-width: 640px) {
    .mobile-catalog {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 40px;

        &__body {
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 53px;
           padding-bottom: 0;
        }

        &__close {
            display: inline-flex;

            .svg-icon {
                fill: @grey-2;
            }
        }

        &__item {
            width: 232px;
            margin-bottom: 42px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

@media(max-width: 480px) {
    .mobile-catalog {
        top: 48px;
    }
}