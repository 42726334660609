.logo {
    display: inline-flex;
    align-items: center;

    img {
        max-width: 218px;
    }
}

@media(max-width: 1140px) {
    .logo {
        img {
            width: 141px;
            height: auto;
        }
    }
}