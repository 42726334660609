.med-logo {
    display: inline-flex;
    align-items: center;
    color: initial;
    text-decoration: none;

    img {
        flex: none;
        margin-right: 17px;
    }

    &__text {
        font-size: 16px;
        line-height: 19px;
        width: 375px;
    }
}


@media(max-width: 1200px) {
    .med-logo {
        img {
            width: 50px;
            height: auto;
            margin-right: 15px;   
            flex: none;
        }

        &__text {
            font-size: 15px;
            line-height: 19px;
            width: 265px;
        }
    }
}

@media(max-width: 992px) {
    .med-logo {
        img {
            width: 45px;
            height: auto;
            margin-right: 10px;   
            flex: none;
        }

        &__text {
            font-size: 14px;
            line-height: 16px;
            width: 163px;
        }
    }
}

@media(max-width: 768px) {
    .med-logo {
        margin-bottom: 19px;
        
        img {
            width: 52px;
            height: auto;
            margin-right: 20px;   
            flex: none;
        }

        &__text {
            font-size: 14px;
            line-height: 16px;
            width: 187px;
        }
    }
}