.btn {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: none;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    font-family: Montserrat;
    text-decoration: none;
    border-radius: 28px;
    text-transform: uppercase;
    border: 1px solid transparent;
    padding: 18px 32px;
    transition: background-color 0.3s, color 0.3s, border-color 0.3s, box-shadow 0.3s ease;

    &--out-white {
        border-color: #fff;
        color: #fff;
        background-color: transparent;
    }

    &--out-orange {
        border-color: @orange;
        color:  @orange;
    }

    &--orange {
        background-color: @orange;
        border-color: @orange;
        color: #fff;

        .svg-icon {
            use {
                fill: #fff;
            }
        }
    }

    &--left {
        .svg-icon {
            margin-right: 6px;
        }
    }

    &--mini {
        font-weight: bold;
        font-size: 18px;
        line-height: 157%;
        padding: 10px 20px;
        text-transform: initial;
    }

    &--middle {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0.05em;
        padding: 16px 24px;
    }
    
    &--small {
        padding: 12px 18px;

        letter-spacing: 0.05em;
        font-size: 11px;
        line-height: 13px;
    }
}


.btn-download {
    display: inline-flex;
    align-items: center;
    color: @orange;
    padding: 16px 27px 15px;
    border-radius: 28px;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s, border-color ease;

    .svg-icon {
        margin-right: 11px;

        use {
            fill: @orange;
        }
    }
}

@media (min-width: 1141px) and (max-width: 1600px) {
    .btn {
        padding: 7px 26px;
        font-size: 0.9vw;
        padding: 11px 20px;
    }

    .btn-download { 
        font-size: 0.9vw;
        padding: 11px 7px;

        svg {
            margin-right: 5px;
            width: 1vw;
            height: auto;
        }
    }
}



@media(min-width: 960px) {
    .btn-download {
        &:hover {
            color: #fff;
            background-color: @orange;

            .svg-icon {
                use {
                    fill: #fff;
                }
            }
        }
    }

    .btn {
        &--orange {
            &:hover {
                box-shadow: 0px 32px 25px rgba(255, 100, 17, 0.1);
            }
        }

        &--out-orange {
            &:hover {
                background-color: @orange;
                color:  #fff;
            }
            
        }

        &--out-white {
            &:hover {
                border-color: @orange;
                color: #fff;
                background-color: @orange;
            }
         
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .btn {
        &--small {
            font-size: 0.65vw;
            padding: 6px 16px;
        }
    }
}

@media (max-width: 1140px) {
    .btn {
        font-size: 14px;
        line-height: 17px;  
        padding: 15px 30px;

        &--small {
            font-size: 12px;
            line-height: 15px; 
            padding: 10px 20px;
        }
    }
}

@media (max-width: 640px) {
    .btn {
        &--small {
            font-size: 10px;
            line-height: 12px;
            padding: 7px 14px;
        }
    }
}