.mission {
    margin-left: 83px;
    color: #fff;
    max-width: 718px;

    &__title {
        font-weight: 500;
        font-size: 21px;
        line-height: 140%;
        margin-bottom: 24px;
    }

    &__text {
        font-weight: 600;
        font-size: 30px;
        line-height: 134%;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .mission {
        margin-left: 56px;
        max-width: 57%;

        &__title {
            font-size: 1.3vw;
            margin-bottom: 19px;
        }

        &__text {
            line-height: 130%;
            font-size: 1.9vw;
        }
    }
}

@media(max-width: 1140px) {
    .mission {
        margin-left: 75px;
        max-width: 510px;

        &__title {
            font-size: 15px;
            line-height: 140%;
            margin-bottom: 16px;
        }

        &__text {
            font-size: 16px;
            line-height: 140%;
        }
    }
}

@media(max-width: 960px) {
    .mission {
        margin-left: 0;

        &__text {
            max-width: 427px;
        }
    }
}