.banner {
    padding-top: 88px;
    background-color: @grey-1;
    background-image: url(../images/common/Intersect.svg);
    color: #000000;
    background-position: center;
    background-size: cover;
    position: relative;

    &__back {
        position: absolute;
        z-index: 1;
        display: inline-flex;
        bottom: -432px;
        right: 78px;
    }

    &--plant {
        background-image: url(../images/back/plant-back.svg);
        background-size: auto 100%;
        background-repeat: no-repeat;
        background-position: top right;
    }

    &__icon {
        width: 282px;
        height: 318px;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-weight: 700;
        font-size: 21px;
        line-height: 140%;
        color: #fff;
        text-decoration: underline;
        padding-left: 44px;
        padding-right: 44px;
        padding-bottom: 86px;
        text-align: center;
        transition: opacity 0.3s ease;

        &--big {
            width: 320px;
            height: 368px;
            background-position: center;
            background-size: cover;
        }

        &:nth-child(2) {
            margin-left: 321px;
            margin-top: -102px;
        }

        &:nth-child(3) {
            margin-top: -259px;
        }

        &:nth-child(1) {
            margin-left: 81px;
        }
    }

    h1 {
        margin-bottom: 24px;
    }

    &__body {
        width: 400px;
        flex: none;
        padding-bottom: 128px;
    }

    &__text {
        font-weight: bold;
        font-size: 18px;
        line-height: 157%;  
        margin-bottom: 32px;
    }
    
    &__info {
        color: @grey-3;
        padding-right: 25px;

        a {
            color: @orange;
        }

        span {
            font-weight: bold;
            font-size: 36px;
            line-height: 121%;
            display: block;
            margin-bottom: 4px;
            
        }
    }

    &__btn {
        display: flex;
        align-items: center;
        margin-bottom: 48px;

        .btn {
            margin-right: 16px;
        }
    }

    &__wrap {
        display: flex;
        align-items: flex-end;
    }

    &__img {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;

        &-img {
            margin-bottom: 41px;
        }

        &-text {
            display: inline-flex;
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
            padding-bottom: 2px;
            border-bottom: 2px solid transparent;
            margin-bottom: 43px;
        }

        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 25px;
            cursor: pointer;

            &--red {
                .banner__img-text {
                    color: #D53423;
                    border-color: #D53423;
                }
            }

            &--blue {
                .banner__img-text {
                    color: #007CB1;
                    border-color: #007CB1;
                }
            }

            &--yellow {
                .banner__img-text {
                    color: #AFA45F;
                    border-color: #AFA45F;
                }
            }

            &--orange {
                .banner__img-text {
                    color:  #FF6600;
                    border-color:  #FF6600;
                }
            }

            &--green {
                .banner__img-text {
                    color: #008562;
                    border-color: #008562;
                }
            }
        }
    }

    &--compound {
        background-position: top right;
    }

    &--reinforcement {
        .banner {
            &__wrap {
                justify-content: space-between;
            }
            
            &__body {
                width: 550px;
            }

            &__img {
                justify-content: flex-end;

                &-text {
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 120%;
                }

                &-img {
                    margin-bottom: 9px;
                }

                &-main {
                    img {
                        &:last-child {
                            display: none;
                        }
                    }
                }

                &-item {
                    width: auto;
                    padding: 0;
                    margin: 0 31px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    &--plant {
        padding-top: 152px;

        .banner {
            &__wrap {
                align-items: center;
            }

            &__img {
                position: relative;
                z-index: 2;
                flex-direction: column;
                align-items: flex-start;
                margin-top: -168px;
            }

            &__body {
                width: 560px;
                padding-bottom: 144px;
            }
        }
    }

    &--compound {
        h1 {
            max-width: 400px;
        }

        .banner {
           

            &__body {
                width: 480px;
            }
        }
    }
    
    &--innerc {
        padding-top: 141px;

        .banner {
            &__wrap {
                align-items: flex-start;
            }

            &__body {
                width: 511px;
            }

            &__img {
                margin-left: 70px;
                position: relative;
                height: 100%;
                margin-top: -81px;

                img {
                    &:last-child {
                        position: absolute;
                        bottom: -247px;
                        right: -56px;
                    }
                }
            }
        }
    }

}


@media(min-width: 1141px) and (max-width: 1600px){
    .banner {
        h1 {
            margin-bottom: 19px;
        }

        &__info {
            font-size: 0.9vw;
            max-width: 72%;
            line-height: 1.5;

            span {
                font-size: 2.24vw;
            }
        }

        &__name {

        }

        &__icon {
            width: 17vw;
            height: 19vw;
            background-size: cover;
            padding-bottom: 72px;

            &:nth-child(1) {
                margin-left: 6vw;
                margin-top: 5vw;
            }

            &:nth-child(2) {
                margin-left: 21vw;
                margin-top: -5vw;
            }

            &:nth-child(3) {
                margin-top: -17vw;
            }

            &-name {
                font-size: 1.3vw;
                line-height: 1.4;
            }


            &--big {
                width: 20vw;
                height: 23vw;
            }
        }

        &__btn {
            margin-bottom: 29px;

            .btn {
                padding: 11px 27px;
            }
        }


        &--plant {
            padding-top: 110px;

            .banner {
                &__body {
                    width: 46%;
                    padding-bottom: 80px;
                }
            }

           
        }

        &--compound {
            padding-top: 50px;

            .banner {
                &__body {
                    width: 40%;
                    padding-bottom: 74px;  
                }

                &__img {
                    padding-left: 30px;

                    &-item {
                        margin-left: 19px;
                        margin-right: 0;

                        &:first-child {
                            margin-left: 0;
                        }
                    }

                    &-text {
                        font-size: 1vw;
                        margin-bottom: 29px;
                    }

                    &-img {
                        width: 3.8vw;
                        margin-bottom: 10px;
                    }
                }
            }
        }

        &--reinforcement {
            overflow: hidden;

            .banner {
                &__text {
                    max-width: 78%;
                    line-height: 1.5;
                }

                &__body {
                    flex: none;
                    padding-bottom: 73px;
                    width: 44%;  
                }

                &__img-text {
                    font-size: 1.1vw;
                    margin-bottom: 27px;
                }

                &__img-img { 
                    margin-bottom: 5px;
                }

                &__img {
                    margin-bottom: -35px;
                }

                &__img-item {
                    margin: 0;
                    margin-left: 41px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:nth-child(1) {
                        transform: translateY(-22px);

                        .banner__img-main {
                            width: 2.88vw;
                        }

                        .banner__img-img {
                            width: 2.5vw;
                        }
                    }

                    &:nth-child(2) {
                        transform: translateY(-34px);

                        .banner__img-img {
                            width: 3vw;
                        }

                        .banner__img-main {
                            width: 3vw;
                        }
                    }

                    &:nth-child(3) {
                        transform: translateY(16px);

                        .banner__img-img {
                            width: 3.7vw;
                        }

                        .banner__img-main {
                            width: 4vw;
                        }
                    }

                    &:nth-child(4) {
                        .banner__img-img {
                            width: 4vw;
                        }
                        .banner__img-main {
                            width: 4vw;
                        }
                    }

                    &:nth-child(5) {
                        .banner__img-img {
                            width: 4.5vw;
                        }
                        .banner__img-main {
                            width: 4vw;
                        }
                    }
                }
            }
        }

        

        &--compound {
            padding-top: 75px;
        }

        &__text {
            font-size: 1.13vw;
            margin-bottom: 14px;
        }
         
        &--innerc {
            .banner {
                &__img {
                    img:last-child {
                        position: absolute;
                        bottom: -147px;
                        right: -25px;
                    }
                }
            }
        }

    }
}

@media(min-width: 961px) and (max-width: 1140px){
    .banner {
        &--reinforcement {
            overflow: hidden;

            .banner {
                &__text {
                    max-width: 95%;
                    line-height: 1.5;
                }

                &__body {
                    flex: none;
                    padding-bottom: 65px;
                    width: 38%;
                }

                &__btn {

                   flex-direction: column;
                   align-items: flex-start;

                    .btn {
                        width: auto;
                        display: inline-flex;
                        font-size: 14px;
                        line-height: 17px;
                        padding: 13px 74px 12px;
                        margin-bottom: 16px;

                    }

                    .btn-download {
                        padding: 14px 20px;
                        font-size: 14px;
                        line-height: 17px;
                        .svg-icon {
                            width: 20px;
                            margin-right: 8px;
                        }
                    }
                }

                &__img-text {
                    font-size: 1.1vw;
                    margin-bottom: 27px;
                }

                &__img-img { 
                    margin-bottom: 5px;
                }

                &__img {
                    margin-bottom: -48px;
                }

                &__img-item {
                    margin: 0;
                    margin-left: 41px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:nth-child(1) {
                        transform: translateY(-22px);

                        .banner__img-main {
                            width: 35px;
                        }

                        .banner__img-img {
                            width: 31px;
                        }
                    }

                    &:nth-child(2) {
                        transform: translateY(-2px);

                        .banner__img-img {
                            width: 38px;
                        }

                        .banner__img-main {
                            width: 44px;
                        }
                    }

                    &:nth-child(3) {
                        transform: translateY(3px);

                        .banner__img-img {
                            width: 50px;
                        }

                        .banner__img-main {
                            width: 50px;
                        }
                    }

                    &:nth-child(4) {
                        transform: translateY(-8px);

                        .banner__img-img {
                            width: 56px
                        }
                        .banner__img-main {
                            width: 53px;
                        }
                    }

                    &:nth-child(5) {
                        .banner__img-main {
                            width: 54px;
                        }

                        .banner__img-img {
                            width: 62px;
                        }
                    }
                }
            }
        }
    }
}

@media(min-width: 960px) {
    .banner {
        &__icon {
            &:hover {
                opacity: 0.8;
            }
        }

        &__img {
            &-item {
                transition: opacity 0.3s ease;

                &:hover {
                    opacity: 0.6;
                }
            }
        }
    }
}

@media(max-width: 1140px) {
    .banner {
        h1 {
            margin-bottom: 20px;
        }

        &__info {
            font-size: 11px;
            line-height: 160%;

            span {
                font-size: 24px;
                line-height: 121%;
            }
        }

        &__btn {
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            .btn {
                margin-left: 0;
                padding: 11px 23px;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 0;
                margin-right: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .btn-download {
                padding: 12px 23px 12px 16px;
                font-size: 12px;
                line-height: 15px;

                .svg-icon {
                    width: 12px;
                    height: auto;
                    margin-right: 6px;
                }
            }
        }

        &__text {
            font-size: 13px;
            line-height: 160%;
            margin-bottom: 24px;
        }

        &__body {
            width: 312px;
            padding-bottom: 70px;
        }

        &__icon {
            position: absolute;
            padding-left: 5px;
            padding-right: 5px;
            padding-bottom: 62px;
            width: 203px;
            height: 233px;
            background-position: center;
            background-size: cover;

            &:nth-child(1) {
                margin-left: 0;
                top: -35px;
                left: 108px;
            }

            &:nth-child(2) {
                margin-left: 0;
                margin-top: 0;
                top: 131px;
                left: 280px;
            }

            &:nth-child(3) {
                margin-top: 0;
                top: 207px;
                left: 48px;
            }

            &--big {
                width: 229px;
                height: 264px;
            }

            &-name {
                font-size: 18px;
                line-height: 140%;
            }
        }

        &--reinforcement {
            padding-top: 31px;

            .banner {
                &__img {
                    &-item {
                        margin: 0 21px;
                    }

                    &-img {
                        img {
            
                        }
                    }

                    &-text {
                        font-size: 16px;
                        line-height: 120%;
                        margin-bottom: 23px;
                    }
                }
            }
        }

        &--plant {
            padding-top: 100px;

            h1 {
                margin-bottom: 15px;
            }

            .banner {
                &__back {
                    display: none;
                }

                &__wrap {
                    align-items: flex-start;
                }

                &__img {
                    margin-top: -20px;
                    position: relative;
                    height: 100%;
                }

                &__body {
                    padding-bottom: 65px;
                    width: 42%;
                }
            }
        }

        &--compound {
            padding-top: 75px;

            .banner {
                &__body {
                    width: 44%;
                    padding-bottom: 60px;
                }

                &__img {
                    padding-left: 30px;

                    &-img {
                        width: 42px;
                        margin-bottom: 14px;
                    }

                    &-text {
                        font-size: 11px;
                        line-height: 120%; 
                        margin-bottom: 22px;
                    }

                    &-item {
                        margin-left: 29px;
                        margin-right: 0;

                        &:first-child {
                            margin-left: 0;
                        }
                    }
                }
            }

        }

        &--innerc {
            padding-top: 102px;
            padding-bottom: 41px;

            .banner {
                &__body {
                    width: 413px;
                }
                &__img {
                    width: calc(~"100% - 511px");
                    margin-left: 74px;
                    margin-top: -48px;
                    flex: none;

                    img {
                        &:first-child {
                            width: 262px;
                        }

                        &:last-child {
                            bottom: -153px;
                            right: initial;
                            left: 228px;
                            width: 202px;
                            max-width: initial;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 960px) {
    .banner {
        padding-top: 55px;

        &__info {
            font-size: 13px;
            line-height: 160%;

            span {
                display: none;
            }
        }

        
       
        &__btn {
            display: flex;
            align-items: center;
            margin-bottom: 22px;

            .btn {
                margin-bottom: 0;
            }
        }

        h1 {
            margin-bottom: 24px;
            max-width: 100%;
        }

        &__wrap {
            display: block;
        }

        &__body {
            padding-bottom: 84px;
        }

        &__icon {
            position: relative;
            margin: 0 12px !important;
            padding-bottom: 70px;
            padding-right: 20px;
            padding-left: 20px;

            &-name {
                font-size: 21px;
                line-height: 140%;
            }

            &:nth-child(1) {
                width: 268px;
                height: 310px;
                top: initial;
                right: initial;
                left: initial;
                bottom: initial;
            }

            &:nth-child(2) {
                width: 268px;
                height: 310px;
                top: initial;
                right: initial;
                left: initial;
                bottom: initial;
            }

            &:nth-child(3) {
                width: 268px;
                height: 310px;
                top: initial;
                right: initial;
                left: initial;
                bottom: initial;
                margin-top: -51px !important;
            }
        }

        &--compound {
            background-color: #fff;
            background-image: none;

            .banner {
                &__body {
                    width: 100%;
                }

                &__img {
                    margin: 0 -12px;

                    &-text {
                        font-size: 11px;
                        line-height: 120%;
                        margin-bottom: 24px;
                    }

                    &-img {
                        width: 41px;
                        height: auto;
                        margin-bottom: 14px;
                    }

                    &-item {
                        width: calc(~"33.3% - 24px");
                        margin: 0 12px;
                    }
                }
            }
        }

        &--plant {
            padding-top: 48px;
            background-image: none !important;
            background-color: #fff;

            h1 {
                margin-bottom: 20px;
            }

            .banner {
                &__img {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    flex-direction: row;
                    position: relative;
                    margin-top: -27px;
                    margin-left: -12px;
                    margin-right: -12px;
                    margin-bottom: 61px;

                }
                &__body {
                    display: block;
                    width: 100%;
                }
            }
        }

        &--reinforcement {
            padding-top: 61px;

            h1 {
                margin-bottom: 20px;
            }

            .banner {
                &__body {
                    width: 49%;
                    padding-bottom: 41px;
                }

                &__img {
                    &-item {
                        position: relative;
                        height: 38px;
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        margin: 24px 0;
                        width: 100%;
                    }

                    &-main {
                        position: absolute;
                        left: 124px;
                        height: 100%;
                        
                        img {
                            &:first-child {
                                display: none;
                            }

                            &:last-child {
                                display: block;
                                max-width: initial;
                            }
                        }
                    }

                    &-img {
                        margin: 0;
                        width: 22px;
                    }

                    &-text {
                        font-size: 21px;
                        line-height: 120%;
                        margin: 0 14px 0 10px;
                    }
                }

                &__img {
                    width: calc(~"51% + 40px");
                    padding-left: 50px;
                    margin-right: -40px;
                    flex: none;
                    display: flex;
                    flex-direction: column;
                }

                &__wrap {
                    display: flex;
                    align-items: center;
                }

                &__info {
                    font-size: 11px;
                    line-height: 160%;
                    margin-right: -17px;
                }

                &__btn {
                    margin-bottom: 17px;
                    flex-direction: column;
                    align-items: flex-start;

                    .btn {
                        padding: 10px 58px;
                        margin-bottom: 16px;
                    }
                }

                &__text {
                    margin-bottom: 26px;
                }
            }
        }

        &--innerc { 
            padding-top: 38px;
            display: flex;

            .banner {
                &__wrap {
                    display: flex;
                }

                &__body {
                    padding-bottom: 19px;
                    width: 401px;

                    br {
                        display: none;
                    }
                }

                &__img {
                    width: calc(~"100% - 401px");
                    margin-left: 20px;
                    margin-top: 38px;
                    flex: none;

                    img {
                        &:first-child {
                            position: relative;
                            bottom: initial;
                            left: initial;
                            width: 207px;
                            max-width: initial;
                           
                        }

                        &:last-child {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 640px) {
    .banner {
        &__icon {
            width: 282px;
            height: 325px;
            margin-top: 0 !important;
            margin-bottom: 32px !important;

            &:nth-child(3) {
                margin-top: 0 !important;
                margin-bottom: 0 !important;
            }
        }

        &--innerc {
            .banner {
                &__body {
                    width: 100%;
                }

                &__img {
                    display: none;
                }
            }
        }

        &--plant {
            .banner {
                &__img {
                    margin-top: 0;
                }
            }
        }

        &--reinforcement {
            padding-top: 29px;
            background-image: none;
            background-color: #fff;

            .banner {

                &__btn {
                    flex-direction: row;
                    justify-content: flex-start;

                    .btn {
                        padding: 10px 18px;
                        margin-bottom: 0;
                    }
                }

                &__img {
                    padding-left: 0;
                    padding-bottom: 60px;

                    &-main {
                        display: flex;
                        align-items: center;
                    }

                    &-item {
                        height: auto;
                        margin: 0 0 38px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
                &__info {
                    margin-right: 0;
                    padding-right: 0;
                }
                &__wrap {
                    display: block;

                }

                &__body {
                    padding-bottom: 40px;
                    width: 100%;
                }
            }
        }
    }
}

@media(max-width: 340px) {
    .banner {
        &__btn {
            flex-direction: column;
            align-items: flex-start;

            .btn {
                margin-bottom: 10px;
            }

            .btn-download {
                padding-left: 0;
            }
        }
    }
}