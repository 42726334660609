.sphere {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0 -40px;
    position: relative;

    &__img {
        display: none;
    }

    &__back {
        position: absolute;
        right: 40px;
    }

    &__item {
        display: flex;
        align-items: flex-end;
        position: relative;
        margin: 0 41px;
        z-index: 2;
        padding: 0 31px 71px;
        width: 240px;
        height: 271px;
        transition: opacity 0.3s ease;

        &:nth-child(7) {
            margin-left: 525px;
        }

        &:nth-child(2) {
            margin-left: 169px;
        }

        &:nth-child(1) {
            margin-left: 205px;
            margin-right: 229px;
        }

        &::before {
            content:" ";
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
            background-image: url(../images/sphere/back.png);
        }

        &--big {
            padding-left: 80px;
            align-items: center;
            padding-bottom: 0;

            &::before {
                display: none;
            }

            .sphere {
                &__name {
                    font-weight: bold;
                    font-size: 36px;
                    color: #000000;
                    line-height: 121%;
                }
            }
        }
    }

    &__name {
        position: relative;
        font-weight: 500;
        font-size: 21px;
        line-height: 140%;
        color: #FFFFFF;
    }

    &--compound {
        .sphere {
            &__item {
                &:nth-child(5) {
                    margin-right: 100px;
                }

                &:nth-child(6) {
                    margin-left: 525px;
                }

                &:nth-child(7) {
                    margin-left: 31px;
                }
            }
        }
    }
}



@media(min-width: 1141px) and (max-width: 1600px) {
    .sphere {
        padding-top: 37px;
        padding-bottom: 40px;
        

        &__name {
            font-size: 1.3vw;
        }

        &__back {
            width: 39vw;
            right: 130px;
            top: 65px;
        }

        &__item {
            width: 15vw;
            background-position: center;
            background-size: cover;
            height: 17vw;
            padding: 0 22px 49px;
            margin: 0 2.5vw;

            &:nth-child(1) {
                margin-left: 13vw;
                margin-right: 11vw;
            }

            &--big {
                padding-bottom: 0;
                padding-left: 59px;

                .sphere {
                    &__name {
                        font-size: 2.2vw;
                    }
                }
            }

            &::before {
                width: 100%;
                height: 100%;
                background-position: center;
                background-size: cover;
            }
        }

        &--compound { 
            margin: 0 -2.5vw;

            .sphere {
                &__item {
                    &:nth-child(6) {
                        margin-left: 32vw;
                    }
                }
            }
        }
    }
}

@media (max-width: 1140px) {
    .sphere {
        width: 881px;
        justify-content: space-between;
        margin: 0 auto;
        position: relative;
        padding-top: 188px;
        padding-bottom: 188px;
        padding-right: 112px;

        &__back {
            top: 0;
            width: 498px;

            img {
                max-width: 100%;
            }
        }

        &__item {
            width: 172px;
            height: 194px;
            margin:  0;
            padding: 0 31px 35px 31px;
            background-size: cover;

            &:nth-child(1) {
                position: absolute;
                margin-left: 0;
                top: 0;
                left: 99px;
            }

            &:nth-child(2) {
                position: absolute;
                margin-left: 0;
                right: 0;
                top: 0;
                margin: 0;
            }

            &:nth-child(7) {
                position: absolute;
                margin-left: 0;
                bottom: 0;
                right: 213px;
                margin: 0;
            }

            &:nth-child(8) {
                position: absolute;
                margin-left: 0;
                right: 0;
                bottom: 0;
                margin: 0;
            }

            &--big {
                padding-left: 76px;
                padding-top: 34px;

                .sphere {
                    &__name {
                        font-size: 24px;
                        line-height: 121%;
                    }
                }
            }

            &::before {
                width: 100%;
                height: 100%;
                background-size: cover;
            }
        }

        &__name {
            font-size: 16px;
            line-height: 140%;
        }

        &--compound {
            padding-bottom: 56px;

            .sphere__item:nth-child(5) {
                margin-right: 195px;
            }

            .sphere__item:nth-child(7) {
                margin-left: 0;
                bottom: 54px;
                right: 0;
            }

            .sphere__item:nth-child(6) {
                margin-left: 502px;
            }
        }
    }
}

@media (max-width: 960px) {
    .sphere {
        width: 100%;
        padding: 0;

        &__back {
            display: none;
        }

        &__img {
            display: flex;
        }

        &__name {
            font-weight: 700;
            color: @black;
            text-align: center;
        }

        &__img {
            width: 120px;
            height: 138.15px;
            background-position: center;
            background-size: cover;
            margin-bottom: 10px;
        }

        &__item {
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 0;
            width: calc(~"50% - 30px") !important;
            position: relative!important;
            margin: 0 15px 32px !important;
            background-image: none !important;
            top: initial !important;
            right: initial !important;
            left: initial !important;
            bottom: initial !important;

            &::before {
                display: none;
            }

            &--big {
                padding: 0;
                text-align: center;
                width: 100% !important;
                height: auto;
                position: relative;
                text-align: center;
                margin-bottom: 31px !important;
                display: block;
                background-image: none !important;
            }

            &:nth-child(1) {
                width: 100%;
                position: relative;
                top: initial;
                left: initial;
                margin-right: 0;
            }
        }
    }
}

@media (max-width: 480px) {
    .sphere {

        &__item {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: calc(~"100% - 30px") !important;
            position: relative!important;
            margin: 0 15px 32px !important;
            background-image: none !important;
            top: initial !important;
            right: initial !important;
            left: initial !important;

            &::before {
                display: none;
            }

            &--big {
                padding: 0;
                text-align: center;
                width: 100% !important;
                height: auto;
                position: relative;
                text-align: center;
                margin-bottom: 31px !important;
                display: block;
                background-image: none !important;
            }

            &:nth-child(1) {
                width: 100%;
                position: relative;
                top: initial;
                left: initial;
                margin-right: 0;
            }
        }
    }
}