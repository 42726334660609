.banner-about {
    position: relative;

    &__body {
        width: 560px;
        
    }

    .container {
        padding-top: 131px;
        padding-bottom: 121px;
        position: relative;
    }

    &__img {
        position: absolute;
        top: -178px;
        right: -30px;

        img {
            &:nth-child(2) {
                margin-left: -239px;
                margin-top: -51px;
                margin-bottom: -50px;
            }


        }
    }

    &__text {
        margin-top: 32px;
        font-weight: 700;
        font-size: 18px;
        line-height: 157%;
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .banner-about {
        .container {
            padding-top: 94px;
            padding-bottom: 88px;
        }

        &__body {
            width: 48%;
        }

        &__img {
            top: -11vw;
            right: 87px;

            img {
                width: 25vw;

                &:nth-child(2) {
                    margin-left: -15vw;
                    margin-top: -3vw;
                    margin-bottom: -3vw;
                }
            }
        }

        &__text {
            font-size: 1.1vw;
            margin-top: 24px;
        }
    }
}

@media (max-width: 1140px) {
    .banner-about {
        .container {
            padding-top: 89px;
            padding-bottom: 88px;
        }

        &__body {
            padding-left: 77px;
            width: 579px;
        }

        &__img {
            top: -137px;
            right: -72px;

            img {
                width: 215px;
               
                &:nth-child(2) {
                    margin-left: -130px;
                    margin-top: -25px;
                    margin-bottom: -27px;
                }
            }
        }

        &__text {
            margin-top: 19px;
            font-size: 13px;
            line-height: 160%;
        }
    }
}

@media (max-width: 960px) { 
    .banner-about {
        .container {
            padding-top: 66px;
            padding-bottom: 54px;
        }

        &__body {
            padding-left: 0;
            width: 65%;
        }

        &__img {
            top: 26px;
            right: -84px;

            img {
                width: 176px;
               
                &:nth-child(2) {
                    margin-left: -106px;
                    margin-top: -25px;
                    margin-bottom: -27px;
                }
            }
        }

        &__text {
            margin-top: 19px;
            font-size: 13px;
            line-height: 160%;
        }
    }
}

@media (max-width: 640px) { 
    .banner-about {

        .container {
            padding-top: 52px;
            padding-bottom: 41px;
            display: flex;
            flex-direction: column;
        }

        &__body {
            order: 2;
            width: 100%;
        }

        &__img {
            position: relative;
            top: initial;
            right: initial;
            order: 1;
            margin-bottom: 8px;

            img {
                width: 148px;
                display: none;

                &:first-child {
                    display: block;
                }
            }
        }
    }
}