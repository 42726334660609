.people-item {
    padding: 0 29px;

    &__item {
        display: flex;
        height: 405px;
    }

    .slick-arrow {
        position: absolute;
        background-color: transparent;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        padding: 0;
        border: 0;
        z-index: 5;
        transition: opacity 0.3s ease;

        &:hover {
            opacity: 0.8;
        }

        svg {
            display: block;

            path {
                stroke: #3F2B22;
            }
        }

        &.slick-prev {
            left: 0;
        }

        &.slick-next {
            right: 0;
        }
    }

    &__img {
        width: 300px;
        height: 100%;
        margin-right: 16px;
        flex: none;
        border-radius: 3px;
        background-position: center;
        background-size: cover;
    }

    &__name {
        display: inline-flex;
        font-weight: bold;
        font-size: 26px;
        line-height: 32px;
        border-bottom: 1px solid #000000;
        margin-bottom: 23px;
    }

    &__text {
        p {
            font-size: 16px;
            line-height: 22px;
            margin: 0 0 15px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__body {
        flex-grow: 1;
        padding: 42px 38px;
        background: #F2F2F2;
        border-radius: 3px;
    }
}

.pagingInfo__wrap {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 41px;
    line-height: 22px;
    color: #E7C89A;
    font-size: 28px;

    .pagingInfo {
        font-size: 28px;
        color: #E7C89A;
        margin-right: 5px;
    }

    .slideshow {
        margin-left: 5px;
        font-size: 18px;
        color: #000000;
        line-height: 1;
    }
}

@media(max-width: 1200px) {
    .people-item {
        &__item {
            align-items: center;
            height: auto;
            min-height: 405px;
        }

        &__img {
            height: 405px;
        }

    }
}

@media(max-width: 992px) {
    .people-item {
        &__body {
            padding: 18px 19px;
        }

        &__text {
            p {
                font-size: 14px;
                line-height: 16px;
            }
        }

        &__name {
            font-size: 18px;
            line-height: 30px;
            margin-bottom: 17px;
        }
    }
}

@media(max-width: 650px) {
    .people-item {
        padding: 0 18px;

        .slick-arrow {
            transform: translateY(0);
            top: 160px;

            &.slick-prev {
                left: -5px;
            }

            &.slick-next {
                right: -5px;
            }

            svg {
                width: 9px;
                height: auto;
            }
        }
        

        &__body {
            width: 100%;
        }

        &__item {
            display: block;
        }

        &__img {
            width: 251px;
            height: 339px;
            margin: 0 auto 12px;
        }
    }
}