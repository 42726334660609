.tab {
    &__control {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 41px;

        &-wrap {
            display: inline-flex;
            align-items: center;
        }
    }

    &__block {
        display: none;

        &.is-active {
            display: block;
        }
    }

    &__link {
        cursor: pointer;
        font-weight: bold;
        font-size: 26px;
        line-height: 130%;
        margin-right: 36px;
        color: @grey-3;
        padding-bottom: 6px;
        border-bottom: 5px solid @grey-2;
        transition: color 0.3s, border-color 0.3s ease;

        &.is-active {
            color: @black;
            border-color: @orange;
        }
    }
}

@media(min-width: 1141px) and (max-width: 1600px) {
    .tab {
        &__link {
            font-size: 1.6vw;
            margin-right: 18px;
        }

        &__control {
            margin-bottom: 28px;
        }
    }
}   

@media (max-width: 1140px) {
    .tab {
        &__link {
            font-size: 19px;
            line-height: 130%;
            padding-bottom: 4px;
        }

        &__control {
            margin-bottom: 34px;
        }
    }
}

@media (max-width: 960px) {
    .tab {
        .btn {
            display: none;
        }
    }
}

